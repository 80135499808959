export interface IZipFile {
    filename?: string;
    uncompressedSize?: number;
}
export interface IErrorCodeList {
    some?: (number) => number;
}

export const NORMALIZED_YIELD = "Regular";
export const NORMALIZED_YIELD_BATCH = "Batch";
export const NORMALIZED_YIELD_BENCHMARK = "Benchmark";
