import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { EquipmentProfileAPI, VendorAPI, Request, APIError } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { setApiResult } from "~/core/api/actions";
import { vendorSagas } from "./controller";
import { messages } from "../../i18n-messages";
// selectors
import { getTheUserGuid } from "~/login/selectors";
import {
    createEquipment,
    createEquipmentSuccess,
    createEquipmentFailed,
    fetchVendorEquipmentList,
    fetchVendorEquipmentListSuccess,
    fetchVendorEquipmentListFailed,
} from "./actions";

import * as actions from "./actions";

export function* processCreateEquipment(action) {
    const { payload } = action;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: payload };
    try {
        const response = yield call(Request.post, EquipmentProfileAPI.CREATE, requestOptions);
        yield put(createEquipmentSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        console.warn(
            `Failed to process action ${createEquipment().type} with payload ${JSON.stringify(
                payload
            )}`
        );
        yield put(
            notificationActions.apiCallError(
                error,
                createEquipment,
                messages.failedCreateEquipment,
                { equipmentType: createEquipment().type }
            )
        );
        yield put(createEquipmentFailed(error));
    }
}

export function* requestVendorEquipmentList(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield EquipmentProfileAPI.getOwnerOperatorEquipmentList(
                requestOptions
            );
            yield put(fetchVendorEquipmentListSuccess(response));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(fetchVendorEquipmentListFailed(error));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(fetchVendorEquipmentListFailed("No Data"));
    }
}

function* getTestingLabVendorList(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(VendorAPI.getTestingLabVendorList, UserGuid);
        if (response) {
            yield put(actions.getTestingLabVendorListSuccess(response));
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
        yield put(actions.getTestingLabVendorListFailed(error));
    }
}

function* getTestPackageList(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(VendorAPI.getTestPackageList, UserGuid, action.payload);
        if (response) {
            yield put(actions.getTestPackageListSuccess(response));
        }
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
        yield put(actions.getTestPackageListFailed(error));
    }
}

export function* watchTestingLabVendorList() {
    yield takeEvery(actions.GET_TEST_PACKAGE_LIST_DATA, getTestPackageList);
}

export function* watchTestPackageList() {
    yield takeEvery(actions.GET_TESTING_LAB_VENDOR_LIST_DATA, getTestingLabVendorList);
}

export function* watchCreateEquipment() {
    yield takeEvery(createEquipment, processCreateEquipment);
}

export function* watchFetchVendorEquipmentList() {
    yield takeEvery(fetchVendorEquipmentList, requestVendorEquipmentList);
}

const VendorDataSagas = function* () {
    yield all([
        fork(watchCreateEquipment),
        fork(vendorSagas),
        fork(watchFetchVendorEquipmentList),
        fork(watchTestingLabVendorList),
        fork(watchTestPackageList),
    ]);
};
export default VendorDataSagas;
