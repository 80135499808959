import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { NumericInput, SelectInput } from "~/core";

import {
    ACTIVE_YN,
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { actions as recsEventsActions, eventsSelectors } from "~/recs-events";
import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";
import { fetchTillageFormPicklists } from "../../actions";
import { getModuleState } from "../../selectors";
import { TillageIcon } from "../icons/tillage";
import "../../../../../common/rec-event-info/rec-event-info.css";
import "./event-tillage-form.css";

const messages = defineMessages({
    tillageFormLabelText: {
        id: "eventModule.eventInfo.tillageFormLabelText",
        defaultMessage: "Tillage",
    },
    tillageMethodPlaceholderText: {
        id: "eventModule.eventInfo.tillageMethodPlaceholderText",
        defaultMessage: "Tillage Method",
    },
    tillageDirectionPlaceholderText: {
        id: "eventModule.eventInfo.tillageDirectionPlaceholderText",
        defaultMessage: "Tillage Direction",
    },
    tillageDepthPlaceholderText: {
        id: "eventModule.eventInfo.tillageDepthPlaceholderText",
        defaultMessage: "Tillage Depth",
    },
    tillageDepthUnitsPlaceholderText: {
        id: "eventModule.eventInfo.tillageDepthUnitsPlaceholderText",
        defaultMessage: "Unit",
    },
});

const { getPickListCode, PICKLIST_ROW_ORIENTATION, PICKLIST_TILLAGE_METHOD } = picklistNames;

export const formLabelMessage = messages.tillageFormLabelText;
export const formLabelIcon = TillageIcon;

const errorCodeToMessageIdSetMap = new Map([
    [327, messages.tillageMethodPlaceholderText], // ErrorCode.EventTillageTillageTypeRequired
    [699, messages.tillageDepthUnitsPlaceholderText], // ErrorCode.TillageDepthUnitRequired
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

interface IEventTillage {
    agEventModel: any;
    intl: intlShape.isRequired;
    onFetchPicklists: (any) => void;
    onFetchTillagePicklists: (any) => void;
    onUpdateCurrentAgEventAreaAgEventModel: (any) => void;
    picklistOptionsRowOrientation: any;
    picklistOptionsTillageMethod: any;
    picklistOptionsUnits: any;
    saveEventDetailsErrorCodeList: number[];
}

const EventTillageForm_ = (props: IEventTillage): JSX.Element => {
    const {
        agEventModel,
        intl,
        onFetchPicklists,
        onFetchTillagePicklists,
        onUpdateCurrentAgEventAreaAgEventModel,
        picklistOptionsRowOrientation,
        picklistOptionsTillageMethod,
        picklistOptionsUnits,
        saveEventDetailsErrorCodeList,
    } = props;

    const [errorMessagePlaceholderSet, setErrorMessagePlaceholderSet] = useState<Set<any>>(
        new Set()
    );

    function _getErrorMessagePlaceholderSet() {
        return getSetValuesForErrorCodeList(
            saveEventDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }
    function _updateAgEvent(newProps) {
        onUpdateCurrentAgEventAreaAgEventModel(newProps);
    }

    useEffect(() => {
        const fetchPicklistNames = [PICKLIST_ROW_ORIENTATION, PICKLIST_TILLAGE_METHOD];
        const picklistFetchArgObj = fetchPicklistNames.reduce((accum, key) => {
            accum[key] = getPickListCode(key);
            return accum;
        }, {});
        onFetchPicklists(picklistFetchArgObj);
        if (picklistOptionsUnits.length === 0) {
            onFetchTillagePicklists(onFetchPicklists);
        }
    }, []);

    useEffect(() => {
        const newErrorMessagePlaceholderSet = _getErrorMessagePlaceholderSet();
        setErrorMessagePlaceholderSet(newErrorMessagePlaceholderSet);
    }, [saveEventDetailsErrorCodeList]);

    const { formatMessage } = intl;

    return (
        <div className="event-tillage-form">
            <div className="input-row">
                <SelectInput
                    required
                    optionIsHiddenKey={ACTIVE_YN}
                    clearable={false}
                    containerClassNames={[
                        {
                            "select-form-input-error": errorMessagePlaceholderSet.has(
                                messages.tillageMethodPlaceholderText
                            ),
                        },
                    ]}
                    onChange={(v) => _updateAgEvent({ tillageMethodGuid: v })}
                    options={picklistOptionsTillageMethod}
                    placeholderText={formatMessage(messages.tillageMethodPlaceholderText)}
                    value={agEventModel.tillageMethodGuid}
                />
                <SelectInput
                    optionIsHiddenKey={ACTIVE_YN}
                    onChange={(v) => _updateAgEvent({ directionGuid: v })}
                    options={picklistOptionsRowOrientation}
                    placeholderText={formatMessage(messages.tillageDirectionPlaceholderText)}
                    value={agEventModel.directionGuid}
                />
            </div>
            <div className="input-row">
                <div>
                    <NumericInput
                        scale={2}
                        precision={5}
                        containerClassNames={[
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.tillageDepthPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(strVal, formattedVal, numVal) =>
                            _updateAgEvent({ depth: numVal })
                        }
                        placeholderText={formatMessage(messages.tillageDepthPlaceholderText)}
                        value={agEventModel.depth}
                    />
                </div>
                <SelectInput
                    optionIsHiddenKey={ACTIVE_YN}
                    containerClassNames={[
                        {
                            "select-form-input-error": errorMessagePlaceholderSet.has(
                                messages.tillageDepthUnitsPlaceholderText
                            ),
                        },
                    ]}
                    onChange={(v) => _updateAgEvent({ depthIAGuid: v })}
                    options={picklistOptionsUnits}
                    placeholderText={formatMessage(messages.tillageDepthUnitsPlaceholderText)}
                    value={agEventModel.depthIAGuid}
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    onFetchTillagePicklists: () => dispatch(fetchTillageFormPicklists()),
    onUpdateCurrentAgEventAreaAgEventModel: (fieldGuid, agEventTransactionTypeGuid, newProps) =>
        dispatch(
            recsEventsActions.updateCurrentAgEventAreaAgEventModel(
                fieldGuid,
                agEventTransactionTypeGuid,
                newProps
            )
        ),
});

const mapStateToProps = (state) => {
    const picklistOptionsRowOrientation = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_ROW_ORIENTATION)
    );
    const picklistOptionsTillageMethod = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_TILLAGE_METHOD)
    );

    const { saveEventDetailsErrorCodeList } = eventsSelectors.getModuleState(state);

    const { eventInfoPicklists } = getModuleState(state);
    const picklistOptionsUnits = eventInfoPicklists.tillageDepthUnitOptions || [];

    return {
        picklistOptionsRowOrientation,
        picklistOptionsTillageMethod,
        picklistOptionsUnits,
        saveEventDetailsErrorCodeList,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentAgEventAreaAgEventModel: (newProps) =>
        dispatchProps.onUpdateCurrentAgEventAreaAgEventModel(
            ownProps.fieldGuid,
            ownProps.agEventTransactionTypeGuid,
            newProps
        ),
});

export const EventTillageForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventTillageForm_));
