import React, { useRef } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { Checkbox, DateInput, Section, SelectInput, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType, getCropOptionsData } from "~/reports/data/selectors";

import { FieldAPI } from "@ai360/core";
import { useAppSelector } from "~/store/hooks";
import { ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { keywords } from "../keywords";
import { getSelectedRecGuidSet } from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { getRecGeneralGuidToRecSummaryMap } from "~/recs-events/recs/selectors";

type SkyGrowerSummaryPlantingProps = {
    selectedCrop: FieldAPI.ICrop | null;
};

function SkyGrowerSummaryPlanting({
    hideDefaultOptions,
    intl: { formatMessage },
    onInputChange,
    selectedCrop,
}: ReportOptionsComponentProps & SkyGrowerSummaryPlantingProps) {
    const reportData = useAppSelector(
        getReportDataByReportType(SkyGrowerSummaryPlanting.reportName)
    );

    const crops = useAppSelector(getCropOptionsData);
    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );

    const validRecTypes = [keywords.plantingEquation, keywords.plantingManual];
    const selectedRecGuidList = useAppSelector(getSelectedRecGuidSet);
    const agRecSummaryMap = useAppSelector(getRecGeneralGuidToRecSummaryMap);
    const perPageOptions = [
        {
            label: "Grower",
            value: 0,
        },
        {
            label: "Farm",
            value: 1,
        },
        {
            label: "Field",
            value: 2,
        },
    ];

    const seasonsToDisplay = useRef<string[]>();

    React.useEffect(() => {
        reportData[model.PROPS_FIELDS_PER_PAGE] = parseInt(
            reportData[model.PROPS_FIELDS_PER_PAGE].toString()
        );
    }, [reportData]);

    React.useEffect(() => {
        onInputChange(model.PROPS_SELECTED_REC_GUIDS, [...selectedRecGuidList]);

        if (hideDefaultOptions) {
            return;
        }

        if (selectedRecGuidList.size > 0) {
            const seasonGuids = [];

            for (const recGeneralGuid of selectedRecGuidList) {
                const rec = agRecSummaryMap.get(recGeneralGuid);
                if (validRecTypes.includes(rec.recType)) {
                    seasonGuids.push(rec.croppingSeasonGuid);
                }
            }
            const seasonsText = [];
            for (const seasonGuid of seasonGuids) {
                const selectedSeason = seasons.find((season) => season.value === seasonGuid);
                if (seasonsText.indexOf(selectedSeason.label) === -1) {
                    seasonsText.push(selectedSeason.label);
                }
            }
            seasonGuids.sort();
            seasonsToDisplay.current = seasonsText;
        } else {
            seasonsToDisplay.current = [];
        }
    }, [selectedRecGuidList.size]);

    const optionsDisabled = selectedRecGuidList.size > 0;
    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] ||
        reportData[model.PROPS_END_DATE] ||
        selectedRecGuidList.size > 0;

    return (
        <Section className="report-options">
            {!hideDefaultOptions ? (
                <React.Fragment>
                    <SubSection>
                        <CroppingSeason
                            disabled={croppingSeasonDisabled}
                            required={!croppingSeasonDisabled}
                            reportType={SkyGrowerSummaryPlanting.reportName}
                            valuesToDisplay={seasonsToDisplay.current}
                        />
                        <SelectInput<FieldAPI.ICrop>
                            disabled={optionsDisabled}
                            placeholderText={formatMessage(messages.crop)}
                            options={optionsDisabled ? [] : crops}
                            onChange={(value) => {
                                onInputChange(model.PROPS_CROP_GUID, value ? value.cropGuid : null);
                            }}
                            value={optionsDisabled ? null : (selectedCrop as FieldAPI.ICrop)}
                        />
                    </SubSection>
                    <SubSection>
                        <DateInput
                            disabled={optionsDisabled}
                            placeholderText={formatMessage(messages.startDate)}
                            onChange={(value) =>
                                onInputChange(model.PROPS_START_DATE, value ? value : null)
                            }
                            timeFormat={false}
                            value={optionsDisabled ? null : reportData[model.PROPS_START_DATE]}
                        />
                        <DateInput
                            disabled={optionsDisabled}
                            placeholderText={formatMessage(messages.endDate)}
                            onChange={(value) =>
                                onInputChange(model.PROPS_END_DATE, value ? value : null)
                            }
                            timeFormat={false}
                            value={optionsDisabled ? null : reportData[model.PROPS_END_DATE]}
                        />
                    </SubSection>
                </React.Fragment>
            ) : null}
            <SubSection className="two-report-options">
                <SelectInput
                    required
                    clearable={false}
                    containerClassNames={["display-options-list"]}
                    placeholderText={formatMessage(messages.fieldsPerPageOptions)}
                    options={perPageOptions}
                    onChange={(value) => {
                        onInputChange(model.PROPS_FIELDS_PER_PAGE, value);
                    }}
                    value={parseInt(reportData[model.PROPS_FIELDS_PER_PAGE].toString())}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.displayTraits)}
                    onChange={(e, value) => onInputChange(model.PROPS_DISPLAY_TRAITS, value)}
                    value={reportData[model.PROPS_DISPLAY_TRAITS]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.costDetails, { count: 1 })}
                    onChange={(e, value) =>
                        onInputChange(model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL, value)
                    }
                    value={reportData[model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]}
                />
                <Checkbox
                    disabled={!reportData[model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]}
                    label={formatMessage(messages.farmTotals)}
                    onChange={(e, value) => onInputChange(model.PROPS_PRINT_FARM_TOTALS, value)}
                    value={reportData[model.PROPS_PRINT_FARM_TOTALS]}
                />
                <Checkbox
                    disabled={!reportData[model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]}
                    label={formatMessage(messages.fieldTotals)}
                    onChange={(e, value) => onInputChange(model.PROPS_PRINT_FIELD_TOTALS, value)}
                    value={reportData[model.PROPS_PRINT_FIELD_TOTALS]}
                />
                <Checkbox
                    disabled={!reportData[model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]}
                    label={formatMessage(messages.growerTotals)}
                    onChange={(e, value) => onInputChange(model.PROPS_PRINT_GROWER_TOTALS, value)}
                    value={reportData[model.PROPS_PRINT_GROWER_TOTALS]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.summary)}
                    onChange={(e, value) => onInputChange(model.PROPS_PLANTING_SUMMARY, value)}
                    value={reportData[model.PROPS_PLANTING_SUMMARY]}
                />
            </SubSection>
        </Section>
    );
}

SkyGrowerSummaryPlanting.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
    selectedCrop: null,
};

SkyGrowerSummaryPlanting.defaultReportOptions = {
    [model.PROPS_SELECTED_REC_GUIDS]: [],
    [model.PROPS_FIELDS_PER_PAGE]: 2,
    [model.PROPS_DISPLAY_TRAITS]: false,
    [model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]: false,
    [model.PROPS_PRINT_GROWER_TOTALS]: false,
    [model.PROPS_PRINT_FARM_TOTALS]: false,
    [model.PROPS_PRINT_FIELD_TOTALS]: false,
    [model.PROPS_PLANTING_SUMMARY]: true,
};

SkyGrowerSummaryPlanting.reportPreferences = [
    model.PROPS_FIELDS_PER_PAGE,
    model.PROPS_DISPLAY_TRAITS,
    model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL,
    model.PROPS_PRINT_GROWER_TOTALS,
    model.PROPS_PRINT_FARM_TOTALS,
    model.PROPS_PRINT_FIELD_TOTALS,
];

SkyGrowerSummaryPlanting.reportName = ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_PLANTING;

export default injectIntl(SkyGrowerSummaryPlanting) as ReportOptionsComponent<
    ReportOptionsComponentProps & SkyGrowerSummaryPlantingProps
>;
