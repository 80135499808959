import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import { parse } from "qs";

import { Loader, LoaderTypes, SubSection, TextInput } from "~/core";
import { messages } from "~/i18n-messages";
import { ThemeRoot } from "~/theme";
import { APIErrorWithCode, CustomerAPI } from "@ai360/core";
import { Section } from "../../core";

import * as actions from "../actions";
import * as selectors from "../selectors";
import "./login.css";
import { getErrorMessages } from "~/i18n-error-messages";
import { logFirebaseEvent } from "~/utils/firebase";

export interface IMyAgDataRegistration_Props {
    error?: Error;
    location?: Record<string, any>;
    onStartProcessing: () => void;
    onStopProcessing: () => void;
    initMyAgData: () => void;
    isProcessing?: boolean;
    intl: intlShape;
}

const taxIdRegex = new RegExp(/(^\d{9}$)|(^\d{3}-\d{2}-\d{4}$)|(^[1-9]\d?-\d{7}$)/);

const MyAgDataRegistration_ = (props: IMyAgDataRegistration_Props): JSX.Element => {
    const { location, onStartProcessing, onStopProcessing, isProcessing, intl } = props;
    const { formatMessage } = intl;
    const { registration } = parse(location.search.slice(1));

    const [taxId, setTaxId] = useState<string>(null);
    const [info, setInfo] = useState<CustomerAPI.IMyAgDataBasicInfo>(null);
    const [shaking, setShaking] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<string | string[]>(null);
    const [lastStatus, setLastStatus] = useState<CustomerAPI.IMyAgDataFileStatus>(null);
    const [stage, setStage] = useState<"need-id" | "pending" | "done" | "not-found">("need-id");
    const [showTimeout, setShowTimeout] = useState(false);

    useEffect(() => {
        props.initMyAgData();
    }, []);

    useEffect(() => {
        if (registration) {
            onStartProcessing();
            CustomerAPI.getMyAgDataRegistrationById(registration)
                .then((response) => {
                    setInfo(response);
                    onStopProcessing();
                    if (response.queueStatusId) {
                        setStage("pending");
                        _fetchStatus(response.queueStatusId, true);
                    }
                })
                .catch((err) => {
                    _handleError(err);
                })
                .finally(() => console.log("fetch ended"));
        }
    }, [registration]);

    useEffect(() => {
        if (!lastStatus) return;

        if (lastStatus.status === "Queued") {
            _fetchStatus(lastStatus.queueStatusId);
        } else {
            if (lastStatus.errorMessages?.length > 0) {
                setErrMessage(lastStatus.errorMessages);
                setShaking(true);
            }

            setStage("done");
            setShowTimeout(false);
        }
    }, [lastStatus]);

    const invalidTaxId = useMemo(() => !taxIdRegex.test(taxId), [taxId]);

    function _handleError(err) {
        let errMessage: string;
        if (err instanceof APIErrorWithCode) {
            errMessage = getErrorMessages(formatMessage, err);
        } else if (err?.apiResultObj?.status === 404) {
            setStage("not-found");
        } else {
            errMessage = formatMessage(messages.unknownError);
        }
        setShaking(true);
        setErrMessage(errMessage);

        onStopProcessing();
    }

    function _fetchStatus(queueStatusId: number, immediately = false) {
        setTimeout(
            () =>
                CustomerAPI.getMyAgDataFileStatus(queueStatusId).then((response) =>
                    setLastStatus(response)
                ),
            immediately ? 0 : 15000
        );
        setTimeout(() => {
            if (stage !== "done") {
                setShowTimeout(true);
            }
        }, 1800000); // 30 minutes
    }

    function _accept() {
        if (invalidTaxId) return;

        setStage("pending");
        logFirebaseEvent("myagdata_registered");
        CustomerAPI.finishMyAgDataRegistration(registration, taxId)
            .then((result) => {
                setLastStatus(result);
            })
            .catch((err) => {
                _handleError(err);
                setStage("done");
            });
    }

    function renderNeedId() {
        return (
            <React.Fragment>
                <div className="validation-summary-errors">{errMsgEl}</div>
                <p>
                    {formatMessage(messages.myAgDataRegistrationText1)}
                    &nbsp;
                    {info.customerName}
                </p>
                <TextInput
                    required={true}
                    placeholderText={formatMessage(messages.myAgDataTaxId)}
                    value={taxId}
                    onChange={(value) => setTaxId(value)}
                    hasError={Boolean(taxId) && invalidTaxId}
                    errorText={null}
                />
                {invalidTaxId && taxId && (
                    <div className="error-text">
                        {formatMessage(messages.myAgDataRegistrationError)}
                    </div>
                )}
                <p>{formatMessage(messages.myAgDataRegistrationText2)}</p>
                <button
                    className="default-button"
                    disabled={invalidTaxId}
                    onClick={() => _accept()}
                >
                    {formatMessage(messages.myAgDataRegistrationButton)}
                </button>
            </React.Fragment>
        );
    }

    function renderPending() {
        return (
            <React.Fragment>
                <p>{formatMessage(messages.myAgDataRegistrationChecking)}</p>
                <div>
                    {showTimeout ? (
                        <div className="validation-summary-errors">
                            {formatMessage(messages.myAgDataRegistrationTimeout)}
                        </div>
                    ) : (
                        <Loader
                            className={"inline-loader"}
                            type={LoaderTypes.LINE_SCALE_PULSE_OUT_RAPID}
                        />
                    )}
                </div>
                <a href="#" onClick={() => window.close()}>
                    {formatMessage(messages.myAgDataRegistrationCloseWindow)}
                </a>
            </React.Fragment>
        );
    }

    function renderDone() {
        return (
            <React.Fragment>
                <p>
                    {formatMessage(
                        !errMsgEl
                            ? messages.myAgDataRegistrationSuccess
                            : messages.myAgDataRegistrationFail
                    )}
                </p>
                <div className="validation-summary-errors">{errMsgEl}</div>
                <p>{formatMessage(messages.myAgDataRegistrationClosePrompt)}</p>
                <a href="#" onClick={() => window.close()}>
                    {formatMessage(messages.myAgDataRegistrationCloseWindow)}
                </a>
            </React.Fragment>
        );
    }

    function renderNotFound() {
        return (
            <React.Fragment>
                <p>{formatMessage(messages.myAgDataRegistrationNotFound)}</p>
                <a href="#" onClick={() => window.close()}>
                    {formatMessage(messages.myAgDataRegistrationCloseWindow)}
                </a>
            </React.Fragment>
        );
    }

    const loginBoxClasses = [
        "login-box",
        {
            "login-err-animation": shaking,
        },
    ];

    const errMsgEl = Array.isArray(errMessage)
        ? errMessage.map((msg, idx) => <p key={idx}>{msg}</p>)
        : errMessage;
    return (
        <ThemeRoot className="login-root">
            {isProcessing ? <Loader /> : null}
            {!info && stage !== "not-found" ? (
                <Loader />
            ) : (
                <div className="login-page-container">
                    <div className={classnames(loginBoxClasses)}>
                        <div className="login-logo-div"></div>
                        <div className={"myagdata-container"}>
                            <div className="display-label">
                                {formatMessage(messages.myAgDataTitle)}
                            </div>
                            <Section>
                                <SubSection className="myagdata-registration">
                                    {stage === "need-id" && renderNeedId()}
                                    {stage === "pending" && renderPending()}
                                    {stage === "done" && renderDone()}
                                    {stage === "not-found" && renderNotFound()}
                                </SubSection>
                            </Section>
                        </div>
                    </div>
                </div>
            )}
        </ThemeRoot>
    );
};

const mapStateToProps = (state): Partial<IMyAgDataRegistration_Props> => {
    return {
        error: selectors.getError(state),
        isProcessing: selectors.isProcessing(state),
    };
};

const mapDispatchToProps = (dispatch): Partial<IMyAgDataRegistration_Props> => {
    return {
        onStartProcessing: () => dispatch(actions.setProcessing(true)),
        onStopProcessing: () => dispatch(actions.setProcessing(false)),
        initMyAgData: () => dispatch(actions.initialMyAgDataLoad()),
    };
};

export const MyAgDataRegistration = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MyAgDataRegistration_));
