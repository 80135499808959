import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { ZeroToInfiniteGrid, SelectInput, Checkbox, DialogBox, DialogBoxFooterType } from "~/core";
import { messages } from "./../i18n-messages";
import { SearchIcon } from "~/core/icons";
import classnames from "classnames";
import TableCell from "~/core/components/tables/data-table/components/table-cell";
import { keywords as adminKeywords } from "~/admin/containers/keywords";
import { noOptionRenderer, loadingIndicator } from "../component/search-agvance-customer";

const searchIcon = (
    <span key="search" className="search-icon">
        <SearchIcon className="search-icon" />
    </span>
);

// Constants
const PROPS_AGVANCE_CUSTOMER_GUID = "agvanceCustomerGuid";
const PROPS_AGVANCE_GROWER_NAME = "agvanceGrowerName";
const PROPS_CITY = "city";
const PROPS_STATE = "state";
const PROPS_ORG_LEVEL_NAME = "orgLevelName";
const PROPS_IS_PRIMARY = "isPrimary";

class AdditionalAgvanceCustomer_ extends Component {
    static propTypes = {
        className: PropTypes.string,
        customerSearchIsLoading: PropTypes.bool,
        intl: intlShape.isRequired,
        isAgvanceCustomerListOutOfBound: PropTypes.bool,
        onDelete: PropTypes.func,
        onCustomerDelete: PropTypes.func,
        onInputChange: PropTypes.func,
        onSelectionChange: PropTypes.func,
        mode: PropTypes.string,
        options: PropTypes.array,
        record: PropTypes.array,
        statePropName: PropTypes.string,
        primaryCustomer: PropTypes.object,
        onPrimaryCustomerChange: PropTypes.func,
        myAgDataRegistrations: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.newPrimaryCustomer = null;
        this.state = {
            options: props.options || [],
            initialValue: "",
            showPrimaryChangeDialogBox: false,
            showPrimaryLocationMismatchDialogBox: false,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.options !== this.props.options) {
            this.setState({ options: this.formSearchData(nextProps.options) });
        }
    };

    formSearchData = (customerList) => {
        if (this.props.isAgvanceCustomerListOutOfBound && !this.state.initialValue) {
            return [];
        }

        return customerList.map((record) => ({
            ...record,
            label: record[PROPS_AGVANCE_GROWER_NAME],
        }));
    };

    onInputChange = (initialValue) => {
        this.props.onInputChange(initialValue);
        this.setState({ initialValue });
    };

    onChange = (value, isAdd = true) => {
        if (!value) {
            return;
        }
        const { record, options } = this.props;
        const selectedValues = [...record];
        let selectedCustomerIndex = -1;
        const isAlreadyInList = selectedValues.some((customer, index) => {
            if (customer[PROPS_AGVANCE_CUSTOMER_GUID] === value) {
                selectedCustomerIndex = index;
            }
            return customer[PROPS_AGVANCE_CUSTOMER_GUID] === value;
        });
        const removedCustomer = selectedValues[selectedCustomerIndex];
        const newCustomers = options.find((customer) => {
            return customer[PROPS_AGVANCE_CUSTOMER_GUID] === value;
        });
        if (isAdd && !isAlreadyInList) {
            const newCustomer = {
                ...newCustomers,
                canDelete: true, //newly added customers can always be deleted
            };
            selectedValues.push(newCustomer);
        } else {
            selectedValues.splice(selectedCustomerIndex, 1);
        }
        this.onInputChange("");
        this.props.onSelectionChange(selectedValues, newCustomers ? null : removedCustomer);
    };

    populateRestrictEditDeleteProp = (recordList) => {
        if (recordList.length === 1 && recordList[0].isPrimary) {
            return [];
        }
        return recordList.map((item) => {
            return {
                ...item,
                restrictEditDelete:
                    item.isPrimary ||
                    Boolean(
                        this.props.myAgDataRegistrations?.find(
                            (x) => x.agvanceCustomerGuid === item.agvanceCustomerGuid
                        )
                    ),
            };
        });
    };

    onPrimaryCheckboxChange = (event, record) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (
            this.props.mode === adminKeywords.ADD ||
            (this.props.mode === adminKeywords.EDIT &&
                (this.props.primaryCustomer.oracleId === record.oracleId ||
                    this.props.primaryCustomer.orgLevelGuid === record.orgLevelGuid ||
                    this.props.primaryCustomer.agvanceGrowerId === record.agvanceGrowerId ||
                    this.props.primaryCustomer.growerId === record.agvanceGrowerId ||
                    this.props.primaryCustomer.agvanceDatabaseId === record.agvanceDatabaseId))
        ) {
            this.setState({ showPrimaryChangeDialogBox: true });
            this.newPrimaryCustomer = record;
        } else {
            this.setState({ showPrimaryLocationMismatchDialogBox: true });
        }
    };

    optionRenderer = ({ option, isSelected, isHighlighted }) => {
        const className = classnames(
            "select-form-input-option",
            {
                selected: isSelected,
                "filter-match": isHighlighted,
            },
            "search-person-options"
        );
        const cityAndState = [option[PROPS_CITY], option[PROPS_STATE]].join(", ");
        const locationText = option.isDuplicate
            ? `, Location - ${option[PROPS_ORG_LEVEL_NAME]}`
            : null;
        return (
            <div
                className={className}
                title={`${option[PROPS_AGVANCE_GROWER_NAME]} - [${option.alternateCustomerIdSelecting}]`}
            >
                <div>{option[PROPS_AGVANCE_GROWER_NAME]}</div>
                <div className="person-state">
                    {cityAndState} {locationText}
                </div>
            </div>
        );
    };

    printCustomerName = (value, record) => {
        return (
            <TableCell
                title={`${value} - ${record.alternateCustomerIdSelecting}`}
                className="agvance-name"
            >
                <p>{value}</p>
            </TableCell>
        );
    };

    printIsPrimary = (value, record) => {
        return (
            <Checkbox
                className="agvance-checkbox"
                value={record.isPrimary}
                onChange={(event) => {
                    if (!record.isPrimary) {
                        this.onPrimaryCheckboxChange(event, record);
                    }
                }}
            />
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const record = this.populateRestrictEditDeleteProp(this.props.record);
        const { isAgvanceCustomerListOutOfBound } = this.props;
        const { onChange } = this;
        const {
            options,
            showPrimaryChangeDialogBox,
            showPrimaryLocationMismatchDialogBox,
            initialValue,
        } = this.state;
        return (
            <div className="auto-search-list-container additional-agvance-customer">
                <DialogBox
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={showPrimaryChangeDialogBox}
                    onAction={() => {
                        this.props.onPrimaryCustomerChange(this.newPrimaryCustomer);
                        this.setState({ showPrimaryChangeDialogBox: false });
                        this.newPrimaryCustomer = null;
                    }}
                    onClose={() => this.setState({ showPrimaryChangeDialogBox: false })}
                    title={formatMessage(messages.primaryCustomerChangeTitle)}
                >
                    {formatMessage(messages.primaryCustomerChangeText)}
                </DialogBox>
                <DialogBox
                    isOpen={showPrimaryLocationMismatchDialogBox}
                    onClose={() =>
                        this.setState({
                            showPrimaryLocationMismatchDialogBox: false,
                        })
                    }
                    title={formatMessage(messages.primaryCustomerChangeTitle)}
                >
                    {formatMessage(messages.primaryCustomerChangeLocationMismatch)}
                </DialogBox>
                <SelectInput
                    {...this.props}
                    clearFilterInputOnBlur={false}
                    containerClassNames={["person-filter", this.props.className]}
                    placeholderText={formatMessage(messages.customerName)}
                    inputContainerLeftElements={[searchIcon]}
                    inputContainerRightElements={
                        this.props.customerSearchIsLoading ? [loadingIndicator] : null
                    }
                    arrowRenderer={() => null}
                    onChange={(value) =>
                        this.setState(
                            {
                                initialValue: value,
                            },
                            () => onChange(value, true)
                        )
                    }
                    initialFilterStr={initialValue}
                    value={initialValue}
                    onInputChange={(val) => this.onInputChange(val)}
                    optionRenderer={this.optionRenderer}
                    optionHeight={42}
                    valueKey={PROPS_AGVANCE_CUSTOMER_GUID}
                    options={options}
                    preFixinputContainerRightElements
                    noOptionsRenderer={() =>
                        noOptionRenderer({
                            formatMessage,
                            isAgvanceCustomerListOutOfBound,
                            initialValue,
                        })
                    }
                />
                {!(record && record.length) ? null : (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_AGVANCE_GROWER_NAME]: {
                                title: formatMessage(messages.name),
                                className: "agvance-name",
                                printer: this.printCustomerName,
                            },
                            [PROPS_ORG_LEVEL_NAME]: {
                                title: formatMessage(messages.location),
                                className: "agvance-location",
                            },
                            [PROPS_IS_PRIMARY]: {
                                title: formatMessage(messages.primary),
                                className: "agvance-checkbox",
                                printer: this.printIsPrimary,
                            },
                        }}
                        className="additional-agvance-customer-grid"
                        onDelete={(option) =>
                            this.onChange(option[PROPS_AGVANCE_CUSTOMER_GUID], false)
                        }
                    />
                )}
            </div>
        );
    }
}

export const AdditionalAgvanceCustomer = injectIntl(AdditionalAgvanceCustomer_);
