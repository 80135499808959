import React, { Component } from "react";

import SlidingPanel from "~/sliding-panel/sliding-panel";
import viewResolver from "~/utils/routing/view-resolver";

import { ActionPanel } from "./action-panel";
import { ErrorBoundary } from "./error-boundary";
import { Header } from "~/header";
import { MapContainer } from "./map";
import { NotificationContainer } from "./notifications";
import { ThemeRoot } from "./theme";

import "./root-component.css";

export class RootComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentNameCode: null,
            childNameCode: null,
            slidingPanelProps: {},
        };
    }

    closeSlidingPanel = () => {
        this.setState({
            parentNameCode: null,
            childNameCode: null,
            slidingPanelProps: {},
        });
    };

    loadDetailPage = (childNameCode, parentNameCode, slidingPanelProps) => {
        this.setState({ childNameCode, parentNameCode, slidingPanelProps });
    };

    renderSlidingPanel = (parentNameCode = null, childNameCode = null, slidingPanelProps = {}) => {
        if (parentNameCode) {
            const { withSlidingPanel, component } = viewResolver(
                this.state.parentNameCode,
                this.state.childNameCode
            );
            if (withSlidingPanel) {
                return React.createElement(component, {
                    closeSlidingPanel: this.closeSlidingPanel,
                    ...slidingPanelProps,
                });
            }
            return (
                <SlidingPanel
                    close={this.closeSlidingPanel}
                    navigateTo={{ parentNameCode, childNameCode }}
                    {...slidingPanelProps}
                />
            );
        }
    };

    render() {
        const { parentNameCode, childNameCode, slidingPanelProps } = this.state;
        return (
            <ThemeRoot className="root-component" hideBackground>
                <ErrorBoundary>
                    <Header
                        loadDetailPage={this.loadDetailPage}
                        closeSidePanel={this.closeSlidingPanel}
                    />
                    <div className="main-content-container">
                        <ActionPanel loadDetailPage={this.loadDetailPage} />
                        <MapContainer />
                        {this.renderSlidingPanel(parentNameCode, childNameCode, slidingPanelProps)}
                    </div>
                </ErrorBoundary>
                <NotificationContainer />
            </ThemeRoot>
        );
    }
}
