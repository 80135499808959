import React, { useEffect, useRef } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { FieldAPI, ReportAPI } from "@ai360/core";

import {
    Checkbox,
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SelectInput,
    SubSection,
} from "~/core";
import { IOption, ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { IRecordChildObject, IRecordObject } from "~/core/components/tree-view/tree-view";

import { selectors as picklistSelectors, picklistNames, ACTIVE_YN } from "~/core/picklist";
import { getSelectedRecGuidSet } from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { getRecGeneralGuidToRecSummaryMap } from "~/recs-events/recs/selectors";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";
import { getEventGeneralGuidToEventSummaryMap } from "~/recs-events/events/selectors";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { messages } from "~/reports/i18n-messages";
import { actions, model } from "~/reports/data";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import {
    getReportDataByReportType,
    getCropOptionsData,
    getYieldByCompareByOptions,
} from "~/reports/data/selectors";

import { getBasemapOptions } from "../utils";
import { updateYieldByCompareByOptions } from "../data/actions";
import { CategoryTree } from "../components";
import { keywords } from "../keywords";

type SkyYieldByProps = {
    selectedCrop: FieldAPI.ICrop | null;
};

function SkyYieldBy({
    hideDefaultOptions,
    intl: { formatMessage },
    onInputChange,
    selectedCrop,
}: ReportOptionsComponentProps & SkyYieldByProps) {
    const dispatch = useAppDispatch();
    const [showPageSelection, setShowPageSelection] = React.useState(false);
    const reportData = useAppSelector(getReportDataByReportType(SkyYieldBy.reportName));

    const yieldByCompareOptions = useAppSelector(getYieldByCompareByOptions);

    const validEventTypes = [
        keywords.applicationEvent,
        keywords.plantingEvent,
        keywords.harvestEvent,
        keywords.tillageEvent,
    ];
    const selectedEventGuidList = useAppSelector(getSelectedEventGuidSet);

    const validRecTypes = [keywords.applicationManual, keywords.plantingManual];
    const selectedRecGuidList = useAppSelector(getSelectedRecGuidSet);

    const crops = useAppSelector(getCropOptionsData);
    const agEventSummaryMap = useAppSelector(getEventGeneralGuidToEventSummaryMap);
    const agRecSummaryMap = useAppSelector(getRecGeneralGuidToRecSummaryMap);
    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );
    const seasonsToDisplay = useRef<string[]>();

    useEffect(() => {
        dispatch(actions.getYieldByCompareByOption({}));
    }, []);

    useEffect(() => {
        onInputChange(model.PROPS_SELECTED_EVENT_GUID_LIST, [...selectedEventGuidList]);
        onInputChange(model.PROPS_SELECTED_REC_GUID_LIST, [...selectedRecGuidList]);

        if (hideDefaultOptions) {
            return;
        }
        if (selectedEventGuidList.size > 0 || selectedRecGuidList.size > 0) {
            const seasonGuids = [];
            for (const recGeneralGuid of selectedRecGuidList) {
                const rec = agRecSummaryMap.get(recGeneralGuid);
                if (validRecTypes.includes(rec.recType)) {
                    seasonGuids.push(rec.croppingSeasonGuid);
                }
            }
            for (const eventGeneralGuid of selectedEventGuidList) {
                const event = agEventSummaryMap.get(eventGeneralGuid);
                if (validEventTypes.includes(event.agEventTypeName)) {
                    seasonGuids.push(event.croppingSeasonGuid);
                }
            }
            const seasonsText = [];
            for (const seasonGuid of seasonGuids) {
                const selectedSeason = seasons.find((season) => season.value === seasonGuid);
                if (seasonsText.indexOf(selectedSeason.label) === -1) {
                    seasonsText.push(selectedSeason.label);
                }
            }
            seasonGuids.sort();
            seasonsToDisplay.current = seasonsText;
        } else {
            seasonsToDisplay.current = [];
        }
    }, [selectedEventGuidList, selectedRecGuidList]);

    const basemapOptions = useAppSelector(getBasemapOptions);
    const optionsDisabled = selectedEventGuidList.size > 0 || selectedRecGuidList.size > 0;
    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] ||
        reportData[model.PROPS_END_DATE] ||
        selectedEventGuidList.size > 0 ||
        selectedRecGuidList.size > 0;
    const displayTypeOptions: IOption[] = [
        {
            label: formatMessage(messages.coverage),
            value: "coverage",
        },
        {
            label: formatMessage(messages.polygon),
            value: "polygon",
        },
    ];

    const updateOptions = (categories) => {
        dispatch(updateYieldByCompareByOptions(categories));
    };

    const yieldByDataset: IRecordObject[] = [
        {
            name: formatMessage(messages.yieldByPlanting),
            id: keywords.planting,
            isOpen: true,
            children: [
                {
                    id: keywords.varietyHybrid,
                    name: formatMessage(messages.varietyHybrid),
                },
                {
                    id: keywords.seedingRate,
                    name: formatMessage(messages.seedingRate),
                },
                {
                    id: keywords.seedingTargetRate,
                    name: formatMessage(messages.targetRate),
                },
                {
                    id: keywords.monitorVariety,
                    name: formatMessage(messages.monitorVariety),
                },
                {
                    id: keywords.aliasVariety,
                    name: formatMessage(messages.alias),
                },
            ] as IRecordChildObject[],
        },
        {
            name: formatMessage(messages.yieldByApplication),
            id: keywords.application,
            isOpen: true,
            children: [
                {
                    id: keywords.product,
                    name: formatMessage(messages.product),
                },
                {
                    id: keywords.productAppliedRate,
                    name: formatMessage(messages.appliedRate),
                },
                {
                    id: keywords.productTargetRate,
                    name: formatMessage(messages.targetRate),
                },
            ],
        },
        {
            name: formatMessage(messages.yieldByTillage),
            id: keywords.tillage,
            isOpen: true,
            children: [
                {
                    id: keywords.tillageMethod,
                    name: formatMessage(messages.tillageMethod),
                },
                {
                    id: keywords.tillageDepth,
                    name: formatMessage(messages.tillageDepth),
                },
            ],
        },
        {
            name: formatMessage(messages.yieldBySoilType),
            id: keywords.soilType,
            isOpen: true,
            children: [
                {
                    id: keywords.soilTypeName,
                    name: formatMessage(messages.soilTypeName),
                },
            ],
        },
    ];

    return (
        <div className="report-options">
            <Section>
                {hideDefaultOptions ? null : (
                    <React.Fragment>
                        <SubSection>
                            <CroppingSeason
                                disabled={croppingSeasonDisabled}
                                required={!croppingSeasonDisabled}
                                reportType={SkyYieldBy.reportName}
                                valuesToDisplay={seasonsToDisplay.current}
                            />
                            <SelectInput<FieldAPI.ICrop>
                                disabled={optionsDisabled}
                                placeholderText={formatMessage(messages.crop)}
                                options={optionsDisabled ? [] : crops}
                                onChange={(value) => {
                                    onInputChange(
                                        model.PROPS_CROP_GUID,
                                        value ? value.cropGuid : null
                                    );
                                }}
                                value={optionsDisabled ? null : (selectedCrop as FieldAPI.ICrop)}
                            />
                        </SubSection>
                        <SubSection>
                            <DateInput
                                disabled={optionsDisabled}
                                placeholderText={formatMessage(messages.startDate)}
                                onChange={(value) => onInputChange(model.PROPS_START_DATE, value)}
                                timeFormat={false}
                                value={reportData[model.PROPS_START_DATE]}
                            />
                            <DateInput
                                disabled={optionsDisabled}
                                placeholderText={formatMessage(messages.endDate)}
                                onChange={(value) => onInputChange(model.PROPS_END_DATE, value)}
                                timeFormat={false}
                                value={reportData[model.PROPS_END_DATE]}
                            />
                        </SubSection>
                    </React.Fragment>
                )}
                <SubSection>
                    <SelectInput
                        required
                        clearable={false}
                        containerClassNames={["display-options-list"]}
                        placeholderText={formatMessage(messages.displayType)}
                        options={displayTypeOptions}
                        onChange={(stringValue) => {
                            const value = stringValue !== "coverage";
                            onInputChange(model.PROPS_SHOW_YIELD_POLYGON, value);
                        }}
                        value={reportData[model.PROPS_SHOW_YIELD_POLYGON] ? "polygon" : "coverage"}
                    />
                    <SelectInput
                        required
                        optionIsHiddenKey={ACTIVE_YN}
                        containerClassNames={["yield-basemap-select"]}
                        clearable={false}
                        placeholderText={formatMessage(messages.selectedBasemap)}
                        options={basemapOptions}
                        onChange={(value) => onInputChange(model.PROPS_SELECTED_BASEMAP, value)}
                        value={reportData[model.PROPS_SELECTED_BASEMAP]}
                    />
                </SubSection>
                <SubSection>
                    <NoLink
                        className="categories-link report-options-modal-link page-selection"
                        label={formatMessage(messages.yieldByCompareByOptions)}
                        onClick={() => setShowPageSelection(true)}
                    />
                    <DialogBox
                        unrestricted
                        title={formatMessage(messages.yieldByCompareByOptions)}
                        isOpen={showPageSelection}
                        onAction={() => setShowPageSelection(false)}
                        onClose={() => setShowPageSelection(false)}
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                    >
                        <CategoryTree
                            onStatusChange={(values) => updateOptions(values)}
                            formatMessage={formatMessage}
                            categories={yieldByCompareOptions}
                            categoryDataSet={yieldByDataset}
                        />
                    </DialogBox>
                    <Checkbox
                        containerClassNames={["yield-custom-imagery"]}
                        onChange={(e, value) =>
                            onInputChange(model.PROPS_USE_CUSTOM_IMAGERY, value)
                        }
                        value={reportData[model.PROPS_USE_CUSTOM_IMAGERY]}
                        label={formatMessage(messages.useCustomImagery)}
                    />
                </SubSection>
            </Section>
        </div>
    );
}

SkyYieldBy.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
    selectedCrop: null,
};

SkyYieldBy.defaultReportOptions = {
    [model.PROPS_USE_CUSTOM_IMAGERY]: false,
    [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
    [model.PROPS_CROPPING_SEASON_GUID_LIST]: [],
    [model.PROPS_CROP_GUID]: null,
    [model.PROPS_START_DATE]: null,
    [model.PROPS_END_DATE]: null,
    [model.PROPS_SHOW_YIELD_POLYGON]: false,
    [model.PROPS_SELECTED_BASEMAP]: "reportImagery",
};

SkyYieldBy.reportPreferences = [model.PROPS_SELECTED_BASEMAP, model.PROPS_SHOW_YIELD_POLYGON];

SkyYieldBy.reportName = ReportAPI.ReportNames.AGVANCE_SKY_YIELD_BY_REPORT;

export default injectIntl(SkyYieldBy) as ReportOptionsComponent<
    ReportOptionsComponentProps & SkyYieldByProps
>;
