import {
    accordionReducer,
    // model as accordionModel
} from "~/accordion";
import { INIT } from "~/accordion/actions";

import * as actions from "./actions";
import * as selectors from "./selectors";
import * as immutableUtils from "~/utils/immutable";

export const layerInfoInitialState = {
    isLoading: false,
    analysisSummary: null,
    analysisInfoPicklists: {},
    analysisLayerTypes: [],
    fields: [],
    fieldGuids: [],
    managementAreaAttributes: [],
    managementAreaTypes: [],
    normalizedYieldOptions: [],
    normalizedYieldType: null,
    processingAnalysisInfo: new Map(),
    seedStrongOptions: [],
    dropdownData: {
        cropData: [],
    },
    ecDataAttributes: [],
    [selectors.ACCORDION_KEY]: accordionReducer(undefined, { payload: null, type: INIT }),
};

export const analysisInfoReducer = (state = layerInfoInitialState, action) => {
    switch (action.type) {
        case actions.CLOSE_ANALYSIS_INFO: {
            return Object.freeze({
                ...state,
                fieldGuids: [],
            });
        }
        case actions.SET_NORMALIZED_YIELD_TYPE: {
            const { normalizedYieldType } = action.payload;
            return Object.freeze({
                ...state,
                normalizedYieldType,
            });
        }
        case actions.SET_ANALYSIS_SUMMARY: {
            const { analysisSummary } = action.payload;
            return Object.freeze({
                ...state,
                analysisSummary,
            });
        }
        case actions.SET_ANALYSIS_DETAILS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.FETCH_ANALYSIS_LAYER_TYPES_SUCCEEDED: {
            const { analysisLayerTypes } = action.payload;
            return Object.freeze({
                ...state,
                analysisLayerTypes,
            });
        }
        case actions.SET_EC_DATA_ATTRIBUTES: {
            const { attributes } = action.payload;
            return Object.freeze({
                ...state,
                ecDataAttributes: attributes,
            });
        }
        case actions.FETCHED_DROPDOWN_DATA: {
            return Object.freeze({
                ...state,
                dropdownData: action.payload,
            });
        }
        case actions.REMOVE_PROCESSING_ANALYSIS_INFO: {
            const { fieldGuid } = action.payload;
            return Object.freeze({
                ...state,
                processingAnalysisInfo: immutableUtils.noMutateMapDelete(
                    state.processingAnalysisInfo,
                    fieldGuid
                ),
            });
        }
        case actions.RESET_MANAGEMENT_AREA_ATTRIBUTE_DATA: {
            const analysisSummary = { ...state.analysisSummary };
            if (analysisSummary.analysisLayerGuid) {
                return state;
            }
            analysisSummary.managementAreaData = {};
            analysisSummary.analysisLayerAreaList.forEach((l) => {
                analysisSummary.managementAreaData[l.analysisLayerAreaId] =
                    state.managementAreaAttributes.map((a) => {
                        const attributes = { ...a };
                        return attributes;
                    });
            });
            return Object.freeze({
                ...state,
                analysisSummary,
            });
        }
        case actions.SET_ANALYSIS_INFO_PICKLISTS: {
            const { analysisInfoPicklists } = action.payload;
            return Object.freeze({
                ...state,
                analysisInfoPicklists,
            });
        }
        case actions.SET_FIELDS: {
            const { fields } = action.payload;
            return Object.freeze({
                ...state,
                fields,
            });
        }
        case actions.SET_FIELD_GUIDS: {
            const { fieldGuids } = action.payload;
            return Object.freeze({
                ...state,
                fieldGuids,
            });
        }
        case actions.SET_MANAGEMENT_AREA_ATTRIBUTE_DATA: {
            const { areaId, name, textValue, yesNoValue, decimalIntValue } = action.payload;
            const analysisSummary = { ...state.analysisSummary };
            const attributes = analysisSummary.managementAreaData[areaId];
            if (attributes) {
                attributes
                    .filter((a) => a.name === name)
                    .forEach((a) => {
                        a.textValue = textValue;
                        a.yesNoValue = yesNoValue;
                        a.decimalIntValue = decimalIntValue;
                    });
                analysisSummary.managementAreaData = {
                    ...analysisSummary.managementAreaData,
                };
            }
            return Object.freeze({
                ...state,
                analysisSummary,
            });
        }
        case actions.SET_MANAGEMENT_AREA_ATTRIBUTES: {
            const attributues = action.payload.sort((a, b) => a.columnOrder - b.columnOrder);
            return Object.freeze({
                ...state,
                managementAreaAttributes: attributues,
            });
        }
        case actions.SET_MANAGEMENT_AREA_TYPES: {
            const managementAreaTypes = action.payload.map((managementAreaType) => ({
                label: managementAreaType.name,
                value: managementAreaType.templateGuid,
            }));
            return Object.freeze({
                ...state,
                managementAreaTypes,
            });
        }
        case actions.SET_PROCESSING_ANALYSIS_INFO: {
            const { fieldGuid, processingAnalysisInfo } = action.payload;
            return Object.freeze({
                ...state,
                processingAnalysisInfo: immutableUtils.noMutateMapSet(
                    state.processingAnalysisInfo,
                    fieldGuid,
                    processingAnalysisInfo
                ),
            });
        }
        case actions.SET_NORMALIZED_YIELD_OPTIONS: {
            const { normalizedYieldOptions } = action.payload;
            return Object.freeze({
                ...state,
                normalizedYieldOptions,
            });
        }
        case actions.SET_SEED_STRONG_OPTIONS: {
            const { seedStrongOptions } = action.payload;
            return Object.freeze({
                ...state,
                seedStrongOptions,
            });
        }
        case actions.UPDATE_EC_DATA_ANALYSIS_SUMMARY:
        case actions.UPDATE_IMAGERY_ANALYSIS_SUMMARY:
        case actions.UPDATE_MANAGEMENT_AREA_ANALYSIS_SUMMARY:
        case actions.UPDATE_NORMALIZED_YIELD_ANALYSIS_SUMMARY:
        case actions.UPDATE_PROFITLOSS_ANALYSIS_SUMMARY:
        case actions.UPDATE_SEEDSTRONG_ANALYSIS_SUMMARY: {
            const { newProps } = action.payload;
            return Object.freeze({
                ...state,
                analysisSummary: {
                    ...state.analysisSummary,
                    ...newProps,
                },
            });
        }
        case actions.UPDATE_MANAGEMENT_AREA_APPLY_TO_AREA: {
            const { analysisSummary } = state;
            if (
                analysisSummary.managementAreaData == null ||
                Object.keys(analysisSummary.managementAreaData).length === 0
            ) {
                return state;
            }
            const analysisLayerAreaList = analysisSummary.analysisLayerAreaList.map((zone) => {
                const areaData = analysisSummary.managementAreaData[zone.analysisLayerAreaId];
                zone.applyAnalysisToArea =
                    areaData != null &&
                    areaData.some(
                        (a) =>
                            (a.textValue != null && a.textValue.trim() !== "") ||
                            a.yesNoValue === true ||
                            a.decimalIntValue != null
                    );
                return zone;
            });
            return Object.freeze({
                ...state,
                analysisSummary: {
                    ...analysisSummary,
                    analysisLayerAreaList,
                },
            });
        }
        case actions.FETCH_ACTUAL_DETAIL_COST_COMPLETED: {
            return Object.freeze({
                ...state,
                applicationCostDetails: action.payload.results,
            });
        }
        case actions.CLOSE_PROFIT_LOSS_DETAILS: {
            return Object.freeze({
                ...state,
                applicationCostDetails: [],
            });
        }
        case actions.SET_IS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                profitLossDialog: isLoading,
            });
        }
        default: {
            const accordionState = accordionReducer(state[selectors.ACCORDION_KEY], action);
            if (accordionState === state[selectors.ACCORDION_KEY]) {
                return state;
            }
            return Object.freeze({
                ...state,
                [selectors.ACCORDION_KEY]: accordionState,
            });
        }
    }
};
