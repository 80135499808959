import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import { withUser } from "~/hocs";
import { ADD, adminData, FALSE, TRUE } from "~/admin/data";
import { OrgLevelList, PROPS_STATE_ABBREVIATION } from "~/admin/agBytes/components/org-level-list";
import { SalespersonCustomerList } from "./salesperson-customer-list";
import { AppHelpers, JohnDeereAPI } from "@ai360/core";

import {
    getAgBytesErrorClassNames,
    getCountryGuid,
    getGuid,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
    preventBubbleUp,
} from "~/admin/utils";
import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    FileSelector,
    Menu,
    NoLink,
    RadioButton,
    RadioButtonGroup,
    Section,
    SubSection,
    TextArea,
    TextInput,
    ZeroToInfiniteGrid,
} from "~/core";
import { createAddLinkLabelText, messages as globalMessages } from "~/i18n-messages";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import Address, { model as addressModel, SHIP_TO } from "../component/address";
import { AdditionalAgvanceCustomer } from "../component/additional-agvance-customer";
import SearchAgvanceCustomer from "../component/search-agvance-customer";
import EquipmentAddEdit from "../equipment/add-edit/add-edit-container";
import { model, service } from "./data";
import { messages } from "../i18n-messages";
import { keywords as adminKeywords } from "~/admin/containers/keywords";
import { PersonalityTypes } from "~/utils/keywords";
import { FAILURE, SUCCESS } from "~/hocs/needs/utils";
import { AgvanceUtils } from "./agvance-utils";
import PortalIntegration from "../component/portal-integration";
import { MyAgDataRegistration } from "~/admin/setup/customer/myagdata-registration-list";

// Style
import "./customer.css";

// Keywords
export const keywords = {
    shipToType: "S",
};

export class Customer extends Component {
    static propTypes = {
        additionalAgvanceCustomerList: PropTypes.array,
        agvanceCustomerAddressList: PropTypes.array,
        agvanceCustomerList: PropTypes.array,
        apiErrors: PropTypes.array,
        closeSlidingPanel: PropTypes.func,
        clearAgvanceCustomerList: PropTypes.func,
        clearOracleCustomer: PropTypes.func,
        communityDataDefault: PropTypes.bool,
        companyGuid: PropTypes.string,
        createCustomerOracleId: PropTypes.func,
        createCustomer: PropTypes.func,
        customerNameExists: PropTypes.bool,
        createEquipment: PropTypes.func,
        customerName: PropTypes.string,
        customerRecord: PropTypes.object,
        customerSearchIsLoading: PropTypes.bool,
        enableMyAgData: PropTypes.bool.isRequired,
        enrollCustomer: PropTypes.func,
        enrollmentRequired: PropTypes.bool,
        equipmentList: PropTypes.array,
        fetchAgvanceCustomer: PropTypes.func,
        fetchAgvanceCustomerWithNameSearch: PropTypes.func,
        fetchCustomerEquipmentList: PropTypes.func,
        fetchCustomerExists: PropTypes.func,
        fetchDropdownData: PropTypes.func,
        fetchEnrollmentForm: PropTypes.func,
        fetchMyAgDataRegistration: PropTypes.func,
        fetchOwnerCommunityData: PropTypes.func,
        getAgvanceCustomer: PropTypes.func,
        id: PropTypes.any,
        intl: intlShape.isRequired,
        isAdditionalAgvanceCustomerSearch: PropTypes.bool,
        isAgvanceCustomerListOutOfBound: PropTypes.bool,
        needs: PropTypes.func,
        newPrimaryCustomer: PropTypes.any,
        onCloseSlidingPanel: PropTypes.func,
        onSaveFailed: PropTypes.func,
        onSaveSuccess: PropTypes.func,
        oracleCustomer: PropTypes.object,
        personalityId: PropTypes.number,
        removeCustomerRecord: PropTypes.func,
        salespersonList: PropTypes.array,
        sendAuthentication: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        setAgvanceCustomerListOutOfBound: PropTypes.func,
        showCreateCustomerConfDialog: PropTypes.bool,
        updateCustomer: PropTypes.func,
        updateEnrollmentForm: PropTypes.func,
        unenrollCustomer: PropTypes.func,
        userGuid: PropTypes.string.isRequired,
        userRole: PropTypes.object.isRequired,
    };

    static ADDRESS_TYPE_GUID = "addressTypeGuid";
    static COUNTRY_GUID = "countryGuid";
    static STATE_GUID = "stateGuid";

    constructor(props) {
        super(props);
        this.state = {
            [model.PROPS_CUSTOMER_ADDRESS_LIST]: [],
            [model.PROPS_ADDRESS_TYPE_LIST]: [],
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: [],
            [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: [],
            [model.PROPS_ORG_LEVEL_LIST]: [],
            [model.PROPS_SALESPERSON_CUSTOMER_LIST]: [],
            agvanceCustomerList: [],
            apiErrors: [],
            showCreateCustomerConfDialog: false,
            showEquipmentScreen: false,
            saveEquipmentData: false,
            equipmentListData: [],
            showDataPoolingChangePrompt: false,
            showOracleDialog: false,
            salespersonList: [
                ...new Map(
                    props.salespersonList.map((item) => [item["salespersonGuid"], item])
                ).values(),
            ],
            [model.PROPS_DATA_POOLING]: null,
            [model.PROPS_DATA_POOLING_ORIGINAL]: null,
            [model.ENROLLED_YN]: false,
            [model.ENROLLED_YN_ORIGINAL]: false,
            [model.PROPS_ENROLLMENT_MOBILE_PHONE]: "",
            removedCustomers: [],
            removedOrgLevels: [],
            additionalAgvanceCustomerList: [],
            [model.PROPS_MYAGDATA_REGISTRATIONS]: null,
        };
        this.addEditPanel = {
            mode: adminKeywords.ADD,
        };
        this.companyGuid = null;
        this.customer = {};
        this.jdOrganizationIds = [];
        this.selectedCustomer = [];
        this.equipmentAddRequestId = null;
        this.sendAuthRequestId = null;
        props.needs([this.props.fetchOwnerCommunityData({ model: null })]);
    }

    static defaultProps = {
        communityDataDefault: null,
    };

    _closeSlidingPanel = () => {
        if (this.props.onCloseSlidingPanel) {
            this.props.onCloseSlidingPanel();
        }
        this.props.setAgvanceCustomerListOutOfBound({ flag: false, count: 0 });
        this.props.closeSlidingPanel();
    };

    _getMenuItems = () => {
        const { formatMessage } = this.props.intl;
        const disabled = this.customer[model.PROPS_ENROLLED_AUTO_YN];
        const menuItems = [
            {
                label: formatMessage(messages.remove),
                action: () => this.setState({ showRemoveEnrollmentFormModal: true }),
                disabled: disabled,
            },
        ];

        menuItems.forEach((menuItem, i) => {
            menuItem.key = i;
        });
        return menuItems;
    };

    _initializeDropdowns = (nextProps) => {
        this._initializeAddressType(nextProps);
        this._initializeCountryList(nextProps);
        this._initializeStateList(nextProps);
        this._initializeOrgLevel(nextProps);
        this._initializeAgvanceCustomerList(nextProps);
        this._initializeSalespersonList(nextProps);
        this._initializeAdditionalCustomerList(nextProps);
        this._initializePortalIntegration(nextProps);
    };

    _initializeAdditionalCustomerList = (nextProps) => {
        if (nextProps.additionalAgvanceCustomerList !== this.state.additionalAgvanceCustomerList) {
            this.setState({
                additionalAgvanceCustomerList: this.removeSelectedOptionList(
                    AgvanceUtils.prepareAgvanceCustomerList(
                        nextProps.additionalAgvanceCustomerList
                    ),
                    [
                        ...AgvanceUtils.getNonPrimaryCustomerList(
                            this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]
                        ),
                        ...this.selectedCustomer,
                    ]
                ),
            });
        }
    };

    _initializeAddressType = (nextProps) => {
        if (
            nextProps[model.PROPS_ADDRESS_TYPE_LIST] !== this.state[model.PROPS_ADDRESS_TYPE_LIST]
        ) {
            this.setState({
                [model.PROPS_ADDRESS_TYPE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_TYPE_LIST],
                    { guid: Customer.ADDRESS_TYPE_GUID, label: "name" }
                ),
            });
        }
    };

    _initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: Customer.COUNTRY_GUID, label: "name", id: "id" }
                ),
            });
        }
    };

    _initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATE_LIST] !== this.state[model.PROPS_STATE_LIST]) {
            this.setState({
                [model.PROPS_STATE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATE_LIST],
                    { guid: Customer.STATE_GUID, label: "abbreviation" }
                ),
            });
        }
    };

    _initializeOrgLevel = (nextProps) => {
        if (nextProps[model.PROPS_ORG_LEVEL_LIST] !== this.props[model.PROPS_ORG_LEVEL_LIST]) {
            this.setState(
                {
                    [model.PROPS_ORG_LEVEL_LIST]: this._prepareOrgLevel(
                        nextProps[model.PROPS_ORG_LEVEL_LIST]
                    ),
                },
                () => {
                    if (this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].length) {
                        this._getAgvanceCustomerList(
                            this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].map(
                                (item) => item.orgLevelGuid
                            )
                        );
                    }
                }
            );
        }
    };

    _initializeAgvanceCustomerList = (nextProps) => {
        if (
            nextProps.agvanceCustomerList ||
            JSON.stringify(nextProps.agvanceCustomerList) !==
                JSON.stringify(this.state.agvanceCustomerList)
        ) {
            const filteredAgvanceCustomerList = this._filterAgvanceCustomerListWithOrgLevel(
                nextProps.agvanceCustomerList,
                this.state[model.PROPS_ORG_LEVEL_LIST]
            );
            const agvanceCustomerList = this.removeSelectedOptionList(
                AgvanceUtils.prepareAgvanceCustomerList(filteredAgvanceCustomerList),
                [
                    ...AgvanceUtils.getNonPrimaryCustomerList(
                        this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]
                    ),
                    ...this.selectedCustomer,
                ]
            );
            this.setState({ agvanceCustomerList });
        }
    };

    _initializeSalespersonList = (nextProps) => {
        if (nextProps.salespersonList !== this.props.salespersonList) {
            this.setState({
                salespersonList: [
                    ...new Map(
                        nextProps.salespersonList.map((item) => [item["salespersonGuid"], item])
                    ).values(),
                ],
            });
        }
    };

    _initializePortalIntegration = (nextProps) => {
        if (nextProps?.companyGuid !== this.state.companyGuid) {
            JohnDeereAPI.getOrganizations(nextProps?.companyGuid).then((idObjects) => {
                this.setState({ jdOrganizationIds: idObjects });
            });
            this.setState({ companyGuid: nextProps?.companyGuid });
        }
    };

    _updateEnrollmentForm = (data) => {
        this.setState({ showRemoveEnrollmentFormModal: false });
        if (!data || !data.files) {
            this.props.updateEnrollmentForm({
                enrollmentForm: "",
                enrollmentFormType: "",
                customerRecord: this.props.customerRecord,
            });
            return;
        }
        const targetFile = data.files[0];
        AppHelpers.fileToBase64(targetFile).then((base64) => {
            if (base64) {
                const convertedString = AppHelpers.base64ImageToBase64string(base64);
                this.props.updateEnrollmentForm({
                    enrollmentForm: convertedString,
                    enrollmentFormType: targetFile.name.split(".").pop(),
                    customerRecord: this.props.customerRecord,
                });
            }
        });
    };

    _updatePhoneNumber = (value) => {
        let newValue = "";
        if (value) {
            const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            newValue = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        }
        this._onTextChange(model.PROPS_ENROLLMENT_MOBILE_PHONE, newValue);
        this.setState({ [model.PROPS_ENROLLMENT_MOBILE_PHONE]: newValue });
    };
    _onTextChange = (formKey, value, callback) => {
        this.customer = onTextChange(this.customer, { formKey: [formKey], value }, callback);
    };

    _onViewEnrollmentForm = () => {
        this.props.fetchEnrollmentForm({
            enrolledFormUrl: this.customer[model.PROPS_ENROLLED_FORM_URL],
            customerName: this.customer[model.PROPS_NAME],
        });
    };

    _onSendAuthentication = () => {
        const authProps = {
            enrollmentEmail: this.customer[model.PROPS_ENROLLMENT_EMAIL],
            mobilePhone: this.customer[model.PROPS_ENROLLMENT_MOBILE_PHONE],
            signerName: this.customer[model.PROPS_ENROLLMENT_SIGNER_NAME],
            customerGuid: this.customer[model.PROPS_CUSTOMER_GUID],
        };
        this.sendAuthRequestId = this.props.needs([this.props.sendAuthentication(authProps)]);
    };

    _onProcessSave = () => {
        const { needs, personalityId } = this.props;
        if (
            this.addEditPanel.mode === adminKeywords.ADD &&
            personalityId !== PersonalityTypes.DISCONNECTED
        ) {
            this.customerNameExistsRequestId = needs([
                this.props.fetchCustomerExists(this.customer),
            ]);
        } else {
            this._onSave();
        }
    };

    _onSave = () => {
        const { enrollCustomer, unenrollCustomer, needs, personalityId } = this.props;
        let callingApi;
        this.customer[model.PROPS_ENROLLED_MANUAL_CHANGE] =
            this.state[model.ENROLLED_YN] !== this.state[model.ENROLLED_YN_ORIGINAL];
        this.customer[model.PROPS_DATA_POOLING_CHANGE] =
            this.state[model.PROPS_DATA_POOLING] !== this.state[model.PROPS_DATA_POOLING_ORIGINAL];
        if (this.addEditPanel.mode === adminKeywords.ADD) {
            if (personalityId === PersonalityTypes.HELENA_CORPORATE) {
                callingApi = this.props.createCustomerOracleId;
            } else {
                callingApi = this.props.createCustomer;
            }
        } else {
            callingApi = this.props.updateCustomer;
        }
        if (this.customer[model.PROPS_ENROLLED_MANUAL_CHANGE] === true) {
            const updateCustomer =
                this.state[model.ENROLLED_YN] === true ? enrollCustomer : unenrollCustomer;
            updateCustomer(this.customer[model.PROPS_CUSTOMER_GUID]);
        }
        this.props.setAgvanceCustomerListOutOfBound({ flag: false, count: 0 });
        this.addEditRequestId = needs([callingApi(this.customer)]);
    };

    _filterAgvanceCustomerListWithOrgLevel = (customerList = [], orgLevelList) => {
        return customerList.filter((record) => {
            const { orgLevelGuid, oracleId } = record;
            return (
                orgLevelList.some((orgLevel) => orgLevelGuid === orgLevel.orgLevelGuid) && oracleId
            );
        });
    };

    _prepareOrgLevel = (orgLevelList = [], guid) => {
        let retOrgLevelList = orgLevelList;
        // Agvance customer
        if (this.props.personalityId !== PersonalityTypes.DISCONNECTED) {
            retOrgLevelList = orgLevelList.filter((record) => {
                const { agvanceLinked, orgLevelGuid } = record;
                if (guid) {
                    return orgLevelGuid === guid && agvanceLinked;
                } else {
                    return agvanceLinked;
                }
            });
            if (retOrgLevelList.length === 1) {
                this._getAgvanceCustomerList(retOrgLevelList.map((item) => item.orgLevelGuid));
            }
        }

        if (retOrgLevelList.length === 1 && !this._isEditMode()) {
            this.setState({
                [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: retOrgLevelList,
            });
            this._orgLevelChange(retOrgLevelList);
        }

        return retOrgLevelList;
    };

    _getAgvanceCustomerList = (arrayOfGuids = [this.props.companyGuid]) => {
        this.props.needs([this.props.fetchAgvanceCustomer(arrayOfGuids)]);
    };

    _isPrimaryLocationRemoved(newLocationList) {
        if (this.selectedCustomer.length) {
            const { orgLevelGuid } = this.selectedCustomer[0];
            if (newLocationList.some((item) => item.orgLevelGuid === orgLevelGuid)) {
                return false;
            }
        }
        return true;
    }

    _prepCrossRefList(newLocationList) {
        const crossRefListData = [...this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]];
        if (!newLocationList.length) {
            this.setState({
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: [],
            });
            return;
        }
        const { removedCustomers } = this.state;
        const newCrossRefListData = crossRefListData.reduce((acc, item) => {
            // This allows the customer save if the current location is replaced by the child location
            const matchedLocation = newLocationList.some(
                (location) =>
                    location.orgLevelGuid === item.orgLevelGuid ||
                    (location.orgLevelParentsGuids &&
                        location.orgLevelParentsGuids.indexOf(item.orgLevelGuid) > -1)
            );
            if (matchedLocation) {
                acc.push(item);
            } else {
                removedCustomers.push(item);
            }
            return acc;
        }, []);
        this.setState(
            {
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: newCrossRefListData,
                removedCustomers,
            },
            () => {
                this._onTextChange(
                    model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST,
                    newCrossRefListData
                );
            }
        );
    }

    // This function set the removed orglevels in the state (cache)
    // Also, it handles the scenario if the removed orgLevel is added back
    _getRemovedOrgLevels = (newOrgLevels = [], removedOrgLevel = []) => {
        let newRemovedOrgLevels = [...this.state.removedOrgLevels];
        const deletedIdx = newRemovedOrgLevels.findIndex((rog) =>
            newOrgLevels.some((v) => rog.orgLevelGuid === v.orgLevelGuid)
        );
        if (removedOrgLevel) {
            newRemovedOrgLevels.push(removedOrgLevel);
        } else if (deletedIdx > -1) {
            newRemovedOrgLevels.splice(deletedIdx, 1);
        }
        return newRemovedOrgLevels;
    };

    _isDuplicateLocationSelected(value) {
        let isLocationsWithSameDBIds = false;
        if (this.selectedCustomer.length) {
            const selectedCustomerOrglLevel = value.find(
                (v) => v.orgLevelGuid === this.selectedCustomer[0].orgLevelGuid
            );
            if (selectedCustomerOrglLevel) {
                value.forEach((item) => {
                    if (
                        selectedCustomerOrglLevel.orgLevelGuid !== item.orgLevelGuid &&
                        selectedCustomerOrglLevel.agvanceDatabaseId === item.agvanceDatabaseId
                    ) {
                        isLocationsWithSameDBIds = true;
                    }
                });
            }
        }
        return isLocationsWithSameDBIds;
    }

    _getLocationCustomerListForMigration(
        orgLevelList,
        crossRefListData,
        selectedLocations,
        removedOrgLevel
    ) {
        const secondaryLocation = orgLevelList.find(
            (v) => removedOrgLevel.agvanceDatabaseId === v.agvanceDatabaseId
        );
        const crossRefs = crossRefListData.map((item) => {
            if (item.orgLevelGuid === removedOrgLevel.orgLevelGuid) {
                item.orgLevelGuid = secondaryLocation.orgLevelGuid;
            }
            return item;
        });
        this.selectedCustomer[0].orgLevelGuid = secondaryLocation.orgLevelGuid;
        selectedLocations = selectedLocations.map((sl) => {
            if (secondaryLocation.orgLevelGuid === sl.orgLevelGuid) {
                return { ...sl, isPrimary: true, restrictEditDelete: true };
            }
            return sl;
        });
        return { crossRefListData: crossRefs, selectedLocations };
    }

    _orgLevelChange = (value, removedOrgLevel = null) => {
        if (
            !this._isEditMode() &&
            this._isPrimaryLocationRemoved(value) &&
            AgvanceUtils.isAgvanceConnected(this.props.personalityId)
        ) {
            this.onCustomerRemove();
        }
        let crossRefListData = this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST];
        let selectedLocations = value;
        this._prepCrossRefList(value);
        this._onTextChange(model.PROPS_CUSTOMER_ORG_LEVEL_LIST, value);
        if (this._isDatabaseIdChanged(value)) {
            this._getAgvanceCustomerList(value.map((item) => item.orgLevelGuid));
        }
        if (
            this.addEditPanel.mode === adminKeywords.ADD &&
            value.length < this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].length &&
            AgvanceUtils.isAgvanceConnected(this.props.personalityId)
        ) {
            this.customer = {
                ...this.customer,
                [model.PROPS_NAME]: "",
                [model.PROPS_CUSTOMER_ADDRESS_LIST]: [],
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: [],
                [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: value,
            };
            this.setHeader();
            this.setState({
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: [],
                [model.PROPS_CUSTOMER_ADDRESS_LIST]: this._formAddressList({}, false),
            });
        }

        // Allow user to delete primary location,
        // if the selected locations are having same Agvance Database ID
        if (this._isDuplicateLocationSelected(value)) {
            selectedLocations = selectedLocations.map((v) => ({
                ...v,
                restrictEditDelete: false,
            }));
        }

        // Remove the primary location and auto update the crossrefList
        // to use the first matching location as primary location, match is based
        // on the AgvanceDatabaseId being same
        if (
            this.selectedCustomer[0] &&
            removedOrgLevel &&
            removedOrgLevel.orgLevelGuid === this.selectedCustomer[0].orgLevelGuid
        ) {
            const dbl = this._getLocationCustomerListForMigration(
                value,
                crossRefListData,
                selectedLocations,
                removedOrgLevel
            );
            crossRefListData = dbl.crossRefListData;
            selectedLocations = dbl.selectedLocations;
        }

        this.setState(
            {
                [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: selectedLocations,
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: crossRefListData,
                removedOrgLevels: this._getRemovedOrgLevels(value, removedOrgLevel),
            },
            () => {
                this._onTextChange(model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST, [
                    ...this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST],
                ]);
            }
        );
    };

    _isDatabaseIdChanged = (orgLevelList) => {
        const existingOrgLevelList = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST];
        return orgLevelList.some(
            (orgLevel) =>
                !existingOrgLevelList.find(
                    (t) => t.agvanceDatabaseId === orgLevel.agvanceDatabaseId
                )
        );
    };

    _onOrganizationChange = (value) => {
        this._onTextChange(model.PROPS_PARTNER_ORG_ID, value);
    };

    _salespersonCustomerChange = (value) => {
        this._onTextChange(model.PROPS_SALESPERSON_CUSTOMER_LIST, value);
        this.setState({
            [model.PROPS_SALESPERSON_CUSTOMER_LIST]: value,
        });
    };

    _selectCorrectAddressLine = (record) => {
        return AgvanceUtils.isAgvanceStandard(this.props.personalityId)
            ? record.addressType === keywords.shipToType
                ? // Agvance ShipTo addresses are offset by 1 in HAAS
                  // (eg. Agvance.AddressName = Haas.address1, Agvance.address1 = Haas.address2, etc)
                  AppHelpers.isNothing(record.address3)
                    ? AppHelpers.isNothing(record.address4)
                        ? record.address2
                        : record.address4
                    : record.address3
                : // Is Grower address
                AppHelpers.isNothing(record.address2)
                ? AppHelpers.isNothing(record.address3)
                    ? record.address1
                    : record.address3
                : record.address2
            : // not Agvance Standard use address 1
              record.address1;
    };

    _formAddressList = (record, readOnly) => {
        if (_.isEmpty(record)) {
            return [];
        }

        return [
            {
                [addressModel.PROPS_ADDRESS_TYPE_GUID]: readOnly
                    ? getGuid({
                          options: this.state[model.PROPS_ADDRESS_TYPE_LIST],
                          label: SHIP_TO,
                      })
                    : "",
                [addressModel.PROPS_ADDRESS_TYPE_NAME]: readOnly ? SHIP_TO : "",
                [addressModel.PROPS_ADDRESS_IS_PRIMARY]: true,
                [addressModel.PROPS_ADDRESS]: {
                    [addressModel.PROPS_ADDRESS_NAME]: record.address2,
                    [addressModel.PROPS_CITY]: record.city,
                    [addressModel.PROPS_COUNTRY_GUID]: readOnly
                        ? record.countryGuid
                        : getCountryGuid({
                              options: this.state[model.PROPS_COUNTRIES_LIST],
                          }),
                    [addressModel.PROPS_READ_ONLY_YN]: readOnly,
                    [addressModel.PROPS_STATE_GUID]: record.stateGuid,
                    [addressModel.PROPS_STATE_ABBREVIATION]: record.state,
                    [addressModel.PROPS_STREET_ONE]: this._selectCorrectAddressLine(record),
                    [addressModel.PROPS_ZIP_CODE]: record.zip,
                },
            },
        ];
    };

    _processCustomerNameExistsRequest = (nextProps) => {
        if (nextProps.requestIds[this.customerNameExistsRequestId] === SUCCESS) {
            this.customerNameExistsRequestId = null;
            if (nextProps.customerNameExists) {
                this.showCreateCustomerConfDialog(nextProps.customerNameExists);
            } else {
                this._onSave();
            }
        } else if (nextProps.requestIds[this.customerNameExistsRequestId] === FAILURE) {
            if (nextProps.apiErrors.length) {
                this.setState({
                    apiErrors: nextProps.apiErrors,
                });
            }
            this.customerNameExistsRequestId = null;
        }
    };

    _processAddEditRequest = (nextProps) => {
        if (
            nextProps.requestIds[this.addEditRequestId] === SUCCESS &&
            nextProps.oracleCustomer != null
        ) {
            this.addEditRequestId = null;
        } else if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
            this.setState({
                apiErrors: [],
            });
            this.addEditRequestId = null;
            if (this.props.onSaveSuccess) {
                this.props.onSaveSuccess(this.props.customerRecord);
            }
            this.props.removeCustomerRecord();
            this._closeSlidingPanel();
        } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
            if (nextProps.apiErrors.length) {
                this.setState({
                    apiErrors: nextProps.apiErrors,
                });
            }
            this.addEditRequestId = null;
            const crossRefList = this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST];
            const { removedCustomers } = this.state;
            removedCustomers.forEach((c) => {
                crossRefList.push(c);
            });

            const orgLevelList = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST];
            const { removedOrgLevels } = this.state;
            removedOrgLevels.forEach((o) => {
                orgLevelList.push(o);
            });

            this.setState(
                {
                    [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: crossRefList,
                    [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: orgLevelList,
                    removedCustomers: [],
                    removedOrgLevels: [],
                },
                () => {
                    if (this.props.onSaveFailed) {
                        this.props.onSaveFailed(this.props.customerRecord);
                    }
                    this._getAgvanceCustomerList(
                        orgLevelList.map(({ orgLevelGuid }) => {
                            return orgLevelGuid;
                        })
                    );
                }
            );
        }
    };

    _processSendAuthenticationRequest = (nextProps) => {
        if (nextProps.requestIds[this.sendAuthRequestId] === SUCCESS) {
            this.setState({
                apiErrors: [],
            });
            this.sendAuthRequestId = null;
            this.props.removeCustomerRecord();
            this._closeSlidingPanel();
        } else if (nextProps.requestIds[this.sendAuthRequestId] === FAILURE) {
            if (nextProps.apiErrors.length) {
                this.setState({
                    apiErrors: nextProps.apiErrors,
                });
            }
            this.sendAuthRequestId = null;
        }
    };

    _isDisabled = () => {
        return (
            AgvanceUtils.isAgvanceConnected(this.props.personalityId) &&
            this.state[model.PROPS_ORG_LEVEL_LIST].length > 1 &&
            this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].length === 0
        );
    };

    _isAddressDisabled = () => {
        return (
            AgvanceUtils.isAgvanceConnected(this.props.personalityId) &&
            ((this.state[model.PROPS_ORG_LEVEL_LIST].length > 1 &&
                this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].length === 0) ||
                !this.selectedCustomer.length)
        );
    };

    _isEditMode = () => {
        return this.addEditPanel.mode === adminKeywords.EDIT;
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    onChildComponentChange = (childKey, value) => {
        this.customer[childKey] = value;
    };

    setHeader = () => this.props.setBreadcrumbs([this.customer[model.PROPS_NAME]]);

    toggleEquipmentAddEditPanel = () => {
        this.setState({
            showEquipmentScreen: !this.state.showEquipmentScreen,
        });
        this.setHeader();
    };

    toggleEquipmentSave = () => {
        this.setState({
            saveEquipmentData: !this.state.saveEquipmentData,
        });
    };

    onSaveEquipmentData = (data) => {
        this.toggleEquipmentSave();
        if (this.equipmentAddRequestId === null) {
            this.setState(
                {
                    currentEquipmentData: data,
                },
                () => {
                    this.equipmentAddRequestId = this.props.needs([
                        this.props.createEquipment(data),
                    ]);
                }
            );
        }
    };

    removeSelectedOptionList = (options, selectedItems) => {
        const optionList = options.filter((option) => {
            return (
                !selectedItems.some((selectedItem) => {
                    return (
                        selectedItem[model.PROPS_AGVANCE_CUSTOMER_GUID] ===
                        option[model.PROPS_AGVANCE_CUSTOMER_GUID]
                    );
                }) ||
                (this.selectedCustomer.length
                    ? this.selectedCustomer[0][model.PROPS_AGVANCE_CUSTOMER_GUID] !==
                      option.agvanceGuid
                    : true)
            );
        });
        return optionList || [];
    };

    addSelectedOptionList = (options, selectedItems) => [
        ...this.state.agvanceCustomerList,
        ...options.filter(
            (option) => selectedItems[model.PROPS_ORACLE_ID] === option[model.PROPS_ORACLE_ID]
        ),
    ];

    // Gives grower level address if Primary Ship To Address doesn't exist
    getAgvanceCustomerAddress = (customer) => {
        const { billToShipToAddresses, countryGuid } = customer;
        const primaryShipToAddress = billToShipToAddresses.filter(
            ({ addressType, primaryAddress }) => {
                return (
                    addressType === keywords.shipToType && JSON.parse(primaryAddress.toLowerCase())
                );
            }
        );
        if (primaryShipToAddress.length) {
            return {
                ...primaryShipToAddress[0],
                countryGuid,
            };
        }
        return customer;
    };

    onCustomerRemove = () => {
        const addressList = this._formAddressList({}, false);
        this._onTextChange(model.PROPS_NAME, "", this.setHeader);
        if (this.selectedCustomer.length) {
            this.setState({
                [model.PROPS_CUSTOMER_ADDRESS_LIST]: addressList,
                agvanceCustomerList: this.props.isAgvanceCustomerListOutOfBound
                    ? []
                    : AgvanceUtils.prepareAgvanceCustomerList(
                          this.addSelectedOptionList(
                              this.props.agvanceCustomerList,
                              this.selectedCustomer[0]
                          )
                      ).sort((a, b) =>
                          a[model.PROPS_AGVANCE_GROWER_NAME].localeCompare(
                              b[model.PROPS_AGVANCE_GROWER_NAME]
                          )
                      ),
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]:
                    this._removePrimaryCustomerFromCrossRefList(),
                [model.PROPS_SALESPERSON_CUSTOMER_LIST]: [],
            });
            this._onTextChange(
                model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST,
                this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]
            );
            this._onTextChange(model.PROPS_SALESPERSON_CUSTOMER_LIST, []);
            this.selectedCustomer = [];
        }
    };

    onPrimaryCustomerChange = (newPrimaryCustomer) => {
        const crossRefListData = [...this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]];
        crossRefListData.forEach((item) => {
            if (item.isPrimary) {
                item.isPrimary = false;
                item.canDelete = true;
            } else if (newPrimaryCustomer.agvanceCustomerGuid === item.agvanceCustomerGuid) {
                item.isPrimary = true;
                item.canDelete = false;
            }
        });
        this.setState(
            {
                crossRefListData,
            },
            () => {
                if (
                    this._getSelectedCustomer(newPrimaryCustomer.agvanceCustomerGuid).length === 0
                ) {
                    this.newPrimaryCustomer = newPrimaryCustomer;
                    this.requestIdNewPrimaryCustomer = this.props.needs([
                        this.props.getAgvanceCustomer(newPrimaryCustomer.agvanceCustomerGuid),
                    ]);
                } else {
                    this.onCustomerSelection(newPrimaryCustomer.agvanceCustomerGuid);
                }
            }
        );
    };

    _removePrimaryCustomerFromCrossRefList = () => {
        const crossRefListData = [...this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]];
        const index = crossRefListData.findIndex((item) => item.isPrimary);
        crossRefListData.splice(index, 1);
        return crossRefListData;
    };

    _setCrossRefListData = (modifiedCustomer) => {
        const crossRefListData = this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST];
        const primaryIndex = crossRefListData.findIndex((item) => item.isPrimary);
        crossRefListData.splice(primaryIndex, 1, {
            ...modifiedCustomer,
            canDelete: false,
        });
        return crossRefListData;
    };

    _setNewPrimaryCustomer = (selectedCustomer) => {
        // Post migration only one customerGuid will be linked with the Agvance Customer. Thus,
        // AgvanceCustomer returned for the source customer will remain null. This logic
        // will prevent updating the redundant data in the UI
        if (!selectedCustomer[0].agvanceCustomerGuid && !selectedCustomer[0].customerGuid) {
            const currentPrimaryCustomer = this.state[
                model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST
            ].find((v) => v.agvanceCustomerGuid === this.selectedCustomer[0].agvanceCustomerGuid);
            const selectedOrgLevel = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].find(
                (b) => b.orgLevelGuid === currentPrimaryCustomer.orgLevelGuid
            );
            const newPrimaryCustomer = this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST].find(
                (v) =>
                    v.agvanceCustomerGuid ===
                    (this.selectedCustomer[0].agvanceCustomerGuid ||
                        this.selectedCustomer[0].customerGuid)
            );
            const newPrimaryOrgLevel = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].find(
                (b) => b.orgLevelGuid === newPrimaryCustomer.orgLevelGuid
            );

            if (
                selectedOrgLevel.agvanceDatabaseId.toUpperCase() ===
                newPrimaryOrgLevel.agvanceDatabaseId.toUpperCase()
            ) {
                return;
            } else {
                console.error(
                    `There was an error fetching the Agvance Customer details for the customerGuid ${this.newPrimaryCustomer.customerGuid}`
                );
            }
        }

        const modifiedCustomer = [{ ...selectedCustomer[0], [model.PROPS_IS_PRIMARY]: true }];
        this.selectedCustomer = modifiedCustomer;
        const customer = this.getAgvanceCustomerAddress(selectedCustomer[0]);
        const addressList = this._formAddressList(customer, true);
        const salespersonList = !selectedCustomer[0].primarySalespersonId
            ? []
            : [
                  {
                      salespersonCustomerGuid: null,
                      salespersonGuid: selectedCustomer[0].primarySalespersonGuid,
                      salespersonId: selectedCustomer[0].primarySalespersonId,
                      personName: selectedCustomer[0].primarySalespersonName,
                      isPrimary: true,
                  },
              ];
        this._onTextChange(model.PROPS_NAME, selectedCustomer[0].growerName, this.setHeader);
        this._onTextChange(model.PROPS_SALESPERSON_CUSTOMER_LIST, salespersonList);
        this.setState(
            {
                [model.PROPS_CUSTOMER_ADDRESS_LIST]: addressList,
                [model.PROPS_SALESPERSON_CUSTOMER_LIST]: salespersonList,
                agvanceCustomerList: this.removeSelectedOptionList(
                    AgvanceUtils.prepareAgvanceCustomerList(this.props.agvanceCustomerList),
                    [
                        ...AgvanceUtils.getNonPrimaryCustomerList(
                            this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]
                        ),
                        ...this.selectedCustomer,
                    ]
                ),
                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]: this._setCrossRefListData(
                    modifiedCustomer[0]
                ),
            },
            () => {
                this._onTextChange(model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST, [
                    ...this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST],
                ]);
            }
        );
    };

    _getSelectedCustomer = (guid) => {
        const selectedCustomer = this.state.agvanceCustomerList.filter(({ agvanceGuid }) => {
            return guid === agvanceGuid;
        });
        if (!selectedCustomer.length) {
            return AgvanceUtils.prepareAgvanceCustomerList(
                this.props.agvanceCustomerList.filter(({ agvanceGuid }) => {
                    return guid === agvanceGuid;
                })
            );
        }
        return selectedCustomer;
    };

    onCustomerSelection = (guid) => {
        if (guid) {
            this.selectedCustomer = this._getSelectedCustomer(guid);
            if (this.selectedCustomer.length === 1) {
                this._setNewPrimaryCustomer(this.selectedCustomer);
            }
        } else {
            this.onCustomerRemove();
        }
    };

    processAddEquipmentRequest = (nextProps) => {
        if (nextProps.equipmentList !== this.props.equipmentList) {
            this.setState({
                equipmentListData: nextProps.equipmentList,
            });
        }
        if (nextProps.requestIds[this.equipmentAddRequestId] === SUCCESS) {
            if (this.state.currentEquipmentData) {
                this.setState({ currentEquipmentData: null });
                const { needs } = this.props;
                needs([
                    this.props.fetchCustomerEquipmentList(this.customer[model.PROPS_CUSTOMER_GUID]),
                ]);
            }
            this.equipmentAddRequestId = null;
            this.toggleEquipmentAddEditPanel();
        } else if (nextProps.requestIds[this.equipmentAddRequestId] === FAILURE) {
            this.equipmentAddRequestId = null;
        }
    };

    _processNewPrimaryCustomerRequest(nextProps) {
        if (nextProps.requestIds[this.requestIdNewPrimaryCustomer] === SUCCESS) {
            this._setNewPrimaryCustomer([
                {
                    ...nextProps.newPrimaryCustomer,
                    [model.PROPS_AGVANCE_GROWER_ID]: nextProps.newPrimaryCustomer.growerId,
                    [model.PROPS_AGVANCE_GROWER_NAME]: nextProps.newPrimaryCustomer.growerName,
                },
            ]);
            this.requestIdNewPrimaryCustomer = null;
        } else if (nextProps.requestIds[this.requestIdNewPrimaryCustomer] === FAILURE) {
            this.requestIdNewPrimaryCustomer = null;
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        this.customer = AgvanceUtils.isAgvanceConnected(this.props.personalityId)
            ? service.getDefaultRecord()
            : {
                  ...service.getDefaultRecord(),
                  [model.PROPS_NAME]: this.props.customerName,
              };
        const { needs } = this.props;
        needs([this.props.fetchDropdownData()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.oracleCustomer != null && !this.state.showOracleDialog) {
            this.showOracleDialog(true);
        }
        if (nextProps.showCreateCustomerConfDialog && !this.state.showCreateCustomerConfDialog) {
            this.showCreateCustomerConfDialog(true);
        }
        if (this.state.showDataPoolingChangePrompt) {
            this.showDataPoolingChangePrompt(true);
        }
        if (
            JSON.stringify(nextProps.customerRecord) !== JSON.stringify(this.props.customerRecord)
        ) {
            this.addEditPanel.mode = adminKeywords.EDIT;
            this.customer = nextProps.customerRecord;
            if (nextProps.equipmentList.length) {
                this.customer[model.PROPS_CUSTOMER_EQUIPMENT_LIST] = nextProps.equipmentList;
            }
            if (nextProps.customerRecord[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].length > 0) {
                this._getAgvanceCustomerList(
                    nextProps.customerRecord[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].map(
                        ({ orgLevelGuid }) => orgLevelGuid
                    )
                );
            }
            this.selectedCustomer = AgvanceUtils.getPrimaryCustomer(
                nextProps.customerRecord[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]
            );
            if (this.state[model.PROPS_MYAGDATA_REGISTRATIONS] === null) {
                this.props.fetchMyAgDataRegistration(
                    nextProps.customerRecord[model.PROPS_CUSTOMER_GUID]
                );
            }
            this.setState(
                {
                    [model.PROPS_CUSTOMER_ADDRESS_LIST]:
                        nextProps.customerRecord[model.PROPS_CUSTOMER_ADDRESS_LIST],
                    [model.PROPS_CUSTOMER_ORG_LEVEL_LIST]: AgvanceUtils.isAgvanceConnected(
                        this.props.personalityId
                    )
                        ? nextProps.customerRecord[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].map((c) => {
                              if (this.selectedCustomer[0].orgLevelGuid === c.orgLevelGuid) {
                                  return { ...c, restrictEditDelete: true };
                              }
                              return c;
                          })
                        : nextProps.customerRecord[model.PROPS_CUSTOMER_ORG_LEVEL_LIST],
                    [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]:
                        nextProps.customerRecord[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST],
                    [model.PROPS_DATA_POOLING]: nextProps.customerRecord[model.PROPS_DATA_POOLING]
                        ? TRUE
                        : FALSE,
                    [model.PROPS_DATA_POOLING_ORIGINAL]: nextProps.customerRecord[
                        model.PROPS_DATA_POOLING
                    ]
                        ? TRUE
                        : FALSE,
                    [model.ENROLLED_YN]: nextProps.customerRecord[model.ENROLLED_YN],
                    [model.ENROLLED_YN_ORIGINAL]: nextProps.customerRecord[model.ENROLLED_YN],
                    [model.PROPS_SALESPERSON_CUSTOMER_LIST]:
                        nextProps.customerRecord[model.PROPS_SALESPERSON_CUSTOMER_LIST],
                    [model.PROPS_MYAGDATA_REGISTRATIONS]:
                        this.state[model.PROPS_MYAGDATA_REGISTRATIONS] ??
                        nextProps.customerRecord[model.PROPS_MYAGDATA_REGISTRATIONS],
                },
                () => {
                    this.setHeader();
                    this.props.needs([
                        this.props.fetchCustomerEquipmentList(
                            this.customer[model.PROPS_CUSTOMER_GUID]
                        ),
                    ]);
                    this._updatePhoneNumber(
                        nextProps.customerRecord[model.PROPS_ENROLLMENT_MOBILE_PHONE]
                    );
                }
            );
        } else {
            if (
                (this.addEditPanel.mode === adminKeywords.ADD &&
                    this.state[model.PROPS_DATA_POOLING] == null) ||
                nextProps.communityDataDefault !== this.props.communityDataDefault
            ) {
                this._onTextChange(model.PROPS_DATA_POOLING, nextProps.communityDataDefault);
                this.setState({
                    [model.PROPS_DATA_POOLING]: nextProps.communityDataDefault ? TRUE : FALSE,
                });
            }
        }
        this._initializeDropdowns(nextProps);
        this.processAddEquipmentRequest(nextProps);
        this._processAddEditRequest(nextProps);
        this._processCustomerNameExistsRequest(nextProps);
        this._processNewPrimaryCustomerRequest(nextProps);
        this._processSendAuthenticationRequest(nextProps);
    }
    componentWillUnmount() {
        const { removeCustomerRecord, customerRecord } = this.props;
        if (!_.isEmpty(customerRecord)) {
            removeCustomerRecord();
        }
        this.props.clearAgvanceCustomerList();
        this.setState({
            [model.PROPS_DATA_POOLING]: null,
        });
    }

    _renderDetailInfo1 = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.state;
        const { needs, userRole } = this.props;
        const {
            onChildComponentChange,
            mapSelectedCountry,
            addEditPanel,
            _isDisabled,
            _isAddressDisabled,
            _isEditMode,
        } = this;
        const props = {
            onChildComponentChange,
            mapSelectedCountry,
            addEditPanel,
            needs,
            apiErrors,
        };
        const primaryCustomer =
            this.customer[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST] &&
            this.customer[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST].find((c) => c.isPrimary);

        return (
            <div className="section-container section-column">
                <Section>
                    <SubSection className="customer-info-container">
                        {AgvanceUtils.isAgvanceConnected(this.props.personalityId) ? (
                            <SearchAgvanceCustomer
                                allowEmptyOptions
                                disabled={_isDisabled() || _isEditMode()}
                                customerList={this.state.agvanceCustomerList}
                                personalityId={this.props.personalityId}
                                initialValue={this.customer[model.PROPS_NAME]}
                                onSelection={this.onCustomerSelection}
                                placeholderText={formatMessage(messages.customerName)}
                                className="customer-name-section"
                                isAgvanceCustomerListOutOfBound={
                                    this.props.isAgvanceCustomerListOutOfBound
                                }
                                customerSearchIsLoading={
                                    !this.props.isAdditionalAgvanceCustomerSearch
                                        ? this.props.customerSearchIsLoading
                                        : false
                                }
                                valueTitle={
                                    primaryCustomer
                                        ? `${primaryCustomer.agvanceGrowerName} - ${primaryCustomer.alternateCustomerIdSelecting}`
                                        : ""
                                }
                                onInputChange={(value) => {
                                    this._onTextChange(model.PROPS_NAME, value, this.setHeader);
                                    if (!value) {
                                        this.onCustomerRemove();
                                    }
                                    if (this.props.isAgvanceCustomerListOutOfBound) {
                                        const orgLevelGuidList = this.state[
                                            model.PROPS_CUSTOMER_ORG_LEVEL_LIST
                                        ].map((item) => item.orgLevelGuid);
                                        this.props.fetchAgvanceCustomerWithNameSearch({
                                            nameSearch: value,
                                            orgLevelGuidList,
                                        });
                                    }
                                }}
                            />
                        ) : (
                            <TextInput
                                required
                                maxLength={50}
                                tabIndex={0}
                                placeholderText={formatMessage(messages.customerName)}
                                onChange={(value) =>
                                    this._onTextChange(model.PROPS_NAME, value, this.setHeader)
                                }
                                value={this.customer[model.PROPS_NAME]}
                                containerClassNames={[
                                    "customer-name-section",
                                    getAgBytesErrorClassNames(55, apiErrors),
                                ]}
                                autoFocus
                            />
                        )}
                        {this._isEditMode() ? (
                            !userRole[adminData.PROPS_ACTIVE_INACTIVE] ? null : (
                                <Checkbox
                                    label={formatMessage(messages.active)}
                                    onChange={(e, value) =>
                                        this._onTextChange(adminData.PROPS_ACTIVE_YN, value)
                                    }
                                    value={this.customer[model.ACTIVE_YN]}
                                />
                            )
                        ) : null}
                    </SubSection>
                    <SubSection>
                        <Address
                            addressList={this.state[model.PROPS_CUSTOMER_ADDRESS_LIST]}
                            addressTypeList={this.state[model.PROPS_ADDRESS_TYPE_LIST]}
                            countriesList={this.state[model.PROPS_COUNTRIES_LIST]}
                            stateList={this.state[model.PROPS_STATE_LIST]}
                            addressPropKey={model.PROPS_CUSTOMER_ADDRESS_LIST}
                            disabled={_isAddressDisabled()}
                            forceUpdate
                            {...props}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    _renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        const { enableMyAgData } = this.props;
        const { apiErrors } = this.state;

        return (
            <div className="section-container section-column">
                <Section
                    required
                    className="grid-section"
                    headerText={formatMessage(messages.locationAffiliations)}
                >
                    {this.state[model.PROPS_ORG_LEVEL_LIST] ? (
                        <OrgLevelList
                            classNames={getAgBytesErrorClassNames(2033, apiErrors)}
                            itemList={this.state[model.PROPS_ORG_LEVEL_LIST]}
                            ownerLevel={
                                this.props[model.PROPS_ORG_LEVEL_LIST] &&
                                this.props[model.PROPS_ORG_LEVEL_LIST].find(
                                    ({ orgLevelParents }) => orgLevelParents.length === 0
                                )
                            }
                            record={this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST]}
                            onSelectionChange={(value, removedOrgLevel) =>
                                this._orgLevelChange(value, removedOrgLevel)
                            }
                            statePropName={PROPS_STATE_ABBREVIATION}
                        />
                    ) : null}
                </Section>
                {!this.props.userRole.customerSalesperson ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.salesperson)}
                    >
                        {this.state[model.PROPS_SALESPERSON_CUSTOMER_LIST] ? (
                            <SalespersonCustomerList
                                itemList={this.state.salespersonList}
                                record={this.state[model.PROPS_SALESPERSON_CUSTOMER_LIST]}
                                onSelectionChange={(value) =>
                                    this._salespersonCustomerChange(value)
                                }
                            />
                        ) : null}
                    </Section>
                )}
                {enableMyAgData && this._isEditMode() && (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.myAgDataRegistration)}
                    >
                        <MyAgDataRegistration
                            customerName={this.props.customerRecord.name}
                            registrations={this.state[model.PROPS_MYAGDATA_REGISTRATIONS]}
                            crossRefList={this.state[model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]}
                            updateRegistrations
                            updateList={(value) =>
                                this.setState({ [model.PROPS_MYAGDATA_REGISTRATIONS]: value }, () =>
                                    this.onChildComponentChange(
                                        model.PROPS_MYAGDATA_REGISTRATIONS,
                                        value
                                    )
                                )
                            }
                        />
                    </Section>
                )}
            </div>
        );
    };

    _renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const { userRole, enrollmentRequired, isAgvanceCustomerListOutOfBound } = this.props;
        const disabled = this._isDisabled();
        const sendAuthDisabled = this.state[model.ENROLLED_YN];
        const showDataPoolingChangePrompt = this.state.showDataPoolingChangePrompt;
        const enrollmentOverrideDisabled = userRole.communityData !== true;
        const enrollmentCheckboxDisabled =
            !this.customer[model.PROPS_ENROLLED_FORM_URL] && !this.state[model.ENROLLED_YN];
        const enrollmentShowAuto =
            this.state[model.ENROLLED_YN] && this.customer[model.PROPS_ENROLLED_AUTO_YN];
        const enrolledTitle =
            this.customer[model.PROPS_ENROLLED_MODIFIED_BY_NAME] +
            " " +
            this.customer[model.PROPS_ENROLLED_MODIFIED_DATE];
        const updatedAgvanceCustomerList = isAgvanceCustomerListOutOfBound
            ? this.state.additionalAgvanceCustomerList
            : this.state.agvanceCustomerList;
        const updatedAgvanceCrossRefList = this.state[
            model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST
        ].map((v) => {
            const tmpOrgLevel = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].find(
                (b) => b.orgLevelGuid === v.orgLevelGuid
            );
            if (tmpOrgLevel) {
                return {
                    ...v,
                    agvanceDatabaseId: tmpOrgLevel.agvanceDatabaseId,
                };
            }
            return v;
        });
        const updatedPrimaryCustomer = this.state[
            model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST
        ].reduce((acc, v) => {
            const tmpOrgLevel = this.state[model.PROPS_CUSTOMER_ORG_LEVEL_LIST].find(
                (b) => b.orgLevelGuid === v.orgLevelGuid
            );
            if (tmpOrgLevel) {
                return {
                    ...this.selectedCustomer[0],
                    agvanceDatabaseId: tmpOrgLevel.agvanceDatabaseId,
                };
            }
            return acc;
        }, {});
        return (
            <div className="section-container section-column">
                {!userRole.communityData ? null : (
                    <Section
                        required
                        className="grid-section"
                        headerText={formatMessage(messages.communityData)}
                    >
                        <RadioButtonGroup
                            selectedValue={this.state[model.PROPS_DATA_POOLING]}
                            afterOnChange={(value) => {
                                const valueBool = value === TRUE;
                                this._onTextChange(model.PROPS_DATA_POOLING, valueBool);
                                this.setState({
                                    [model.PROPS_DATA_POOLING]: value,
                                });
                                this.showDataPoolingChangePrompt(true);
                            }}
                            className={"community-data-section"}
                        >
                            <RadioButton
                                tabIndex={0}
                                value={TRUE}
                                label={formatMessage(messages.yes)}
                            />
                            <RadioButton
                                tabIndex={0}
                                value={FALSE}
                                label={formatMessage(messages.no)}
                            />
                        </RadioButtonGroup>
                        {showDataPoolingChangePrompt ? this._renderDataPoolingChangePrompt() : null}
                    </Section>
                )}
                {this.addEditPanel.mode === adminKeywords.ADD ? null : (
                    <Section
                        className="grid-section equipment-grid"
                        headerText={formatMessage(messages.equipment)}
                    >
                        <SubSection className={"form-section-child-stretch mini-grid"}>
                            {!(this.state.equipmentListData.length > 0) ? null : (
                                <ZeroToInfiniteGrid
                                    records={this.state.equipmentListData}
                                    columns={{
                                        equipmentName: {
                                            title: formatMessage(messages.name),
                                            width: 150,
                                        },
                                        equipmentParentType: {
                                            title: formatMessage(messages.parentType),
                                            width: 100,
                                        },
                                        equipmentType: {
                                            title: formatMessage(messages.typeName),
                                            width: 150,
                                        },
                                        manufacturer: {
                                            title: formatMessage(messages.manufacturer),
                                            width: 150,
                                        },
                                    }}
                                />
                            )}
                            <div className="add-link-container">
                                <NoLink
                                    className="add-link"
                                    label={createAddLinkLabelText(
                                        formatMessage,
                                        messages.equipment
                                    )}
                                    onClick={this.toggleEquipmentAddEditPanel}
                                />
                            </div>
                        </SubSection>
                    </Section>
                )}
                <Section headerText={formatMessage(messages.notes)}>
                    <SubSection>
                        <TextArea
                            maxLength={4000}
                            placeholderText={formatMessage(messages.notes)}
                            labelText={formatMessage(messages.notes)}
                            containerClassNames={["notes-section"]}
                            disabled={disabled}
                            value={this.customer[model.PROPS_NOTES]}
                            onChange={(value) => this._onTextChange(model.PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
                {!(
                    AgvanceUtils.isAgvanceConnected(this.props.personalityId) &&
                    userRole.customerAliasName
                ) ? null : (
                    <Section
                        headerText={formatMessage(messages.customerAliasName)}
                        className="customer-alias-name-container"
                    >
                        <SubSection>
                            <TextInput
                                tabIndex={0}
                                maxLength={50}
                                placeholderText={formatMessage(messages.customerAliasName)}
                                onChange={(value) =>
                                    this._onTextChange(model.PROPS_CUSTOMER_ALIAS_NAME, value)
                                }
                                value={this.customer[model.PROPS_CUSTOMER_ALIAS_NAME]}
                                containerClassNames={["customer-alias-name"]}
                                disabled={disabled}
                            />
                        </SubSection>
                    </Section>
                )}
                {!AgvanceUtils.isAgvanceConnected(this.props.personalityId) ? null : (
                    <Section headerText={formatMessage(messages.additionalAgvanceCustomer)}>
                        <SubSection>
                            <AdditionalAgvanceCustomer
                                allowEmptyOptions
                                className="customer-name-section"
                                options={updatedAgvanceCustomerList}
                                record={updatedAgvanceCrossRefList}
                                myAgDataRegistrations={
                                    this.state[model.PROPS_MYAGDATA_REGISTRATIONS]
                                }
                                primaryCustomer={updatedPrimaryCustomer}
                                onSelectionChange={(value, removedCustomer = null) => {
                                    const { removedCustomers } = this.state;
                                    if (removedCustomer) {
                                        removedCustomers.push(removedCustomer);
                                    }
                                    this._onTextChange(
                                        model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST,
                                        value,
                                        () =>
                                            this.setState({
                                                [model.PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST]:
                                                    value,
                                                agvanceCustomerList: this.removeSelectedOptionList(
                                                    AgvanceUtils.prepareAgvanceCustomerList(
                                                        this.props.agvanceCustomerList
                                                    ),
                                                    value
                                                ),
                                                removedCustomers,
                                            })
                                    );
                                }}
                                disabled={disabled}
                                onPrimaryCustomerChange={this.onPrimaryCustomerChange}
                                mode={this.addEditPanel.mode}
                                isAgvanceCustomerListOutOfBound={
                                    this.props.isAgvanceCustomerListOutOfBound
                                }
                                customerSearchIsLoading={
                                    this.props.isAdditionalAgvanceCustomerSearch
                                        ? this.props.customerSearchIsLoading
                                        : false
                                }
                                onInputChange={(value) => {
                                    if (this.props.isAgvanceCustomerListOutOfBound) {
                                        const orgLevelGuidList = this.state[
                                            model.PROPS_CUSTOMER_ORG_LEVEL_LIST
                                        ].map((item) => item.orgLevelGuid);
                                        this.props.fetchAgvanceCustomerWithNameSearch({
                                            nameSearch: value,
                                            orgLevelGuidList,
                                            isAdditionalAgvanceCustomer: true,
                                        });
                                    }
                                }}
                            />
                        </SubSection>
                    </Section>
                )}
                {this.addEditPanel.mode === adminKeywords.ADD || !enrollmentRequired ? null : (
                    <Section
                        headerText={formatMessage(messages.enrollmentAgreement)}
                        className="enrollment-container"
                    >
                        <SubSection>
                            {
                                <TextInput
                                    tabIndex={0}
                                    maxLength={14}
                                    placeholderText={formatMessage(messages.mobilePhone)}
                                    onChange={(value) => this._updatePhoneNumber(value)}
                                    value={this.state[model.PROPS_ENROLLMENT_MOBILE_PHONE]}
                                    containerClassNames={["enrollment-input"]}
                                    disabled={sendAuthDisabled}
                                />
                            }
                            {enrollmentOverrideDisabled ? null : (
                                <Checkbox
                                    className={"enrollment-checkbox"}
                                    disabled={enrollmentCheckboxDisabled}
                                    label={formatMessage(messages.enrolled)}
                                    title={enrolledTitle}
                                    onChange={(e, value) => {
                                        this._onTextChange(adminData.PROPS_ENROLLED_YN, value);
                                        this.setState({
                                            [model.ENROLLED_YN]: value,
                                        });
                                    }}
                                    value={this.state[model.ENROLLED_YN]}
                                />
                            )}
                            {enrollmentShowAuto ? (
                                <div className={"enrollment-auto"}>{"\u2714"}</div>
                            ) : null}
                        </SubSection>
                        {
                            <SubSection>
                                <TextInput
                                    tabIndex={0}
                                    maxLength={50}
                                    placeholderText={formatMessage(messages.email)}
                                    onChange={(value) =>
                                        this._onTextChange(model.PROPS_ENROLLMENT_EMAIL, value)
                                    }
                                    value={this.customer[model.PROPS_ENROLLMENT_EMAIL]}
                                    containerClassNames={["enrollment-input"]}
                                    disabled={sendAuthDisabled}
                                />
                                {this.customer[model.PROPS_ENROLLED_FORM_URL] ? (
                                    <div className="enrollment-form-link">
                                        <NoLink
                                            label={formatMessage(messages.enrollmentFormLink)}
                                            onClick={this._onViewEnrollmentForm}
                                        />
                                        <div
                                            className="context-menu-container"
                                            onClick={(evt) => evt.preventDefault()}
                                        >
                                            <Menu
                                                className="context-menu"
                                                isDotMenu={true}
                                                getMenuItems={() => this._getMenuItems(this.props)}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="enrollment-form-add">
                                        <FileSelector
                                            fileType={".pdf, .jpg, .png"}
                                            allowMultiple={false}
                                            label={
                                                formatMessage(globalMessages.addSymbol) +
                                                formatMessage(messages.enrollmentAttachForm)
                                            }
                                            fileCompletedCallback={this._updateEnrollmentForm}
                                            id={this.props.id}
                                            className="add-link"
                                        />
                                    </div>
                                )}
                                <DialogBox
                                    title={formatMessage(messages.enrollmentFormRemoveConfirm)}
                                    footerType={DialogBoxFooterType.YES_NO}
                                    isOpen={this.state.showRemoveEnrollmentFormModal}
                                    onAction={() => this._updateEnrollmentForm({})}
                                    onClose={() =>
                                        this.setState({
                                            showRemoveEnrollmentFormModal: false,
                                        })
                                    }
                                >
                                    <div>{formatMessage(messages.confirmRemoveMsg)}</div>
                                </DialogBox>
                            </SubSection>
                        }
                        {
                            <SubSection>
                                <TextInput
                                    tabIndex={0}
                                    maxLength={50}
                                    placeholderText={formatMessage(messages.signerFullName)}
                                    onChange={(value) =>
                                        this._onTextChange(
                                            model.PROPS_ENROLLMENT_SIGNER_NAME,
                                            value
                                        )
                                    }
                                    value={this.customer[model.PROPS_ENROLLMENT_SIGNER_NAME]}
                                    containerClassNames={["enrollment-input"]}
                                    disabled={sendAuthDisabled}
                                />
                                <Button
                                    className={"enrollment-button"}
                                    type="sendAuthentication"
                                    onClick={() => this._onSendAuthentication()}
                                    disabled={sendAuthDisabled}
                                />
                            </SubSection>
                        }
                    </Section>
                )}
                {this.state.jdOrganizationIds?.length === 0 ||
                !this.props.userRole.portalIntegration ||
                !this._isEditMode() ? null : (
                    <PortalIntegration
                        records={this.state.jdOrganizationIds}
                        value={this.customer[model.PROPS_PARTNER_ORG_ID]}
                        isEditMode={true}
                        onOrganizationChange={this._onOrganizationChange}
                    />
                )}
            </div>
        );
    };

    _renderEquipmentAddEdit = () => {
        const primaryAddress = this.customer[model.PROPS_CUSTOMER_ADDRESS_LIST].filter(
            ({ isPrimary }) => isPrimary === true
        );
        const ownerOperatorData = {
            ownerOperatorCustomerGuid: this.customer[model.PROPS_CUSTOMER_GUID],
            ownerOperatorName: this.customer[model.PROPS_NAME],
            ownerOperatorCity: primaryAddress[0].theAddress.city,
            ownerOperatorStateAbbreviation: primaryAddress[0].theAddress.stateAbbreviation,
        };
        return (
            <div className="equipment-add-edit-page">
                <EquipmentAddEdit
                    addEditPanel={{
                        mode: ADD,
                    }}
                    needs={this.props.needs}
                    ownerOperatorData={ownerOperatorData}
                    fetchData={this.state.saveEquipmentData}
                    liftRecordData={this.onSaveEquipmentData}
                />
            </div>
        );
    };

    showOracleDialog = (showOracleDialog) => this.setState({ showOracleDialog });

    showCreateCustomerConfDialog = (showCreateCustomerConfDialog) =>
        this.setState({ showCreateCustomerConfDialog });

    _renderCustomerConfDialog = () => {
        const { formatMessage } = this.props.intl;
        const { showCreateCustomerConfDialog } = this.state;
        return !showCreateCustomerConfDialog ? null : (
            <DialogBox
                forceOverflow
                title={formatMessage(messages.createCustomerConfTitle)}
                footerType={DialogBoxFooterType.YES_NO}
                onClose={() => {
                    this.showCreateCustomerConfDialog(false);
                }}
                onAction={() => {
                    this.showCreateCustomerConfDialog(false);
                    this._onSave();
                }}
                isOpen={showCreateCustomerConfDialog}
            >
                <p>{formatMessage(messages.createCustomerConfDialogLine1)}</p>
                <br />
                <p>{formatMessage(messages.createCustomerConfDialogLine2)}</p>
            </DialogBox>
        );
    };

    _renderOracleDialog = () => {
        const { formatMessage } = this.props.intl;
        const { oracleCustomer, clearOracleCustomer } = this.props;
        const { showOracleDialog } = this.state;
        return !oracleCustomer ? null : (
            <DialogBox
                forceOverflow
                title={formatMessage(messages.oracleCustomerDialogTitle)}
                footerType={DialogBoxFooterType.YES_NO}
                onClose={() => {
                    clearOracleCustomer();
                    this.showOracleDialog(false);
                }}
                onAction={() => {
                    this.customer = oracleCustomer.customer;
                    clearOracleCustomer();
                    this.showOracleDialog(false);
                    this.addEditPanel.mode = adminKeywords.EDIT;
                    this._onSave();
                }}
                isOpen={showOracleDialog}
            >
                <p>
                    {formatMessage(messages.oracleCustomerDialogTextLine1, {
                        existingLocation: oracleCustomer.existingLocationNameList.join(", "),
                    })}
                </p>
                <br />
                <br />
                <p>
                    {formatMessage(messages.oracleCustomerDialogTextLine2, {
                        newLocation: oracleCustomer.newLocationName,
                    })}
                </p>
                <br />
                <br />
                <p>{formatMessage(messages.oracleCustomerDialogTextLine3)}</p>
            </DialogBox>
        );
    };

    showDataPoolingChangePrompt = (showDataPoolingChangePrompt) =>
        this.setState({ showDataPoolingChangePrompt });

    _renderDataPoolingChangePrompt() {
        const { formatMessage } = this.props.intl;
        const dataPooling = this.state[model.PROPS_DATA_POOLING];
        const revertDataPoolingString = dataPooling === "true" ? FALSE : TRUE;
        const messageLine1 =
            dataPooling === "true"
                ? messages.dataPoolingChangeToYesPromptLine1
                : messages.dataPoolingChangeToNoPromptLine1;
        return (
            <DialogBox
                forceOverflow
                title={formatMessage(messages.dataPoolingChangePromptTitle)}
                footerType={DialogBoxFooterType.YES_NO}
                onClose={() => {
                    this._onTextChange(model.PROPS_DATA_POOLING, revertDataPoolingString);
                    this.setState({
                        [model.PROPS_DATA_POOLING]: revertDataPoolingString,
                    });
                    this.showDataPoolingChangePrompt(false);
                }}
                onAction={() => {
                    this.showDataPoolingChangePrompt(false);
                }}
                isOpen={this.state.showDataPoolingChangePrompt}
            >
                <p>{formatMessage(messageLine1)}</p>
                <br />
                <br />
                <p>{formatMessage(messages.dataPoolingChangePromptLine2)}</p>
            </DialogBox>
        );
    }

    _renderCustomer = () => {
        return (
            <div className="add-edit-panel">
                <div className="section-container">
                    {this._renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo3()}
                </div>
                {this._renderOracleDialog()}
                {this._renderCustomerConfDialog()}
                {!this.state.showEquipmentScreen ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this._renderEquipmentAddEdit}
                            navigateTo={{
                                parentNameCode: 101,
                                childNameCode: 140,
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this.toggleEquipmentSave} />
                            <Button type="cancel" onClick={this.toggleEquipmentAddEditPanel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    };

    render() {
        // Changing Add/Edit role
        let navigateTo = { parentNameCode: "100", childNameCode: "212" };
        if (this.addEditPanel.mode === adminKeywords.EDIT) {
            navigateTo.childNameCode = "213";
        }
        return (
            <div className="customer-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        component={this._renderCustomer}
                        navigateTo={navigateTo}
                        overRideChildNameCode={model.PROPS_BREADCRUMB_PARENT_NAME}
                    >
                        <Button type="save" forceSubmit onClick={this._onProcessSave} />
                        <Button type="cancel" onClick={this._closeSlidingPanel} />
                    </SlidingPanel>
                </form>
            </div>
        );
    }
}

export default withUser(injectIntl(Customer));
