import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { InjectedIntl, injectIntl } from "react-intl";

import { FormattingHelpers, LayerUtilsAPI, UserAPI } from "@ai360/core";
import { ILayer, ISubLayer } from "@ai360/core/dist/4x/es/api/layer";

import { Checkbox, Loader, SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";

import { getTheUserGuid } from "~/login";
import { getUser } from "~/login/selectors";

import {
    actions as recsEventsActions,
    eventsSelectors,
    models as recsEventsModel,
} from "~/recs-events";
import { IAgEventModelMethods } from "~/recs-events/events/model";

import { messages } from "~/action-panel/components/event-module/components/event-info/i18n-messages";
import { messages as commonMessages } from "~/action-panel/components/common/rec-event-info/i18n-messages";
import { messages as layerMessages } from "~/action-panel/components/layer-module/components/i18n-messages";
import * as actions from "~/action-panel/components/event-module/components/event-info/actions";
import * as layerSelectors from "~/action-panel/components/layer-module/components/layer-list/selectors";
import * as layerActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import * as eventInfoSelectors from "~/action-panel/components/event-module/components/event-info/selectors";

import { ContextMenu } from "./sampling-zone-context-menu";
import EditLayerProperties from "./edit-layer-properties";

import "~/action-panel/components/common/rec-event-info/rec-event-zone-info.css";
import "./sampling-zone-info.css";
import { CloseIcon } from "~/core/icons";
import { logFirebaseEvent } from "~/utils/firebase";

interface ISamplingZoneInfoProps {
    EditForm: any;
    eventDetails: any;
    fieldGuid: string;
    layerNameToSurfaceInfoMap: Map<string, any>;
    intl: InjectedIntl;
    isLoading: boolean;
    userInfo: UserAPI.IUser;
    agEventModel: IAgEventModelMethods;
    layerInfos: ILayer[];
    loadingSurfacesSet: Set<any>;
    userGuid: string;
    showSurface: (props: any) => void;
    removeSurface: () => void;
    setDataLoading: (isDataLoading: boolean) => void;
    onChangeSurfaceLayerProps: (props: any) => void;
    onChangeManualLayerProps: (props: any) => void;
    onFetchLayerNameToSurfaceInfoMap: (fieldGuid: string) => void;
    onResetZones: () => void;
    onSetEventAreaIsIncluded: (eventAreaId: number, isIncluded: boolean) => void;
    onSetEventZonesFromLayer: (surfaceInfoOption: any) => void;
    onSetZoneInterpolation: (setZoneInterpolation: boolean) => void;
}

const SamplingZoneInfo_ = (props: ISamplingZoneInfoProps): JSX.Element => {
    const {
        EditForm,
        eventDetails,
        fieldGuid,
        layerNameToSurfaceInfoMap,
        intl,
        isLoading,
        userInfo,
        layerInfos,
        loadingSurfacesSet,
        setDataLoading,
        onChangeSurfaceLayerProps,
        onChangeManualLayerProps,
        onFetchLayerNameToSurfaceInfoMap,
        onResetZones,
        onSetEventAreaIsIncluded,
        onSetEventZonesFromLayer,
        onSetZoneInterpolation,
    } = props;

    const [isEditData, setIsEditData] = useState<boolean>(null);
    const [hideContext, setHideContext] = useState<boolean>(null);
    const [updateLegend, setUpdateLegend] = useState<boolean>(null);
    const [selectedLayer, setSelectedLayer] = useState<ILayer>(null);
    const [selectedSurface, setSelectedSurface] = useState<ISubLayer>(null);

    useEffect(() => {
        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );
        if (fieldGuid !== recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            onFetchLayerNameToSurfaceInfoMap(fieldGuid);
        }
        if (haveClassBreaks) {
            eventDetails.eventAreaList.forEach((eventArea) =>
                onSetEventAreaIsIncluded(eventArea.eventAreaId, eventArea.applyEventToArea)
            );
            const type = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);

            setHideContext(!selectedLayer || type === LayerUtilsAPI.LayerType.ANALYSIS);
        }
    }, []);

    useEffect(() => {
        if (updateLegend && selectedLayer) {
            const nextLayer = _getLayerFromInfos(layerInfos);
            const nextSurface = _getSelectedSublayer(nextLayer) as ISubLayer;
            setDataLoading(true);
            _onSelectDisplayLayer(nextSurface);

            setSelectedLayer(nextLayer);
            setSelectedSurface(nextSurface);
            setUpdateLegend(false);
        }
    }, [layerInfos]);

    useEffect(() => {
        if (fieldGuid !== recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            onFetchLayerNameToSurfaceInfoMap(fieldGuid);
        }
    }, [fieldGuid]);

    if (isLoading) {
        return null;
    }
    console.assert(eventDetails.eventAreaList.length > 0);
    const area = eventDetails.eventAreaList[0];
    console.assert(area.agEventList.length > 0);
    const { agEventModel } = area.agEventList[0];

    return (
        <div className="rec-event-info-form sampling-tissue-zone-info">
            {_getZoneClassLegend()}
            <EditForm agEventModel={agEventModel} fieldGuid={fieldGuid} />
        </div>
    );

    function _getSelectedSublayer(dataLayer) {
        if (!dataLayer || !dataLayer.subLayers) {
            return null;
        }

        const type = LayerUtilsAPI.getLayerType(dataLayer);
        switch (type) {
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
                return dataLayer.subLayers.find((layer) => {
                    return (
                        layer.importAttributeGuid === dataLayer.selectedAttributeGuid &&
                        layer.surfaceTypeGuid === dataLayer.selectedSurfaceTypeGuid
                    );
                });
            case LayerUtilsAPI.LayerType.ANALYSIS:
                return dataLayer.subLayers.find((layer) => {
                    return layer.analysisLayerGuid === dataLayer.analysisLayerGuid;
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
            case LayerUtilsAPI.LayerType.SOIL:
            case LayerUtilsAPI.LayerType.REC:
                return dataLayer.subLayers.find((layer) => {
                    return (
                        layer.surfaceGuid === dataLayer.selectedSurfaceGuid &&
                        layer.surfaceTypeGuid === dataLayer.selectedSurfaceTypeGuid
                    );
                });
            default:
                return null;
        }
    }

    function _onCloseDataEdit() {
        setIsEditData(false);
    }

    function _getClassBreakData(layer) {
        if (!layer) {
            return null;
        }
        const { classBreaks } = layer;
        const classBreakRows = classBreaks.map((classBreak) => {
            const { classId, color, displayName, acreage } = classBreak;
            return (
                <div key={classId} className="break-row">
                    <div className="symbolBox" style={{ backgroundColor: `#${color.hexCode}` }} />
                    {`${displayName} (${acreage} acres)`}
                </div>
            );
        });
        return classBreakRows;
    }

    function _onSaveDataEdit({
        layer,
        surface,
        attributeGuid,
        classificationMethodGuid,
        colorRampGuid,
        colorSchemeGuid,
        numberOfClasses,
        classBreaks,
        surfaceTypeGuid,
        surfaceGuid,
    }) {
        _onCloseDataEdit();
        setDataLoading(true);
        const newLayer = { ...layer };
        newLayer.selectedSurfaceTypeGuid = surfaceTypeGuid;
        newLayer.selectedAttributeGuid = attributeGuid;
        newLayer.selectedSurfaceGuid = surfaceGuid;
        newLayer.analysisLayerGuid = surface.analysisLayerGuid;

        const type = LayerUtilsAPI.getLayerType(newLayer);
        if (type != LayerUtilsAPI.LayerType.ANALYSIS) {
            if (newLayer.isManual) {
                onChangeManualLayerProps({
                    itemDimIdx: -1,
                    fieldGuid: fieldGuid,
                    layer: newLayer,
                    surface,
                    attributeGuid,
                    colorSchemeGuid,
                });
            } else {
                onChangeSurfaceLayerProps({
                    itemDimIdx: -1,
                    fieldGuid,
                    layer: newLayer,
                    surface: surface,
                    attributeGuid,
                    classificationMethodGuid,
                    colorRampGuid,
                    colorSchemeGuid,
                    numberOfClasses,
                    surfaceTypeGuid,
                    classBreaks,
                });
            }
            setSelectedLayer(newLayer);
            setUpdateLegend(true);
        } else {
            _onSelectDisplayLayer(surface);
            setSelectedLayer(newLayer);
            setSelectedSurface(surface);
        }
    }

    function _getDisplayLayerSelectInput(): JSX.Element {
        const { formatMessage } = intl;
        const { layerInfos, fieldGuid, isLoading, loadingSurfacesSet, layerNameToSurfaceInfoMap } =
            props;
        if (fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            return null;
        }
        const options =
            layerNameToSurfaceInfoMap == null
                ? []
                : [...layerNameToSurfaceInfoMap.entries()].map(([layerName, surfaceInfo]) => ({
                      label: layerName,
                      value: surfaceInfo,
                  }));

        const dataLoading =
            (selectedSurface && loadingSurfacesSet.has(selectedSurface.surfaceGuid)) || isLoading;
        const type = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);
        const letterIcon = formatMessage(layerMessages.layerTypeEvent);

        return (
            <div className="sample-zone-info-layer-controls">
                <SelectInput
                    containerClassNames={["display-layer-select-input"]}
                    disabled={layerNameToSurfaceInfoMap == null}
                    optionIsHiddenKey={ACTIVE_YN}
                    placeholderText={formatMessage(messages.displayLayerPlaceholderTxt)}
                    options={options}
                    onChange={(selection) => {
                        selection && _onSelectDisplayLayer(selection as ISubLayer);
                    }}
                />
                <ContextMenu
                    className={"sample-zone-context-menu"}
                    fieldGuid={fieldGuid}
                    showSampleZoneInfo={() => {
                        setIsEditData(true);
                    }}
                />
                {isEditData && (
                    <EditLayerProperties
                        isOpen={isEditData}
                        selectedLayer={selectedLayer}
                        selectedSurface={selectedSurface}
                        showLayerSelect={true}
                        layerInfos={layerInfos}
                        letterIcon={letterIcon}
                        onSave={(evt) => _onSaveDataEdit(evt)}
                        onClose={() => _onCloseDataEdit()}
                        type={type}
                    />
                )}
                {dataLoading ? <Loader /> : null}
            </div>
        );
    }

    function _getInterpolationType(): number {
        const { role } = userInfo;
        if (role.zoneInterpolation) {
            return recsEventsModel.InterpolationType.ZONE_INTERPOLATION;
        } else if (role.zoneSampling) {
            return recsEventsModel.InterpolationType.ZONE_SAMPLING;
        } else {
            return recsEventsModel.InterpolationType.STANDARD;
        }
    }

    function _getInterpolationLabel(interpolationType): string {
        const { formatMessage } = intl;
        switch (interpolationType) {
            case recsEventsModel.InterpolationType.ZONE_INTERPOLATION:
                return formatMessage(messages.zoneInterpolationCbLabel);
            case recsEventsModel.InterpolationType.ZONE_SAMPLING:
                return formatMessage(messages.zoneSamplingCbLabel);
            default:
                return null;
        }
    }

    function _getLayerFromInfos(layerInfos: ILayer[]): ILayer {
        const layerType = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);

        switch (layerType) {
            case LayerUtilsAPI.LayerType.SOIL:
                return layerInfos.find((layer) => {
                    return layer.soilsFieldGuid === selectedLayer.soilsFieldGuid;
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            case LayerUtilsAPI.LayerType.ANALYSIS:
                return layerInfos.find((layer) => {
                    return layer.analysisLayerGuid === selectedLayer.analysisLayerGuid;
                });
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
                return layerInfos.find((layer) => {
                    return layer.agEventGeneralGuid === selectedLayer.agEventGeneralGuid;
                });
            case LayerUtilsAPI.LayerType.REC:
                return layerInfos.find((layer) => {
                    return layer.recGeneralGuid === selectedLayer.recGeneralGuid;
                });
            default:
                return null;
        }
    }

    function _getZoneClassLegend(): JSX.Element {
        const { formatMessage } = intl;
        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );

        if (!haveClassBreaks) {
            return _getDisplayLayerSelectInput();
        }

        const cbLabelToTotalAcresMap = new Map();
        for (const area of eventDetails.eventAreaList) {
            const areaClassBreak = area.eventAreaClassBreak;
            const { calculatedArea } = area;
            const key = areaClassBreak.label;
            const accum = cbLabelToTotalAcresMap.has(key) ? cbLabelToTotalAcresMap.get(key) : 0;
            cbLabelToTotalAcresMap.set(key, accum + calculatedArea);
        }

        const cbLabelToColorMap = eventDetails.eventAreaList.reduce((cbLabelToColorMap, area) => {
            const classBreak = area.eventAreaClassBreak;
            if (!cbLabelToColorMap.has(classBreak.label)) {
                cbLabelToColorMap.set(classBreak.label, classBreak.rgbCssStr);
            }
            return cbLabelToColorMap;
        }, new Map());

        const legendElements = [...cbLabelToColorMap.keys()].map((label) => {
            const rgbCssStr = cbLabelToColorMap.get(label);
            const legendColorStyle = { backgroundColor: rgbCssStr };
            const zoneSize = formatMessage(commonMessages.zoneSize, {
                calculatedArea: FormattingHelpers.formatNumber(cbLabelToTotalAcresMap.get(label)),
            });

            return (
                <div key={label} className="event-zone-classbreaks-legend">
                    <div className="event-zone-legend-color" style={legendColorStyle} />
                    <div className="event-zone-legend-label">
                        <span className="label-text" title={label}>
                            {label}
                        </span>
                        <span className="label-size"> ({zoneSize})</span>
                    </div>
                </div>
            );
        });
        console.assert(
            new Set(eventDetails.eventAreaList.map((area) => area.eventAreaClassBreak.heading))
                .size === 1
        );
        const legendHeading = eventDetails.eventAreaList[0].eventAreaClassBreak.heading;
        const { interpolationTypeId } = eventDetails.eventAreaList[0].agEventList[0].agEventModel;
        const interpolationType = _getInterpolationType();
        const interpolate =
            interpolationTypeId !== recsEventsModel.InterpolationType.STANDARD &&
            interpolationTypeId === interpolationType;

        const type = selectedLayer && LayerUtilsAPI.getLayerType(selectedLayer);
        const dataLoading =
            (selectedSurface && loadingSurfacesSet.has(selectedSurface.surfaceGuid)) || isLoading;
        const letterIcon = formatMessage(layerMessages.layerTypeEvent);

        return (
            <div className="zone-legend-container">
                <div className="sample-zone-info-layer-controls">
                    <div className="zone-legend-heading-container sample-zone-info-layer-controls">
                        <div className="zone-legend-heading">{legendHeading}</div>
                        {hideContext ? (
                            <span onClick={_onClose}>
                                <CloseIcon />
                            </span>
                        ) : (
                            <ContextMenu
                                className={"sample-zone-context-menu"}
                                fieldGuid={fieldGuid}
                                showSampleZoneInfo={() => {
                                    setIsEditData(true);
                                }}
                                hideSampleZoneInfo={_onClose}
                            />
                        )}
                    </div>
                    {isEditData && (
                        <EditLayerProperties
                            isOpen={isEditData}
                            selectedLayer={selectedLayer}
                            selectedSurface={selectedSurface}
                            showLayerSelect={false}
                            layerInfos={layerInfos}
                            letterIcon={letterIcon}
                            onSave={(evt) => _onSaveDataEdit(evt)}
                            onClose={() => _onCloseDataEdit()}
                            type={type}
                        />
                    )}
                </div>
                {legendElements}
                {interpolationType === recsEventsModel.InterpolationType.STANDARD ? null : (
                    <Checkbox
                        className="interpolation-cb"
                        label={_getInterpolationLabel(interpolationType)}
                        value={interpolate}
                        onChange={(evt, cbValue) => _onSetZoneInterpolation(cbValue)}
                    />
                )}
                {dataLoading ? <Loader /> : null}
            </div>
        );
    }

    function _onClose(): void {
        _onSetZoneInterpolation(false);
        onResetZones();
        setSelectedLayer(null);
        setSelectedSurface(null);
    }

    function _onSelectDisplayLayer(surfaceInfo: ISubLayer): void {
        _onSetZoneInterpolation(true);
        onSetEventZonesFromLayer(surfaceInfo);

        logFirebaseEvent("sampling_display_layer");
        const dataLayer =
            layerInfos &&
            layerInfos.find((lyr) => {
                if (
                    surfaceInfo.agEventGeneralGuid &&
                    surfaceInfo.agEventGeneralGuid === lyr.agEventGeneralGuid
                ) {
                    return true;
                }
                if (
                    surfaceInfo.surfaceGuid &&
                    surfaceInfo.selectedSurfaceTypeGuid &&
                    lyr.selectedSurfaceTypeGuid === surfaceInfo.surfaceTypeGuid &&
                    lyr.selectedSurfaceGuid === surfaceInfo.surfaceGuid
                ) {
                    return true;
                }
                if (
                    surfaceInfo.analysisLayerGuid &&
                    lyr.analysisLayerGuid === surfaceInfo.analysisLayerGuid
                ) {
                    return true;
                }
                if (
                    !surfaceInfo.agEventGeneralGuid &&
                    surfaceInfo.importAttributeGuid &&
                    surfaceInfo.surfaceTypeGuid &&
                    lyr.selectedAttributeGuid === surfaceInfo.importAttributeGuid &&
                    lyr.selectedSurfaceTypeGuid === surfaceInfo.surfaceTypeGuid
                ) {
                    return true;
                }
                return false;
            });
        const layerType = dataLayer && LayerUtilsAPI.getLayerType(dataLayer);

        setSelectedLayer(dataLayer);
        setSelectedSurface(surfaceInfo);
        setHideContext(!layerType || layerType === LayerUtilsAPI.LayerType.ANALYSIS);
    }

    function _onSetZoneInterpolation(cbValue): void {
        const interpolationTypeId = cbValue
            ? _getInterpolationType()
            : recsEventsModel.InterpolationType.STANDARD;
        const eventAreaList = eventDetails.eventAreaList.map((eventArea) => {
            return recsEventsModel.AgEventArea.updateAgEventArea(eventArea, {
                agEventList: eventArea.agEventList.map((agEvent) => {
                    return recsEventsModel.AgEvent.updateAgEvent(agEvent, {
                        agEventModel: agEvent.agEventModel.updateAgEventModel({
                            interpolationTypeId: interpolationTypeId,
                        }),
                    });
                }),
            });
        });
        onSetZoneInterpolation(eventAreaList);
    }
};

const mapDispatchToProps = (dispatch) => ({
    onFetchLayerNameToSurfaceInfoMap: (fieldGuid: string) =>
        dispatch(recsEventsActions.fetchLayerNameToSurfaceInfoMap(fieldGuid)),
    onUpdateEventDetails: (fieldGuid: string, newProps: any) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
    setEventZonesFromLayer: (fieldGuid: string, surfaceInfo: any) =>
        dispatch(recsEventsActions.setEventZonesFromLayer(fieldGuid, surfaceInfo)),
    resetEventAreas: (fieldGuid: string) => dispatch(recsEventsActions.resetEventAreas(fieldGuid)),
    setEventAreaIsIncluded: (fieldGuid: string, areaId: number, isIncluded: boolean) =>
        dispatch(recsEventsActions.setAreaIsIncluded(fieldGuid, areaId, isIncluded)),
    onChangeSurfaceLayerProps: (payload) => dispatch(layerActions.changeSurfaceLayerProps(payload)),
    onChangeManualLayerProps: (payload) => dispatch(layerActions.changeManualLayerProps(payload)),
    setDataLoading: (isLoading) => dispatch(actions.setECDataLoading(isLoading)),
});

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);
    const { eventSummary, isLoading } = eventInfoSelectors.getModuleState(state);
    const { fieldGuid } = eventSummary;
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const { layerNameToSurfaceInfoMap } = eventsSelectors.getModuleState(state);
    const layerInfos = layerSelectors.getLayerInfos(state).get(fieldGuid);

    return {
        eventDetails,
        fieldGuid,
        layerNameToSurfaceInfoMap,
        isLoading,
        layerInfos,
        userGuid: getTheUserGuid(state),
        userInfo: getUser(state),
        loadingSurfacesSet: layerSelectors.getLoadingSurfacesSet(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const onSetEventZonesFromLayer = (surfaceInfo) =>
        dispatchProps.setEventZonesFromLayer(stateProps.fieldGuid, surfaceInfo);

    const onResetZones = () => dispatchProps.resetEventAreas(stateProps.fieldGuid);

    const onSetZoneInterpolation = (eventAreaList) =>
        dispatchProps.onUpdateEventDetails(stateProps.fieldGuid, {
            eventAreaList,
        });

    const onSetEventAreaIsIncluded = (areaId: number, isIncluded: boolean) =>
        dispatchProps.setEventAreaIsIncluded(stateProps.fieldGuid, areaId, isIncluded);

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onResetZones,
        onSetEventAreaIsIncluded,
        onSetEventZonesFromLayer,
        onSetZoneInterpolation,
    };
};

export const SamplingZoneInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SamplingZoneInfo_));
