import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl, intlShape } from "react-intl";
import { AutoSearch, Button, SelectInput } from "~/core";
import {
    AppHelpers,
    CustomerAPI,
    FieldAPI,
    LocalStorageHelpers,
    urlBase,
    UserAPI,
} from "@ai360/core";
import { OrgLevelSearch } from "~/admin/agBytes/components/org-level-search";
import * as actions from "../actions";
import { getFilteredCustomers, getLastUsedCustomerGuid, getUsers } from "../selectors";
import { messages } from "../i18n-messages";
import { ICustomerSimpleListItem } from "~/customer-data/models";

const { LoginState } = actions;

interface Company {
    label: string;
    value: string;
}

interface CompanyHistory {
    replace: (urlBase: string) => void;
    urlBase: string;
}

interface ISelectCompanyProps {
    onAction: (history) => void;
    onError: (error) => void;
    onLogin: (
        theUser: UserAPI.IUser & { lastUsedCustomerName: string },
        selectedCompanyGuid: string,
        selectedLocationGuid: string,
        selectedCustomerGuid: string,
        isReset?: boolean
    ) => void;
    onSetLoginState: (loginState: number) => void;
    fetchCustomerList: (orgLevelGuid: string, userGuid: string) => void;
    filteredCustomers: ICustomerSimpleListItem[];
    lastUsedCustomerGuid: string;
    theUsers: UserAPI.IUser[];
    intl: intlShape.isRequired;
    isQuickChanger: boolean;
    history: CompanyHistory;
}

interface ISelectCompanyState {
    companies: Company[];
    selectedCompanyGuid: string;
    selectedLocationGuid: string;
    selectedCustomerGuid: string;
}

class SelectCompany_ extends Component<ISelectCompanyProps, ISelectCompanyState> {
    constructor(props: ISelectCompanyProps) {
        super(props);
        const selectedCompanyGuid =
            this.props.theUsers.length > 0 ? this.props.theUsers[0].lastUsedCompanyGuid : null;
        const selectedLocationGuid =
            this.props.theUsers.length > 0 ? this.props.theUsers[0].lastUsedLocationGuid : null;
        const selectedCustomerGuid =
            this.props.theUsers.length > 0 ? this.props.theUsers[0].lastUsedCustomerGuid : null;
        const companies = this.props.theUsers.map((u) => ({
            value: u.companyGuid,
            label: u.companyName,
        }));
        this.state = {
            companies,
            selectedCompanyGuid,
            selectedCustomerGuid,
            selectedLocationGuid,
        };
        const applicableCompanyGuid = selectedCompanyGuid
            ? selectedCompanyGuid
            : companies.length === 1
            ? companies[0].value
            : "";
        this.updateCustomerList(applicableCompanyGuid, selectedLocationGuid);
    }

    private _handleError(error) {
        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    private async setUserAndNavHome(theUser: UserAPI.IUser) {
        const { selectedCompanyGuid, selectedCustomerGuid, selectedLocationGuid } = this.state;
        if (this.props.onAction) {
            this.props.onSetLoginState(LoginState.NONE);
            this.props.onAction(this.props.history);
        }
        const customerName =
            selectedCustomerGuid == null || selectedCustomerGuid == ""
                ? ""
                : (
                      await CustomerAPI.getCustomer({
                          UserGuid: theUser.userGuid,
                          Model: selectedCustomerGuid,
                      })
                  ).name;
        this.props.onLogin(
            {
                ...theUser,
                lastUsedCompanyGuid: selectedCompanyGuid,
                lastUsedLocationGuid: selectedLocationGuid,
                lastUsedCustomerGuid: selectedCustomerGuid,
                lastUsedCustomerName: customerName,
            },
            selectedCompanyGuid,
            selectedLocationGuid,
            selectedCustomerGuid
        );

        if (!this.props.onAction) {
            this.props.onSetLoginState(LoginState.LOGIN_FORM);
            this.props.history.replace(urlBase);
        }
    }

    public login() {
        const { selectedCompanyGuid } = this.state;
        const { theUsers } = this.props;
        const { formatMessage } = this.props.intl;
        if (selectedCompanyGuid) {
            LocalStorageHelpers.set(LocalStorageHelpers.COMPANY_GUID, selectedCompanyGuid);
            const theUser = theUsers.find((u) => u.companyGuid === selectedCompanyGuid);
            this.setUserAndNavHome(theUser);
        } else {
            this._handleError(formatMessage(messages.selectCompany));
        }
    }

    public setCompany(selectedCompanyGuid: string) {
        this.updateCustomerList(selectedCompanyGuid, null);
        this.setState({
            selectedCompanyGuid,
            selectedLocationGuid: null,
            selectedCustomerGuid: null,
        });
    }

    public setCustomer(customer: FieldAPI.ICustomerInfo) {
        this.setState({
            selectedCustomerGuid: customer ? customer.customerGuid : null,
        });
    }

    public setLocation(location) {
        this.setState({
            selectedLocationGuid: location ? location.orgLevelGuid : null,
            selectedCustomerGuid: null,
        });
        const { selectedCompanyGuid } = this.state;
        const selectedUser = !selectedCompanyGuid
            ? null
            : this.props.theUsers.find((user) => user.companyGuid === selectedCompanyGuid);
        if (location) {
            this.props.fetchCustomerList(location.orgLevelGuid, selectedUser.userGuid);
        } else {
            this.props.fetchCustomerList(selectedCompanyGuid, selectedUser.userGuid);
        }
    }

    updateCustomerList(selectedCompanyGuid: string, selectedLocationGuid: string) {
        const selectedUser = !selectedCompanyGuid
            ? null
            : this.props.theUsers.find((user) => user.companyGuid === selectedCompanyGuid);

        if (selectedCompanyGuid && selectedUser) {
            if (selectedLocationGuid) {
                this.props.fetchCustomerList(selectedLocationGuid, selectedUser.userGuid);
                return;
            } else {
                this.props.fetchCustomerList(selectedCompanyGuid, selectedUser.userGuid);
                return;
            }
        }
    }

    render() {
        const { filteredCustomers, isQuickChanger, theUsers, lastUsedCustomerGuid } = this.props;
        const { formatMessage } = this.props.intl;
        const { companies, selectedCompanyGuid, selectedCustomerGuid, selectedLocationGuid } =
            this.state;
        const selectedUser = !selectedCompanyGuid
            ? null
            : this.props.theUsers.find((user) => user.companyGuid === selectedCompanyGuid);
        const orgLevelList =
            !selectedUser || !(selectedUser.role && selectedUser.role.orgLevelQuickChanger)
                ? []
                : AppHelpers.parseOrgLevelList(
                      selectedUser.orgLevelList,
                      "orgLevelParents",
                      " > ",
                      null
                  );
        const hideCustomerList = filteredCustomers.length < 1;
        const excludedCustomers = lastUsedCustomerGuid == null ? [] : [lastUsedCustomerGuid];
        return (
            <div>
                <div className="login-input-container">
                    {isQuickChanger ? null : (
                        <div className="display-label">{formatMessage(messages.selectCompany)}</div>
                    )}
                    {isQuickChanger && theUsers.length === 1 ? null : (
                        <SelectInput
                            tabIndex={1}
                            autoFocus
                            openOnFocus={false}
                            options={companies}
                            value={selectedCompanyGuid}
                            placeholderText={formatMessage(messages.companyNameLbl)}
                            onChange={(value) => this.setCompany(value)}
                        />
                    )}
                    {orgLevelList.length <= 1 ? null : (
                        <OrgLevelSearch
                            clearOnSelection={true}
                            itemList={orgLevelList}
                            onSelection={(v) => this.setLocation(v)}
                            onSearchChange={(v) => {
                                if (!v) {
                                    this.setLocation(null);
                                }
                            }}
                            placeholderText={formatMessage(messages.locationText)}
                            selectedValue={selectedLocationGuid}
                        />
                    )}
                    {hideCustomerList ? null : (
                        <AutoSearch
                            clearOnFocus={true}
                            clearOnSelection={true}
                            itemList={filteredCustomers}
                            excludedValues={excludedCustomers}
                            keyProp={"customerGuid"}
                            nameProp={"customerName"}
                            onSearchChange={(v) => {
                                if (!v) {
                                    this.setCustomer(null);
                                }
                            }}
                            onSelection={(item: FieldAPI.ICustomerInfo) => this.setCustomer(item)}
                            placeholderText={"Customer"}
                            selectedValue={selectedCustomerGuid}
                            showTopLabel={true}
                        />
                    )}
                </div>
                <div className="login-center">
                    <Button
                        tabIndex={2}
                        className="login-btn"
                        type="login"
                        onClick={() => this.login()}
                    />
                </div>
            </div>
        );
    }
}
export const SelectCompany = withRouter(injectIntl(SelectCompany_));

const mapStateToProps = (state) => {
    return {
        filteredCustomers: getFilteredCustomers(state),
        lastUsedCustomerGuid: getLastUsedCustomerGuid(state),
        theUsers: getUsers(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogin: (
            theUser: UserAPI.IUser,
            selectedCompanyGuid: string,
            selectedLocationGuid: string,
            selectedCustomerGuid: string
        ) =>
            dispatch(
                actions.setUserInfo(
                    theUser,
                    selectedCompanyGuid,
                    selectedLocationGuid,
                    selectedCustomerGuid,
                    ownProps.isQuickChanger
                )
            ),
        fetchCustomerList: (orgLevelGuid: string, userGuid: string) =>
            dispatch(actions.fetchFilteredCustomerList(orgLevelGuid, userGuid)),
        onSetLoginState: (loginState: number) => dispatch(actions.setLoginState(loginState)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
