import { all, call, fork, put, select, takeEvery, throttle } from "redux-saga/effects";

import { actions as cdActions, models as cdModels } from "~/customer-data";
import { setApiResult } from "~/core/api/actions";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { AppHelpers, CustomerAPI, SearchAPI, EquipmentProfileAPI, APIError } from "@ai360/core";

import { messages } from "../../i18n-messages";
import * as actions from "./actions";
import { AgvanceUtils } from "../agvance-utils";
import fileSaver from "file-saver";

export function* fetchCustomerRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        try {
            const response = yield CustomerAPI.getCustomer({ UserGuid, Model: payload });
            if (response) {
                yield put(actions.fetchCustomerSuccess(response));
            } else {
                yield put(actions.fetchCustomerFailure("No Data"));
            }
        } catch (error) {
            yield put(actions.fetchCustomerFailure({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchCustomerFailure("No Data"));
    }
}

export function* fetchAgvanceCustomerRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield CustomerAPI.getAgvanceCustomerList(requestOptions);
            if (response) {
                if (response.isListOutOfBound) {
                    yield put(
                        actions.setAgvanceCustomerListOutOfBound({
                            flag: true,
                            count: response.listCount,
                        })
                    );
                    yield put(
                        actions.fetchAgvanceCustomerSuccess({
                            agvanceCustomers: [],
                        })
                    );
                } else {
                    yield put(
                        actions.fetchAgvanceCustomerSuccess({
                            agvanceCustomers: response.agvCustomerList,
                        })
                    );
                }
            }
        } catch (error) {
            yield put(actions.fetchCustomerFailure({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    }
}

export function* createCustomerRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const addCustomerResponse = yield call(CustomerAPI.addCustomer, requestOptions);
            const customerFieldsResponse = yield call(SearchAPI.getCustomerFields, {
                userGuid: UserGuid,
                customerGuid: [addCustomerResponse.customerGuid],
                pageSize: 1,
            });
            yield put(
                cdActions.addUpdateCustomer(
                    cdModels.CustomerInfo.fromCustomerField(customerFieldsResponse.results[0])
                )
            );
            if (addCustomerResponse) {
                yield put(actions.fetchCustomerSuccess(addCustomerResponse));
            }
            yield put(actions.createCustomerSuccess());
            yield put(actions.createCustomerOracleIdSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.createCustomerFailure({ error }));
            yield put(actions.createCustomerOracleIdFailure("No Data"));
        }
    } else {
        yield put(actions.createCustomerFailure("No Data"));
        yield put(actions.createCustomerOracleIdFailure("No Data"));
    }
}

export function* customerExistsRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield call(CustomerAPI.customerNameExists, requestOptions);
            if (response) {
                yield put(actions.fetchCustomerExistsSuccess(response.exists));
            }
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchCustomerExistsFailure({ error }));
        }
    } else {
        yield put(actions.fetchCustomerExistsFailure("No Data"));
    }
}

export function* createCustomerOracleIdRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield call(CustomerAPI.AddCustomerOracleIdCheck, requestOptions);

            if (response.exists) {
                //Set the oracle customer so we can update it instead of adding a new one.
                yield put(actions.setOracleCustomer(response));
                yield put(actions.createCustomerOracleIdSuccess());
            } else {
                yield put(actions.createCustomer(payload));
            }
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.createCustomerOracleIdFailure({ error }));
        }
    } else {
        yield put(actions.createCustomerOracleIdFailure("No Data"));
    }
}

export function* updateCustomerRequest(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const { myAgDataRegistrations } = payload;
            delete payload.myAgDataRegistrations;
            const updateCustomerResponse = yield call(CustomerAPI.updateCustomer, requestOptions);

            if (updateCustomerResponse && myAgDataRegistrations !== null) {
                const emailsWereSent = yield call(
                    CustomerAPI.updateMyAgDataRegistrations,
                    UserGuid,
                    payload.customerGuid,
                    myAgDataRegistrations ?? []
                );
                if (emailsWereSent === true) {
                    yield put(
                        notificationActions.pushToasterMessage(
                            messages.myAgDataEmailSent,
                            MSGTYPE.INFO
                        )
                    );
                }
            }

            const customerGuid = updateCustomerResponse.customerGuid;
            const customerFieldsResponse = yield call(SearchAPI.getCustomerFields, {
                active: updateCustomerResponse.activeYn,
                userGuid: UserGuid,
                customerGuid: [customerGuid],
                pageSize: 1,
            });
            if (
                customerFieldsResponse.results != null &&
                customerFieldsResponse.results.length > 0
            ) {
                yield put(
                    cdActions.addUpdateCustomer(
                        cdModels.CustomerInfo.fromCustomerField(customerFieldsResponse.results[0])
                    )
                );
            }

            // If customer was deactivated as part of the customer edit/update, then deactivate the
            // customer clientside.
            if (!updateCustomerResponse.activeYn) {
                yield put(cdActions.deactivateCustomer(customerGuid));
            }

            yield put(actions.updateCustomerSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.updateCustomerFailure({ error }));
        }
    } else {
        yield put(actions.updateCustomerFailure("No Data"));
    }
}

export function* processCreateEquipment(action) {
    const { payload } = action;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid: UserGuid, Model: payload };
    try {
        const response = yield EquipmentProfileAPI.createEquipmentProfile(requestOptions);
        yield put(actions.createEquipmentSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        console.warn(
            `Failed to process action ${
                actions.createEquipment("").type
            } with payload ${JSON.stringify(payload)}`
        );
        yield put(
            notificationActions.apiCallError(
                error,
                actions.createEquipment,
                messages.failedCreateEquipment,
                { equipmentType: actions.createEquipment("").type }
            )
        );
        yield put(actions.createEquipmentFailed(error));
    }
}

export function* requestCustomerEquipmentList(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload };
        try {
            const response = yield EquipmentProfileAPI.getOwnerOperatorEquipmentList(
                requestOptions
            );
            yield put(actions.fetchCustomerEquipmentListSuccess(response));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchCustomerEquipmentListFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchCustomerEquipmentListFailed("No Data"));
    }
}

export function* requestOwnerCommunityData(action) {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: payload.model };
        try {
            const response = yield CustomerAPI.getOwnerCommunityData(requestOptions);
            yield put(actions.fetchOwnerCommunityDataSuccess(response));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchOwnerCommunityDataFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchOwnerCommunityDataFailed("No Data"));
    }
}

export function* requestGetAgvanceCustomer(action) {
    const { payload } = action;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, AgvanceCustomerGuid: payload };
    try {
        const response = yield CustomerAPI.getAgvanceCustomer(requestOptions);
        // Parse to replace - agvanceGuid to agvanceCustomerGuid
        const editedResponse = {
            ...response,
            agvanceCustomerGuid: response.agvanceGuid,
        };
        yield put(actions.getAgvanceCustomerSuccess(editedResponse));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        yield put(actions.getAgvanceCustomerFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* requestSalespersonList(action) {
    const { payload } = action;
    if (payload) {
        const userGuid = yield select(getTheUserGuid);
        try {
            const response = yield CustomerAPI.getCustomerSalespersonList(userGuid);
            yield put(actions.fetchSalespersonListSuccess(response));
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchSalespersonListFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchSalespersonListFailed("No Data"));
    }
}

export function* sendAuthenticationRequest(action) {
    const { enrollmentEmail, mobilePhone, signerName, customerGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield CustomerAPI.sendAuthentication(userGuid, {
            enrollmentEmail,
            mobilePhone,
            signerName,
            customerGuid,
        });
        yield put(actions.sendAuthenticationSuccess(response));
        yield put(
            notificationActions.pushToasterMessage(
                messages.enrollmentSendEmailSuccess,
                MSGTYPE.SUCCESS
            )
        );
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(actions.sendAuthenticationFailed({ error }));
    }
}

export function* updateEnrollmentFormRequest(action) {
    const userGuid = yield select(getTheUserGuid);
    const { enrollmentForm, enrollmentFormType, customerRecord } = action.payload;
    try {
        const response = yield CustomerAPI.updateEnrollmentForm(userGuid, {
            enrollmentForm,
            enrollmentFormType,
            customerGuid: customerRecord.customerGuid,
        });
        const updatedCustomer = {
            ...customerRecord,
            enrolledFormUrl: response,
        };
        yield put(
            actions.updateEnrollmentFormSuccess({
                customerRecord: updatedCustomer,
            })
        );
        yield put(
            notificationActions.pushToasterMessage(
                messages.enrollmentFormUploadSuccess,
                MSGTYPE.SUCCESS
            )
        );
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(actions.updateEnrollmentFormFailed({ error }));
    }
}

export function* fetchEnrollmentFormRequest(action) {
    const userGuid = yield select(getTheUserGuid);
    const { customerName, enrolledFormUrl } = action.payload;

    try {
        const response = yield CustomerAPI.getEnrollmentForm(userGuid, enrolledFormUrl);
        const fileType = enrolledFormUrl.split(".").pop();
        fileSaver.saveAs(
            AppHelpers.base64ToBlob(response),
            customerName + "_EnrollmentAgreement." + fileType
        );
        yield put(actions.fetchEnrollmentFormSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(actions.fetchEnrollmentFormFailed({ error }));
    }
}

function* fetchMyAgDataRegistrationRequest(action) {
    const userGuid = yield select(getTheUserGuid);

    try {
        const response = yield CustomerAPI.getMyAgDataRegistration(userGuid, action.payload);
        yield put(actions.fetchMyAgDataRegistrationSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error, action));
        }
        yield put(actions.fetchMyAgDataRegistrationFailed({ error }));
    }
}

export function* fetchAgvanceCustomerWithNameSearch(action) {
    const { nameSearch, orgLevelGuidList, isAdditionalAgvanceCustomer = false } = action.payload;
    if (nameSearch.length >= AgvanceUtils.AGVANCE_CUSTOMER_SEARCH_AUTOCOMPLETE_LIMIT) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = {
            UserGuid,
            Model: {
                nameSearch,
                orgLevelGuidList,
            },
        };
        try {
            yield put(actions.setCustomerSearchIsLoading(true));
            yield put(actions.setAdditionalAgvanceCustomerFlag(isAdditionalAgvanceCustomer));
            const response = yield CustomerAPI.getAgvanceCustomerListWithNameSearch(requestOptions);
            yield put(
                actions.fetchAgvanceCustomerSuccess({
                    agvanceCustomers: response.agvCustomerList,
                    isAdditionalAgvanceCustomer,
                })
            );
            yield put(actions.setCustomerSearchIsLoading(false));
        } catch (error) {
            yield put(actions.setCustomerSearchIsLoading(false));
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.fetchAgvanceCustomerWithNameSearchFailed({ error }));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.clearAgvanceCustomerList());
        yield put(actions.setCustomerSearchIsLoading(false));
    }
}

export function* throttleAgvanceCustomerRequest() {
    yield throttle(
        1000,
        actions.fetchAgvanceCustomerWithNameSearch,
        fetchAgvanceCustomerWithNameSearch
    );
}

export function* watchUpdateCustomer() {
    yield takeEvery(actions.updateCustomer, updateCustomerRequest);
}

export function* watchFetchCustomer() {
    yield takeEvery(actions.fetchCustomer, fetchCustomerRequest);
}

export function* watchFetchAgvanceCustomer() {
    yield takeEvery(actions.fetchAgvanceCustomer, fetchAgvanceCustomerRequest);
}

export function* watchCreateCustomer() {
    yield takeEvery(actions.createCustomer, createCustomerRequest);
}

export function* watchCreateCustomerOracleId() {
    yield takeEvery(actions.createCustomerOracleId, createCustomerOracleIdRequest);
}

export function* watchCreateEquipment() {
    yield takeEvery(actions.createEquipment, processCreateEquipment);
}

export function* watchFetchCustomerEquipmentList() {
    yield takeEvery(actions.fetchCustomerEquipmentList, requestCustomerEquipmentList);
}

export function* watchFetchCustomerExists() {
    yield takeEvery(actions.fetchCustomerExists, customerExistsRequest);
}

export function* watchFetchOwnerCommunityData() {
    yield takeEvery(actions.fetchOwnerCommunityData, requestOwnerCommunityData);
}

export function* watchGetAgvanceCustomer() {
    yield takeEvery(actions.getAgvanceCustomer, requestGetAgvanceCustomer);
}

export function* watchFetchSalespersonList() {
    yield takeEvery(actions.fetchSalespersonList, requestSalespersonList);
}

export function* watchSendAuthentication() {
    yield takeEvery(actions.sendAuthentication, sendAuthenticationRequest);
}

export function* watchupdateEnrollmentForm() {
    yield takeEvery(actions.updateEnrollmentForm, updateEnrollmentFormRequest);
}

export function* watchFetchEnrollmentForm() {
    yield takeEvery(actions.fetchEnrollmentForm, fetchEnrollmentFormRequest);
}

export function* watchFetchMyAgDataRegistration() {
    yield takeEvery(actions.fetchMyAgDataRegistration, fetchMyAgDataRegistrationRequest);
}

const CustomerSagas = function* () {
    yield all([
        fork(watchFetchCustomer),
        fork(watchFetchAgvanceCustomer),
        fork(watchCreateCustomer),
        fork(watchCreateCustomerOracleId),
        fork(watchUpdateCustomer),
        fork(watchCreateEquipment),
        fork(watchFetchCustomerEquipmentList),
        fork(watchFetchCustomerExists),
        fork(watchFetchOwnerCommunityData),
        fork(watchFetchEnrollmentForm),
        fork(watchupdateEnrollmentForm),
        fork(watchGetAgvanceCustomer),
        fork(watchFetchSalespersonList),
        fork(watchSendAuthentication),
        fork(throttleAgvanceCustomerRequest),
        fork(watchFetchMyAgDataRegistration),
    ]);
};

export default CustomerSagas;
