import { createSelector } from "reselect";
import { getModuleState as getLayerModuleState } from "../../selectors";

export const ANALYSIS_INFO_STATE_KEY = "ANALYSIS_INFO_STATE";
export const ACCORDION_KEY = "ACCORDION";
export const TEMPGUID = "tmpGuid";

export const getModuleState = (state) => getLayerModuleState(state)[ANALYSIS_INFO_STATE_KEY];

export const getAnalysisLayerInfo = (state) => getModuleState(state).analysisSummary;

export const getEcDataAttributes = (state) => getModuleState(state).ecDataAttributes;

export const getNormalizedYieldOptions = (state) => getModuleState(state).normalizedYieldOptions;

export const getProcessingAnalysisInfo = (state) => getModuleState(state).processingAnalysisInfo;

export const getSeedStrongOptions = (state) => getModuleState(state).seedStrongOptions;

export const getAnalysisLayerTypes = (state) => getModuleState(state).analysisLayerTypes;

export const isLoading = (state) => getModuleState(state).isLoading;

export const getAccordionState = (state) => getModuleState(state)[ACCORDION_KEY];

export const getActualValues = (state) => {
    return getModuleState(state).applicationCostDetails;
};

export const getDropdownData = (state) => getModuleState(state).dropdownData;

export const getFields = (state) => getModuleState(state).fields;

export const getFieldGuidHasDataSet = createSelector(
    (state) => getModuleState(state).fieldGuids,
    (fieldGuids) => new Set(fieldGuids)
);

export const getIsLoading = (state) => getModuleState(state).profitLossDialog;

export const getManagementAreaAttributes = (state) =>
    getModuleState(state).managementAreaAttributes;

export const getManagementAreaTypes = (state) => getModuleState(state).managementAreaTypes;

export const getManagementAreaAttributesByAreaId = (state, areaId) =>
    getAnalysisLayerInfo(state).managementAreaData[areaId] || [];

export const getNormalizedYieldType = (state) => getModuleState(state).normalizedYieldType;
