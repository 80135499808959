import { delay } from "redux-saga";
import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import { actions as accordionActions, model as accordionModel } from "~/accordion";

import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";

import { getTheUserCompanyGuid, getTheUserGuid, getTheUserLockCustomer } from "~/login";
import { mapActions } from "~/map";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { SearchAPI, FormattingHelpers, LayerAPI, LayerUtilsAPI, ReportAPI } from "@ai360/core";

import * as analysisActions from "~/action-panel/components/layer-module/components/analysis-info/actions";
import * as analysisSelectors from "~/action-panel/components/layer-module/components/analysis-info/selectors";
import { createFieldAccordionItems } from "~/action-panel/components/common/accordion/model";
import * as eventInfoActions from "~/action-panel/components/event-module/components/event-info/actions";
import {
    clearRecFilter,
    configureRecFilters,
} from "~/action-panel/components/rec-module/components/rec-list/actions";
import {
    clearEventFilter,
    configureEventFilters,
} from "~/action-panel/components/event-module/components/event-list/actions";
import {
    eventsSelectors,
    actions as recsEventsActions,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import * as panelActions from "../../../../actions";
import { messages } from "../i18n-messages";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { createLayerAccordionItems, COMPLETED, LAYER_HEIGHT } from "./layer-accordion/layer-item";
import { createLegendAccordionItems } from "./layer-accordion/legend-item";

import { nonFieldDataSaga } from "./non-field-data/sagas";

import { infos as cadastralLegendInfos } from "~/data/cadastral/legends";

import natsort from "natsort";
import { actions as messagingActions } from "../../../../../messaging";
import { filterLayerOptions } from "../../utils";
import Immutable from "immutable";
import { MultiDimIdx } from "~/accordion/model";
import { logFirebaseEvent } from "~/utils/firebase";
import { ANALYSIS_INFO_NAME_IMAGERY_SETUP } from "~/recs-events/analysis/model";
import { createSurfaceAccordionItems } from "./layer-accordion/surface-item";

const naturalSorter = natsort({ insensitive: true });

const _addAccordionFieldItems = function* () {
    const currentItems = yield select(selectors.getAccordionItems);
    const expandedGuidSet = yield select(selectors.getExpandedFieldGuidSet);
    const layerInfos = yield select(selectors.getLayerInfos);
    const userGuid = yield select(getTheUserGuid);
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const accordionId = yield select(selectors.getAccordionId);
    const changedItems = yield select(recsEventsSelectors.getChangedLayerItems);
    const filteredFieldGuids = yield select(selectors.getFilteredFieldGuids);
    const fieldGuidToCurrentItemMap = new Map(
        currentItems.map((item) => [item.payload.fieldGuid, item])
    );

    const fieldGuids = [...selectedFieldGuidSet].filter(
        (fieldGuid) => filteredFieldGuids == null || filteredFieldGuids.has(fieldGuid)
    );
    const fields: SearchAPI.IFieldResult[] =
        fieldGuids.length > 0 ? yield SearchAPI.getFields({ userGuid, fieldGuid: fieldGuids }) : [];
    yield put(actions.setFieldGuidToFieldMap(Immutable.OrderedMap(fields.map((x) => [x.id, x]))));
    const sortedFields = fields
        .map((field) => ({
            fieldGuid: field.id,
            fieldBoundaryGuid: field.boundaryId,
            customerGuid: field.customerId,
            customerName: field.customerName,
            customerEnrolled: field.customerEnrolled,
            farmName: field.farmName,
            name: field.name,
            acres: field.acres,
        }))
        .sort((l, r) => {
            if (l == null || r == null) {
                return 0;
            }

            const customerNameComp = naturalSorter(l.customerName, r.customerName);
            const farmNameComp = naturalSorter(l.farmName, r.farmName);
            return customerNameComp !== 0
                ? customerNameComp
                : farmNameComp !== 0
                ? farmNameComp
                : naturalSorter(l.name, r.name);
        });
    const fieldAccordionItems = createFieldAccordionItems(sortedFields, expandedGuidSet);
    const items = [];
    const refreshCalls = [];
    const guidsToRemove = new Set(
        [...selectedFieldGuidSet].filter(
            (fieldGuid) => filteredFieldGuids != null && !filteredFieldGuids.has(fieldGuid)
        )
    );

    const layerFilter = yield select(selectors.getLayerFilter); //returns selected layer filters
    const collapseList = [];
    for (const [i, item] of fieldAccordionItems.entries()) {
        const { fieldGuid } = item.payload;
        const ci: any = fieldGuidToCurrentItemMap.get(fieldGuid);
        let pushItem = item;
        if (ci && layerInfos.has(fieldGuid)) {
            if (ci.expanded && ci.children.length > 0) {
                for (const [idx, layer] of ci.children.entries()) {
                    if (layer.expanded && _isLayerCollapsible(layerFilter, layer.payload)) {
                        collapseList.push(
                            put(accordionActions.collapseAccordionItem(accordionId, [i, idx]))
                        );
                    }
                }
            }
            const layers = yield call(LayerAPI.getAvailableLayersByFieldGuid, userGuid, fieldGuid);
            if (layers.length !== layerInfos.get(fieldGuid).length || changedItems.has(fieldGuid)) {
                refreshCalls.push(
                    call(
                        _getLayerSurfaces,
                        accordionId,
                        [i],
                        { payload: { accordionId, index: [i] } },
                        fieldGuid,
                        layers
                    )
                );
                guidsToRemove.add(fieldGuid);
            } else {
                const children = yield call(_filterLayerItems, ci.children);
                pushItem = accordionModel.AccordionItem.updateAccordionItem(item, {
                    children,
                });
            }
        }
        items.push(pushItem);
    }
    yield put(recsEventsActions.removeChangedLayerItems(guidsToRemove)); // Remove the guids once the layer accordion has been updated
    yield put(actions.removeVisibleSampleSites(guidsToRemove)); // Remove any old surfaces/sample sites
    yield put(actions.removeVisibleSurfaces(guidsToRemove));
    yield put(accordionActions.replaceAllAccordionItems(accordionId, items));
    yield put(actions.setPanelLoading(false));
    yield all(collapseList); //collapses all the persisting layers in future layer selection
    yield all(refreshCalls);
};

const _isLayerCollapsible = (layerFilter, payload) => {
    const { layerType, croppingSeasonGuid, cropGuid } = layerFilter;
    const layerFiltered = payload.layerType === layerType || !layerType;
    const seasonFiltered = payload.croppingSeasonGuid === croppingSeasonGuid || !croppingSeasonGuid;
    const cropFiltered = payload.cropGuid === cropGuid || !cropGuid;
    if (!layerFiltered || !seasonFiltered || !cropFiltered) {
        return true;
    }
    return false;
};

const _addAccordionLayerItems = function* (
    accordionId: number,
    index: accordionActions.ItemIdx,
    layers,
    fieldGuid: string
) {
    const agEventTransactionTypes = yield select(eventsSelectors.getAgEventTypeOptions);
    const samplingEventTypeGuids = new Set(
        agEventTransactionTypes
            .filter((aett) => Boolean(aett.value.sampleTypeGuid))
            .map((aett) => aett.value.agEventTransactionTypeGuid)
    );
    //Cleanup layers and add necessary flags before we push to store
    layers.forEach((layer) => {
        layer.fieldGuid = fieldGuid;
        layer.isSampling = samplingEventTypeGuids.has(layer.agEventTransactionTypeGuid);
        layer.subLayers &&
            layer.subLayers.forEach((surface) => {
                const { agEventGeneralGuid, recGeneralGuid, classBreaks, surfaceGuid } = surface;
                surface.isCorrupt = !(
                    (classBreaks && classBreaks.length > 0) ||
                    layer.imageryLayerGuid ||
                    layer.layerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP
                );
                if (surface.isCorrupt) {
                    console.warn(
                        `data for surfaceGuid ${surfaceGuid} of ${
                            agEventGeneralGuid || recGeneralGuid
                        } is corrupt`
                    );
                }
            });
    });
    yield put(actions.setLayerInfo(fieldGuid, layers));
    const children = yield call(_filterLayerItems, createLayerAccordionItems(layers));
    yield put(accordionActions.updateAccordionItem(accordionId, index, { children }));
};

const _collapseOtherSurfacesForField = function* (
    action,
    fieldItem,
    selectedSurfaceGuid,
    selectedAgEventGeneralGuid
) {
    const { accordionId, index } = action.payload;
    const collapseList = [];
    const visibleSampleSites = yield select(selectors.getVisibleSampleSitesMap);
    for (const [idx1, layer] of fieldItem.children.entries()) {
        for (const [idx2, surface] of layer.children.entries()) {
            if (surface.payload.surfaceGuid === selectedSurfaceGuid) {
                continue;
            }
            if (surface.expanded) {
                collapseList.push(
                    put(accordionActions.collapseAccordionItem(accordionId, [index[0], idx1, idx2]))
                );
            } else if (surface.payload.surfaceGuid === LayerUtilsAPI.SAMPLE_SITES_GUID) {
                const vss = visibleSampleSites.get(fieldItem.payload.fieldGuid);
                if (vss && vss.agEventGeneralGuid !== selectedAgEventGeneralGuid) {
                    yield put(actions.removeVisibleSampleSites([fieldItem.payload.fieldGuid]));
                }
            }
        }
    }
    yield all(collapseList);
};

const _filterLayerItems = function* (layerAccordionItems) {
    const { cropGuid, croppingSeasonGuid, layerType } = yield select(selectors.getLayerFilter);
    return layerAccordionItems.map((item) => {
        const height =
            _filterMatches(cropGuid, item.payload.cropGuid) &&
            _filterMatches(croppingSeasonGuid, item.payload.croppingSeasonGuid) &&
            _filterMatches(layerType, item.payload.layerType)
                ? LAYER_HEIGHT
                : 0;
        return accordionModel.AccordionItem.updateAccordionItem(item, {
            height,
        });
    });
};

const _filterLayerOptions = function* () {
    const layerFilter = yield select(selectors.getLayerFilter);
    const { croppingSeasons, crops, filters, layerTypes } = yield select(
        selectors.getLayerFilterInfo
    );
    const newFilterOptions = filterLayerOptions(
        layerFilter,
        croppingSeasons,
        crops,
        filters,
        layerTypes
    );
    yield put(actions.setLayerFilterOptions(newFilterOptions));
};

const _filterMatches = (filter, value) => filter == null || filter.length === 0 || filter === value;

const _getLayerIdx = (processingAnalysisLayer, layers: LayerAPI.ILayer[]) => {
    let idx = -1;
    let found = false;
    layers.forEach((layer) => {
        if (!found) {
            found = layer.surfaceType === processingAnalysisLayer.surfaceType;
            idx++;
        } else {
            idx += layer.displayName.localeCompare(processingAnalysisLayer.displayName) < 0 ? 1 : 0;
        }
    });
    return idx;
};

const _getLayerSurfaces = function* (
    accordionId: number,
    index: accordionActions.ItemIdx,
    action: any,
    fieldGuid: string,
    customerEnrolled: boolean,
    withLayers: LayerAPI.ILayer[] = null
) {
    const lockCustomersNotEnrolled = yield select(getTheUserLockCustomer);
    if (lockCustomersNotEnrolled && !customerEnrolled) {
        return;
    }
    yield put(actions.updateLoadingFieldGuidSet(fieldGuid, true));
    try {
        const userGuid = yield select(getTheUserGuid);
        const layers: LayerAPI.ILayer[] = withLayers
            ? withLayers
            : yield call(LayerAPI.getAvailableLayersByFieldGuid, userGuid, fieldGuid);
        const processingAnalysisLayers = yield select(analysisSelectors.getProcessingAnalysisInfo);
        const processingAnalysisLayer = processingAnalysisLayers.get(fieldGuid);
        const existingLayerMatch = _getProcessingAnalysisLayerMatch(
            processingAnalysisLayer,
            layers,
            fieldGuid
        );
        if (_shouldAddProcessingAnalysisLayer(processingAnalysisLayer, existingLayerMatch)) {
            const idx = _getLayerIdx(processingAnalysisLayer, layers);
            layers.splice(idx + 1, 0, processingAnalysisLayer);
        } else if (existingLayerMatch) {
            console.warn("existingLayerMatch", existingLayerMatch);
        }
        yield call(_addAccordionLayerItems, accordionId, index, layers, fieldGuid);
        yield put(actions.updateExpandedFieldGuidSet(fieldGuid, true));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.updateLoadingFieldGuidSet(fieldGuid, false));
    }
};

const _removeLayerInfo = function* (fieldGuids) {
    if (fieldGuids.length === 0) {
        return;
    }
    yield put(actions.updateExpandedFieldGuidSet(fieldGuids, false));
    yield put(actions.removeVisibleSampleSites(fieldGuids));
    yield put(actions.removeVisibleSurfaces(fieldGuids));
    yield put(actions.removeLayerInfos(fieldGuids));
};

const _getProcessingAnalysisLayerMatch = (
    processingAnalysisLayer,
    layers: LayerAPI.ILayer[],
    fieldGuid: string
) => {
    if (!processingAnalysisLayer) {
        return null;
    }
    return layers.find(
        (l) =>
            processingAnalysisLayer.fieldGuid === fieldGuid &&
            (l.analysisLayerGuid === processingAnalysisLayer?.analysisLayerGuid ||
                l.displayName === processingAnalysisLayer?.displayName)
    );
};

const _shouldAddProcessingAnalysisLayer = (
    processingAnalysisLayer,
    existingLayerMatch: LayerAPI.ILayer | null
) => {
    return (
        processingAnalysisLayer &&
        !existingLayerMatch &&
        (processingAnalysisLayer.analysisLayerGuid === analysisSelectors.TEMPGUID ||
            (processingAnalysisLayer.type === ANALYSIS_INFO_NAME_IMAGERY_SETUP &&
                processingAnalysisLayer.subLayers?.length < 1))
    );
};

const _updateLayerAndSurfaceInfo = function* (fieldGuid, layers, prevSurface, surface, itemDimIdx) {
    // Need to clear out the accordion item children before setting the LayerInfo so the accordion
    // doesn't try to render a child that doesn't exist with the new legend
    const layerAccordionId = yield select(selectors.getAccordionId);
    //Only call the update layer and surface info if using an accordion... ec data updates do not use this and forces itemDimIdx to be -1
    if (itemDimIdx !== -1) {
        yield put(
            accordionActions.updateAccordionItem(layerAccordionId, itemDimIdx, {
                children: [],
            })
        );
    }
    yield put(actions.setLayerInfo(fieldGuid, layers));
    if (itemDimIdx !== -1 && surface.classBreaks) {
        yield put(
            accordionActions.updateAccordionItem(layerAccordionId, itemDimIdx, {
                children: createLegendAccordionItems(surface.classBreaks),
                payload: {
                    surfaceGuid: surface.surfaceGuid,
                },
            })
        );
    } else if (surface.surfaceType === "Imagery" && surface.analysisLayerGuid) {
        //Imagery Setup
        const surfaces = [];
        accordionActions.updateAccordionItem(layerAccordionId, itemDimIdx, {
            children: createSurfaceAccordionItems(surfaces, LayerUtilsAPI.LayerType.IMAGERY),
        });
    } else {
        yield put(eventInfoActions.setECDataLoading(false));
    }

    const visibleSurfaces = yield select(selectors.getVisibleSurfacesMap);
    const visibleSurface = visibleSurfaces.get(fieldGuid);
    if (visibleSurface == null) {
        return;
    }
    const changedExistingSurfaceProps = surface.surfaceGuid === prevSurface.surfaceGuid;
    const displayingNewSurface = visibleSurface.surfaceGuid !== surface.surfaceGuid;
    if (changedExistingSurfaceProps || displayingNewSurface) {
        yield put(actions.setVisibleSurface(fieldGuid, LayerUtilsAPI.getSlimSurfaceInfo(surface)));
    }
};

const getLayerModuleInfos = function* (action) {
    try {
        const legendInfos = {
            cadastralLegendInfos: [...cadastralLegendInfos],
        };
        yield put(actions.fetchLegendInfoSucceeded(legendInfos));

        const userGuid = yield select(getTheUserGuid);
        const manualSymbologyOptions = yield call(LayerAPI.getManualSymbologyOptions, userGuid);
        yield put(actions.fetchColorSchemesSucceeded(manualSymbologyOptions));

        const symbologyOptions = yield call(LayerAPI.getSymbologyOptions, userGuid);
        yield put(actions.fetchSymbologyOptionsSucceeded(symbologyOptions));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(5000, {
            eventName: "processSoilTypesComplete",
            action: (message) => actions.fetchLayerInfo(message.fieldGuid),
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "layerStatusUpdate",
            generator: onAnalysisLayerStatusUpdate,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "batchAnalysisComplete",
            generator: onBatchAnalysisComplete,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "imageDownloadStatusChanged",
            generator: onImageSetupStatusChanged,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "satelliteImageryLambdasFinished",
            generator: onImageSetupStatusChanged,
        })
    );
};

const onAnalysisLayerStatusUpdate = function* (message) {
    const { analysisLayerGuid, eventStatusCode, fieldGuid, status } = message;
    yield put(actions.setLayerStatus(analysisLayerGuid, status));
    if (status === "Failed" || status === "Completed" || eventStatusCode === 64) {
        yield* updateProcessingAnalysisInfo(fieldGuid, analysisLayerGuid, status);
        yield put(actions.updateLayerSurfaces(fieldGuid));
    } else if (fieldGuid && status) {
        yield* updateProcessingAnalysisInfo(fieldGuid, analysisLayerGuid, status);
    }
};

const updateProcessingAnalysisInfo = function* (
    fieldGuid: string,
    analysisLayerGuid: string,
    status: string
) {
    const infos = yield select(analysisSelectors.getProcessingAnalysisInfo);
    const info = infos.get(fieldGuid);
    const updatedAnalysis = {
        ...info,
        analysisLayerGuid,
        analysisLayerStatus: status,
        status,
    };
    yield put(analysisActions.setProcessingAnalysisInfo(fieldGuid, updatedAnalysis));
};

const onBatchAnalysisComplete = function* (message) {
    yield put(
        notificationActions.pushToasterMessage(
            message.success ? messages.analysisLayerCreated : messages.analysisLayerError,
            message.success ? MSGTYPE.SUCCESS : MSGTYPE.ERROR,
            { seconds: 10 }
        )
    );
};

const onImageSetupStatusChanged = function* (message) {
    const { analysisLayerGuid, eventStatusCode, status, surfaceGuid } = message;

    if (surfaceGuid) {
        //yield* addImagerySurface(message);
    }
    if (status === "Completed" || eventStatusCode === 64) {
        yield* onCompletedImagerySetup(message);
        console.warn(analysisLayerGuid, "Completed");
    } else {
        yield put(actions.setLayerStatus(analysisLayerGuid, status));
        console.warn(analysisLayerGuid, status);
    }
};

const onCompletedImagerySetup = function* (message) {
    const { fieldGuid } = message;
    const processedAnalysisInfos = yield select(analysisSelectors.getProcessingAnalysisInfo);
    const processedAnalysisInfo = processedAnalysisInfos.get(fieldGuid);
    if (!processedAnalysisInfo) {
        console.warn("Complete message received but no existing processedAnalysisInfo");
        yield put(actions.setLayerStatus(message.analysisLayerGuid, COMPLETED));
        return;
    }

    yield* onAnalysisLayerStatusUpdate(message);
    yield put(
        notificationActions.pushToasterMessage(messages.analysisLayerCreated, MSGTYPE.SUCCESS, {
            seconds: 10,
        })
    );
    yield put(analysisActions.removeProcessingAnalysisInfo(fieldGuid));
};

const onCreatePrintLayer = function* (action) {
    const {
        agEventGeneralGuid,
        displayName,
        fieldGuid,
        imageryLayerGuid,
        isSampleSites,
        rendererGuid,
        renderPoints,
        showSampleSites,
    } = action.payload;
    yield put(
        notificationActions.pushToasterMessage(
            messages.printLayerDispatched,
            MSGTYPE.INFO,
            { layer: displayName },
            5000
        )
    );
    logFirebaseEvent("print_layer");
    const companyGuid = yield select(getTheUserCompanyGuid);
    const userGuid = yield select(getTheUserGuid);
    const reportParams = {
        fieldGuid,
        imageryLayerGuid,
        rendererGuid,
        companyGuid,
        renderPoints,
        showSampleSites,
        isSampleSites,
        agEventGeneralGuid,
    };
    try {
        yield call(
            ReportAPI.createChromelessReport,
            userGuid,
            ReportAPI.ReportNames.PRINT_LAYER_REPORT,
            reportParams
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const onCreateSplitPrintLayer = function* (action) {
    const { title } = action.payload;
    yield put(
        notificationActions.pushToasterMessage(
            messages.splitScreenPrintDispatched,
            MSGTYPE.INFO,
            { title },
            5000
        )
    );
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(
            ReportAPI.createChromelessReport,
            userGuid,
            ReportAPI.ReportNames.PRINT_SPLIT_LAYER_REPORT,
            action.payload
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

const onChangeManualLayerProps = function* (action) {
    const { fieldGuid, itemDimIdx, layer, surface, attributeGuid, colorSchemeGuid } =
        action.payload;
    try {
        yield put(actions.updateLoadingSurfacesSet(surface.surfaceGuid, true));

        const userGuid = yield select(getTheUserGuid);
        const newSurface = LayerUtilsAPI.getSurfaceInfo(layer, surface.surfaceGuid, attributeGuid);
        const requestPayload = LayerUtilsAPI.getManualSurfaceChangeRequest(
            fieldGuid,
            layer,
            newSurface,
            attributeGuid,
            colorSchemeGuid
        );
        const newRequestPayload = {
            ...requestPayload,
            analysisLayerGuid: undefined,
        };
        const newSurfaceInfo = yield call(
            LayerAPI.setSurfaceLayerProperties,
            userGuid,
            newRequestPayload,
            true
        );

        const layerInfos = yield select(selectors.getLayerInfos);
        const layers = [...layerInfos.get(fieldGuid)];
        const layerIdx = layers.findIndex(
            (li) => li.displayName === layer.displayName && li.layerType === layer.layerType
        );
        const origSurfaceIdx = LayerUtilsAPI.getSurfaceInfoIndex(
            layers[layerIdx],
            surface.surfaceGuid
        );
        const surfaceIdx = LayerUtilsAPI.getSurfaceInfoIndex(
            layers[layerIdx],
            surface.surfaceGuid,
            attributeGuid
        );
        const isOrig = !layers[layerIdx].originalAttributeGuid;
        layers[layerIdx] = {
            ...layers[layerIdx],
            originalAttributeGuid: isOrig
                ? layers[layerIdx].selectedAttributeGuid
                : layers[layerIdx].originalAttributeGuid,
            originalColorSchemeGuid: isOrig
                ? layers[layerIdx].subLayers[origSurfaceIdx].colorSchemeGuid
                : layers[layerIdx].originalColorSchemeGuid,
            selectedAttributeGuid: attributeGuid,
            selectedSurfaceGuid: surface.surfaceGuid,
        };
        layers[layerIdx].subLayers[surfaceIdx] = {
            ...layers[layerIdx].subLayers[surfaceIdx],
            classBreaks: newSurfaceInfo.classBreaks,
            colorSchemeGuid: colorSchemeGuid, //NOTE, api does not return the colorSchemeGuid
        };

        yield call(
            _updateLayerAndSurfaceInfo,
            fieldGuid,
            layers,
            surface,
            layers[layerIdx].subLayers[surfaceIdx],
            itemDimIdx
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.updateLoadingSurfacesSet(surface.surfaceGuid, false));
    }
};

const onChangeSurfaceLayerProps = function* (action) {
    const { fieldGuid, itemDimIdx, layer, surface, payload } = action.payload;
    const { attributeGuid, surfaceTypeGuid } = payload;
    try {
        yield put(actions.updateLoadingSurfacesSet(surface.surfaceGuid, true));

        const otherSurface = LayerUtilsAPI.getOtherSurface(
            layer,
            surface,
            attributeGuid,
            surfaceTypeGuid
        );

        const newSurface = LayerUtilsAPI.getSurfaceInfo(
            layer,
            otherSurface.surfaceGuid,
            attributeGuid,
            surfaceTypeGuid
        );

        const requestPayload = LayerUtilsAPI.getSurfaceChangeRequest(
            fieldGuid,
            layer,
            newSurface,
            payload
        );

        const userGuid = yield select(getTheUserGuid);
        const newSurfaceInfo = yield call(
            LayerAPI.setSurfaceLayerProperties,
            userGuid,
            requestPayload
        );

        if (newSurfaceInfo) {
            const layerInfos = yield select(selectors.getLayerInfos);
            const layers = [...layerInfos.get(fieldGuid)];
            const layerIdx = layers.findIndex(
                (li) => li.displayName === layer.displayName && li.layerType === layer.layerType
            );
            const surfaceIdx = LayerUtilsAPI.getSurfaceInfoIndex(
                layers[layerIdx],
                otherSurface.surfaceGuid,
                attributeGuid,
                surfaceTypeGuid
            );
            const layerType = LayerUtilsAPI.getLayerType(layers[layerIdx]);
            const attributeName =
                layerType == LayerUtilsAPI.LayerType.REC
                    ? "attributeGuid"
                    : LayerUtilsAPI.getAttributeGuidFieldName(layerType);
            layers[layerIdx] = {
                ...layers[layerIdx],
                selectedAttributeGuid: attributeGuid,
                selectedSurfaceGuid: otherSurface.surfaceGuid,
                selectedSurfaceTypeGuid: surfaceTypeGuid,
                surfaceTypeGuid,
                surfaceTypeDisplayName: newSurfaceInfo.surfaceTypeDisplayName,
                subLayers: layers[layerIdx].subLayers.map((sl, index) => {
                    if (index === surfaceIdx) {
                        return {
                            ...sl,
                            classBreaks: newSurfaceInfo.classBreaks,
                            classificationMethodGuid: newSurfaceInfo.classificationMethodGuid,
                            colorRampGuid: newSurfaceInfo.colorRampGuid,
                            colorSchemeGuid: newSurfaceInfo.colorSchemeGuid,
                            layerFileGuid: newSurfaceInfo.layerFileGuid,
                            numberOfClasses: newSurfaceInfo.numberOfClasses,
                            selectedSurfaceTypeGuid: surfaceTypeGuid,
                            surfaceRendererGuid: newSurfaceInfo.surfaceRendererGuid,
                        };
                    } else if (sl[attributeName] === attributeGuid) {
                        return {
                            ...sl,
                            selectedSurfaceTypeGuid: surfaceTypeGuid,
                        };
                    }
                    return sl;
                }),
            };

            yield call(
                _updateLayerAndSurfaceInfo,
                fieldGuid,
                layers,
                surface,
                layers[layerIdx].subLayers[surfaceIdx],
                itemDimIdx
            );
        }
    } catch (err) {
        if (
            surface == null ||
            surface.layerFileGuid == null ||
            surface.layerFileGuid.length === 0
        ) {
            yield put(
                notificationActions.apiCallError(err, action, messages.failedToUpdateSurfaceAlt, {
                    layerName: layer.displayName,
                    numberOfClasses: payload.numberOfClasses,
                    surfaceName: surface == null ? "surface" : surface.displayName,
                })
            );
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
    } finally {
        yield put(actions.updateLoadingSurfacesSet(surface.surfaceGuid, false));
    }
};

const onExpandCollapse = function* (
    action:
        | ReturnType<typeof accordionActions.expandAccordionItem>
        | ReturnType<typeof accordionActions.collapseAccordionItem>
) {
    const accordionId = action.payload.accordionId;
    const index = action.payload.index as MultiDimIdx;
    const layerAccordionId = yield select(selectors.getAccordionId);
    const isLoading = yield select(selectors.getPanelLoading);
    if (!isLoading && accordionId === layerAccordionId) {
        const items = yield select(selectors.getAccordionItems);
        const { fieldGuid, customerEnrolled } = items[index[0]].payload;
        const isExpanded = action.type === accordionActions.EXPAND;
        if (index.length === 1) {
            if (!isExpanded || items[index[0]].children.length !== 0) {
                yield put(actions.updateExpandedFieldGuidSet(fieldGuid, isExpanded));
            } else {
                yield call(
                    _getLayerSurfaces,
                    accordionId,
                    index,
                    action,
                    fieldGuid,
                    customerEnrolled
                );
            }
        } else if (index.length === 2) {
            console.assert(index.length === 2);
        } else {
            console.assert(index.length === 3);
            if (isExpanded) {
                const layerInfos = yield select(selectors.getLayerInfos);
                const layer = layerInfos.get(fieldGuid)[index[1]];
                const accordionItem = accordionModel.getItem(items, index);
                const { surfaceGuid } = accordionItem.payload;
                yield call(
                    _collapseOtherSurfacesForField,
                    action,
                    items[index[0]],
                    surfaceGuid,
                    layer.agEventGeneralGuid
                );
                const surface = LayerUtilsAPI.getSurfaceInfo(layer, surfaceGuid);
                if (surface) {
                    yield put(
                        actions.setVisibleSurface(
                            fieldGuid,
                            LayerUtilsAPI.getSlimSurfaceInfo(surface)
                        )
                    );
                } else {
                    console.warn("Other layers types Not Implemented yet");
                }
            } else {
                yield put(actions.removeVisibleSurfaces([fieldGuid]));
            }

            yield put(mapActions.setForceRefreshFlag(true));
        }
    }
};

const onExpandCollapseAll = function* (action) {
    const { accordionId } = action.payload;
    const layersAccordionId = yield select(selectors.getAccordionId);
    if (accordionId !== layersAccordionId) {
        return;
    }
    if (action.payload.dimensions[0] === 1) {
        const type =
            action.type === accordionActions.EXPAND_ALL
                ? accordionActions.EXPAND
                : accordionActions.COLLAPSE;
        const items = yield select(selectors.getAccordionItems);
        yield items.map((item, index) =>
            onExpandCollapse({
                type,
                payload: {
                    ...action.payload,
                    index: [index],
                },
            })
        );
    } else {
        console.assert(
            action.payload.dimensions[0] === 3 && action.type === accordionActions.COLLAPSE_ALL
        );
        yield put(actions.removeAllVisibleSurfaces());
    }
};

const onFetchLayerInfo = function* (action: ReturnType<typeof actions.fetchLayerInfo>) {
    const { fieldGuid } = action.payload;
    const accordionId = yield select(selectors.getAccordionId);
    const items: accordionModel.AccordionItem[] = yield select(selectors.getAccordionItems);
    const index = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
    if (index !== -1) {
        const item = items[index];
        const { customerEnrolled } = item.payload;
        yield call(_getLayerSurfaces, accordionId, index, action, fieldGuid, customerEnrolled);
    }
};

const onFetchTableRecords = function* (action) {
    const { fieldGuid, userDefinedPolyJson } = action.payload;
    yield put(actions.setEventSurfaceStatsLoading(true));
    try {
        const tableRecords = yield call(
            LayerAPI.getEventSurfaceStats,
            fieldGuid,
            userDefinedPolyJson
        );
        const layerInfos = yield select(selectors.getLayerInfos);
        const { croppingSeasons } = yield select(selectors.getLayerFilterInfo);
        const fieldLayers = layerInfos.get(fieldGuid);
        const displayRecords = fieldLayers
            .reduce((recs, lyr) => {
                const filteredRecs = tableRecords.filter(
                    (rec) => rec.agEventGeneralGuid === lyr.agEventGeneralGuid
                );
                filteredRecs.forEach((rec) => {
                    if (lyr.subLayers) {
                        let subLyr;
                        if (rec.depthId === "") {
                            subLyr = lyr.subLayers.find(
                                (sub) => sub.importAttributeGuid === rec.importAttributeGuid
                            );
                        } else {
                            subLyr = lyr.subLayers.find(
                                (sub) =>
                                    sub.importAttributeGuid === rec.importAttributeGuid &&
                                    sub.depthId === rec.depthId
                            );
                        }
                        if (subLyr) {
                            const decimalPlaces = FormattingHelpers.progressiveRoundingDecimals(
                                rec.max
                            );
                            const displayRecord = {
                                season: croppingSeasons.get(lyr.croppingSeasonGuid).croppingSeason,
                                layer: lyr.displayName,
                                surface: subLyr.displayName,
                                min: FormattingHelpers.formatNumber(rec.min, decimalPlaces),
                                max: FormattingHelpers.formatNumber(rec.max, decimalPlaces),
                                avg: FormattingHelpers.formatNumber(rec.avg, decimalPlaces),
                            };
                            recs.push(displayRecord);
                        } else {
                            console.warn(
                                `No matching surface layer for ${rec.agEventGeneralGuid} (IA Guid: ${rec.importAttributeGuid})`
                            );
                        }
                    }
                });
                return recs;
            }, [])
            .sort((a, b) => {
                return (
                    b.season.localeCompare(a.season) ||
                    a.layer.localeCompare(b.layer) ||
                    a.surface.localeCompare(b.surface)
                );
            });
        yield put(actions.fetchTableRecordsSuccess(displayRecords));
        console.log(displayRecords);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.setEventSurfaceStatsLoading(false));
    }
};

export const onFilterChanged = function* (): any {
    yield put(actions.setPanelLoading(true));
    yield call(_filterLayerOptions);
    yield* _addAccordionFieldItems();
    yield put(actions.setPanelLoading(false));
};

const onRemoveLayerInfos = function* (action) {
    const { fieldGuids } = action;
    const accordionId = yield select(selectors.getAccordionId);
    const items = yield select(selectors.getAccordionItems);
    const fieldGuidToDimIdxMap = new Map(items.map((item, idx) => [item.payload.fieldGuid, [idx]]));
    const toRemoveDimIdxList: any[] = Array.from(fieldGuids)
        .map((fieldGuid) => fieldGuidToDimIdxMap.get(fieldGuid))
        .filter((dimIdx) => dimIdx != null);
    for (const removeDimIdx of toRemoveDimIdxList) {
        yield put(
            accordionActions.updateAccordionItem(accordionId, removeDimIdx, {
                children: [],
            })
        );
    }
};

const onReprocessSoilTypes = function* (action) {
    const { fieldGuid } = action;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(LayerAPI.reprocessSoilTypes, fieldGuid, userGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.failedToUpdateSurface));
    }
    const accordionId = yield select(selectors.getAccordionId);
    const items = yield select(selectors.getAccordionItems);
    const index = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
    const updatedItemChildren = items[index].children.filter(
        (c) => c.payload.type !== LayerUtilsAPI.LayerType.SOIL
    );
    yield put(
        accordionActions.updateAccordionItem(accordionId, index, {
            children: updatedItemChildren,
        })
    );
};

const onSelectedFieldsChanged = function* () {
    yield put(actions.clearLayerFilter());
    yield put(clearEventFilter());
    yield put(clearRecFilter());
    yield call(delay, 500);
    let filters = {
        croppingSeasons: [],
        layerTypes: [],
        crops: [],
        all: [],
    };
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    if (selectedFieldGuidSet.size > 0) {
        const userGuid = yield select(getTheUserGuid);
        filters = yield call(
            LayerAPI.getLayerFilterOptions,
            Array.from(selectedFieldGuidSet.values()),
            userGuid
        );
    }
    yield put(configureRecFilters(filters));
    yield put(configureEventFilters(filters));

    //TODO: implement apiFilterOptionsToLayerFilterInfo function from layer-module/utils
    const uniqueCSs = new Map(
        filters.croppingSeasons.map((cs) => [`${cs.croppingSeason}`, cs.croppingSeasonGuid])
    );
    const croppingSeasons = new Map();
    for (const [croppingSeason, croppingSeasonGuid] of uniqueCSs.entries()) {
        const fieldGuids = filters.croppingSeasons
            .filter((cs) => cs.croppingSeasonGuid === croppingSeasonGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filters.all.filter(
            (surface) => surface.croppingSeasonGuid === croppingSeasonGuid
        );
        croppingSeasons.set(croppingSeasonGuid, {
            croppingSeason,
            fieldGuids,
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
            layerTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }

    const uniqueLTs = new Set(filters.layerTypes.map((lt) => lt.layerType));
    const layerTypes = new Map();
    for (const layerType of uniqueLTs.values()) {
        const fieldGuids = filters.layerTypes
            .filter((lt) => lt.layerType === layerType)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filters.all.filter((surface) => surface.layerType === layerType);
        layerTypes.set(layerType, {
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
        });
    }

    const uniqueCs = new Map(filters.crops.map((crop) => [crop.crop, crop.cropGuid]));
    const crops = new Map();
    for (const [crop, cropGuid] of uniqueCs.entries()) {
        const fieldGuids = filters.crops
            .filter((c) => c.cropGuid === cropGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filters.all.filter((surface) => surface.cropGuid === cropGuid);
        crops.set(cropGuid, {
            crop,
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            layerTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }

    yield put(
        actions.setLayerFilterInfo({
            croppingSeasons,
            crops,
            filters,
            layerTypes,
        })
    );
    yield call(_filterLayerOptions);
};

const onSetSampleSiteVisible = function* (action) {
    const { fieldGuid, layer } = action;
    const accordionId = yield select(selectors.getAccordionId);
    const items = yield select(selectors.getAccordionItems);
    const visibleSurfaces = yield select(selectors.getVisibleSurfacesMap);

    const fieldItemIdx = items.findIndex((f) => f.payload.fieldGuid === fieldGuid);
    const fieldItem = items[fieldItemIdx];
    const accordionInfo = {
        payload: {
            accordionId,
            index: [fieldItemIdx],
        },
    };
    const surfaceInfo = visibleSurfaces.get(fieldGuid);
    const surfaceGuid =
        surfaceInfo && surfaceInfo.agEventGeneralGuid === layer.agEventGeneralGuid
            ? surfaceInfo.surfaceGuid
            : LayerUtilsAPI.SAMPLE_SITES_GUID;
    yield call(
        _collapseOtherSurfacesForField,
        accordionInfo,
        fieldItem,
        surfaceGuid,
        layer.agEventGeneralGuid
    );
};

const onUpdateLayerSurfaces = function* (action: ReturnType<typeof actions.updateLayerSurfaces>) {
    const { fieldGuid } = action.payload;
    yield put(actions.removeVisibleSurfaces([fieldGuid]));
    yield call(_removeLayerInfo, [fieldGuid]);
    const accordionId = yield select(selectors.getAccordionId);
    const items: accordionModel.AccordionItem[] = yield select(selectors.getAccordionItems);
    const index = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
    if (index > -1) {
        const item = items[index];
        const { customerEnrolled } = item.payload;
        yield call(_getLayerSurfaces, accordionId, index, action, fieldGuid, customerEnrolled);
    }
};

const onUpdateEventSurfaceStats = function* (action) {
    const { polyJson } = action;
    const splitScreenCompareTool = yield select(selectors.getSplitScreenCompareTool);
    const { fieldGuid, displayEventSurfaceStats } = splitScreenCompareTool;
    if (displayEventSurfaceStats) {
        yield put(actions.fetchTableRecords(fieldGuid, polyJson ? JSON.stringify(polyJson) : null));
    }
};

const setupAccordionOnPanelActivated = function* () {
    do {
        let activeModule;
        do {
            activeModule = (yield take(panelActions.ACTIONPANEL_SET_ACTIVEMODULE)).payload;
        } while (activeModule !== panelActions.ActionPanelModuleList.LAYER);

        yield put(actions.setPanelLoading(true));
        yield* _addAccordionFieldItems();
        yield put(actions.initLayers()); //adds selected field filter items

        yield put(actions.setPanelLoading(false));
    } while (true);
};

export const onClearFieldSelection = function* (action: Record<string, any>): any {
    const accordionId = yield select(selectors.getAccordionId);
    if (action.payload == null) {
        yield put(accordionActions.collapseAllAccordionItems(accordionId, [3]));
        yield put(actions.removeAllVisibleSurfaces());
        yield put(actions.removeAllVisibleSampleSites());
        return;
    }
    const { fieldGuids } = action.payload;
    const currentItems = yield select(selectors.getAccordionItems);
    const layerInfos = yield select(selectors.getLayerInfos);
    const collapseList = [];
    for (const [index, fieldItem] of currentItems.entries()) {
        const { fieldGuid } = fieldItem.payload;
        if (
            fieldItem &&
            fieldItem.expanded &&
            fieldItem.children.length > 0 &&
            layerInfos.has(fieldGuid) &&
            fieldGuids.includes(fieldGuid)
        ) {
            for (const [layerIdx, layer] of fieldItem.children.entries()) {
                if (layer.expanded) {
                    for (const [surfaceIdx, surface] of layer.children.entries()) {
                        if (surface.expanded) {
                            collapseList.push(
                                put(
                                    accordionActions.collapseAccordionItem(accordionId, [
                                        index,
                                        layerIdx,
                                        surfaceIdx,
                                    ])
                                )
                            );
                        }
                    }
                }
            }
        }
    }
    yield all(collapseList);
    yield put(actions.removeVisibleSurfaces(fieldGuids));
    yield put(actions.removeVisibleSampleSites(fieldGuids));
};

export const layerListSaga = function* (): any {
    yield all([
        takeEvery([accordionActions.EXPAND, accordionActions.COLLAPSE], onExpandCollapse),
        takeEvery(
            [accordionActions.EXPAND_ALL, accordionActions.COLLAPSE_ALL],
            onExpandCollapseAll
        ),
        takeEvery(actions.CHANGE_MANUAL_LAYER_PROPS_INIT, onChangeManualLayerProps),
        takeEvery(actions.CHANGE_SURFACE_LAYER_PROPS_INIT, onChangeSurfaceLayerProps),
        takeEvery(actions.CREATE_PRINT_LAYER, onCreatePrintLayer),
        takeEvery(actions.CREATE_SPLIT_PRINT_LAYER, onCreateSplitPrintLayer),
        takeEvery(actions.FETCH_LAYER_INFO, onFetchLayerInfo),
        takeEvery(actions.FETCH_TABLE_RECORDS, onFetchTableRecords),
        takeEvery(actions.REMOVE_LAYER_INFOS, onRemoveLayerInfos),
        takeEvery(actions.REPROCESS_SOIL_TYPES, onReprocessSoilTypes),
        takeEvery(actions.SET_VISIBLE_SAMPLE_SITES, onSetSampleSiteVisible),
        takeEvery(actions.UPDATE_LAYERS, onUpdateLayerSurfaces),
        takeEvery(actions.UPDATE_EVENT_SURFACE_STATS, onUpdateEventSurfaceStats),
        takeLatest(
            [
                cdActions.ADD_SELECTED_FIELDS,
                cdActions.CLEAR_ALL_SELECTED_FIELDS,
                cdActions.CLEAR_SELECTED_FIELDS,
                cdActions.SET_SELECTED_FIELDS,
                actions.INIT_LAYERS,
            ],
            onSelectedFieldsChanged
        ),
        takeLatest(
            [cdActions.CLEAR_SELECTED_FIELDS, cdActions.CLEAR_ALL_SELECTED_FIELDS],
            onClearFieldSelection
        ),
        takeLatest(mapActions.SET_MAP_READY, getLayerModuleInfos),
        takeLatest([actions.SET_LAYER_FILTER, actions.CLEAR_LAYER_FILTER], onFilterChanged),
        setupAccordionOnPanelActivated(),
        nonFieldDataSaga(),
        messageSubscriptions(),
    ]);
};
