import { defineMessages } from "react-intl";

export const messages = defineMessages({
    activateRec: {
        id: "recModule.contextMenu.activateRec",
        defaultMessage: "Activate Rec",
    },
    clear: {
        id: "recModule.contextMenu.clear",
        defaultMessage: "Clear",
    },
    confirmationText: {
        id: "recModule.varietyHybridConfirmation.confirmationText",
        defaultMessage:
            "You have 1 or more zones without a variety selected, are you sure you want to Save?",
    },
    confirmationTitle: {
        id: "recModule.varietyHybridConfirmation.confirmationTitle",
        defaultMessage: "Confirm Save",
    },
    convertRecToEvent: {
        id: "recModule.contextMenu.convertRecToEvent",
        defaultMessage: "Convert To Event",
    },
    copy: {
        id: "recModule.contextMenu.copy",
        defaultMessage: "Copy",
    },
    delete: {
        id: "recModule.contextMenu.delete",
        defaultMessage: "Delete",
    },
    deleteRecConfirmation: {
        id: "recModule.confirmationDialog.deleteRecConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Rec?",
    },
    deleteMultipleRecsConfirmation: {
        id: "recModule.confirmationDialog.deleteMultipleRecsConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Recs?",
    },
    exportControllerFile: {
        id: "recModule.contextMenu.exportControllerFile",
        defaultMessage: "Export Controller {count, plural, one {File} other {Files}}",
    },
    exportComplete: {
        id: "recModule.exportControllerFile.exportComplete",
        defaultMessage: "Export Complete",
    },
    exportFailed: {
        id: "recModule.exportControllerFile.exportFailed",
        defaultMessage: "Export Failed",
    },
    exportToFile: {
        id: "recModule.exportControllerFile.exportToFile",
        defaultMessage: "Export To File",
    },
    exportValidationWarningTitle: {
        id: "recModule.exportControllerFile.exportValidationWarningTitle",
        defaultMessage:
            "Controller File Merge Validation {count, plural, one {Error} other {Errors}}",
    },
    exportValidationWarningOneRecPerField: {
        id: "recModule.exportControllerFile.exportValidationWarningOneRecPerField",
        defaultMessage: "Only one Rec per Field can be selected",
    },
    exportValidationWarningOneRecType: {
        id: "recModule.exportControllerFile.exportValidationWarningOneRecType",
        defaultMessage:
            "Application and Planting Recs cannot be combined in the same Controller File Merge",
    },
    exportValidationWarningPlantingRecType: {
        id: "recModule.exportControllerFile.exportValidationWarningPlantingRecType",
        defaultMessage:
            "Planting (Equation) and Planting (Manual) Recs cannot be combined in the same Controller File Merge",
    },
    exportValidationWarningFilenameRequired: {
        id: "recModule.exportControllerFile.exportValidationWarningFilenameRequired",
        defaultMessage: "Required value",
    },
    exportValidationWarningFilenameSpecialCharacters: {
        id: "recModule.exportControllerFile.exportValidationWarningFilenameSpecialCharacters",
        defaultMessage: "Cannot contain special characters",
    },
    jdOperationsCenterText: {
        id: "recModule.contextMenu.jdOperationsCenterText",
        defaultMessage: "JD Operations Center",
    },
    sendRecToText: {
        id: "recModule.contextMenu.sendRecToText",
        defaultMessage: "Send Rec To",
    },
    statusMessagesText: {
        id: "recModule.components.recSend.statusMessagesText",
        defaultMessage: "Status Messages",
    },
    workOrderText: {
        id: "recModule.components.recSend.workOrderText",
        defaultMessage: "Work Order Description",
    },
    instructionsText: {
        id: "recModule.components.recSend.instructionsText",
        defaultMessage: "Work Instructions",
    },
    creatingPrescriptionFile: {
        id: "recModule.components.recSend.creatingPrescriptionFile",
        defaultMessage: "Creating prescription for Rec {recName}",
    },
    missingPermission: {
        id: "recModule.components.recSend.missingPermission",
        defaultMessage: "Creating Work Plans requires {permissionName} in the JD Ops Center",
    },
    addedCropToWorkPlan: {
        id: "recModule.components.recSend.addedCropToWorkPlan",
        defaultMessage: "Added Crop {cropName} to Work Plan",
    },
    takingFirstOperator: {
        id: "recModule.components.recSend.takingFirstOperator",
        defaultMessage: "Rec contains more than 1 person, setting {operatorName} as the Operator",
    },
    addedOperatorToWorkPlan: {
        id: "recModule.components.recSend.addedOperatorToWorkPlan",
        defaultMessage: "Added Operator {operatorName} to Work Plan",
    },
    skippingEquipment: {
        id: "recModule.components.recSend.skippingEquipment",
        defaultMessage:
            "A Machine has already been added, so skipped adding {equipmentName} to Work Plan",
    },
    addedEquipmentToWorkPlan: {
        id: "recModule.components.recSend.addedEquipmentToWorkPlan",
        defaultMessage: "Added {equipmentType} {equipmentName} to Work Plan",
    },
    createdAndAddedOperatorToWorkPlan: {
        id: "recModule.components.recSend.createdAndAddedOperatorToWorkPlan",
        defaultMessage:
            "Created Operator {operatorName} in the JD Ops Center and added to Work Plan",
    },
    createdClientInJdOpsCenter: {
        id: "recModule.components.recSend.createdClientInJdOpsCenter",
        defaultMessage: "Created Client in JD Ops Center named {clientName}",
    },
    fieldOverlapsWithExistingBoundary: {
        id: "recModule.components.recSend.fieldOverlapsWithExistingBoundary",
        defaultMessage: "Field overlaps with existing boundary in the JD Ops Center",
    },
    fieldBoundaryChangeOccurred: {
        id: "recModule.components.recSend.fieldBoundaryChangeOccurred",
        defaultMessage: "Field boundary does not match boundary in JD Ops Center",
    },
    boundaryWithSameNameAlreadyExists: {
        id: "recModule.components.recSend.boundaryWithSameNameAlreadyExists",
        defaultMessage: "Field boundary does not match Field with same name in JD Ops Center",
    },
    createdFieldInJdOpsCenter: {
        id: "recModule.components.recSend.createdFieldInJdOpsCenter",
        defaultMessage: "Created Field in JD Ops Center named {fieldName} on Farm {farmName}",
    },
    unArchivedFieldInJdOpsCenter: {
        id: "recModule.components.recSend.unArchivedFieldInJdOpsCenter",
        defaultMessage: "Unarchived Field in JD Ops Center named {fieldName} on Farm {farmName}",
    },
    retrievedFieldFromJdOpsCenter: {
        id: "recModule.components.recSend.retrievedFieldFromJdOpsCenter",
        defaultMessage: "Found matching Field > {fieldName} in JD Ops Center",
    },
    createdProductInJdOpsCenter: {
        id: "recModule.components.recSend.createdProductInJdOpsCenter",
        defaultMessage: "Created {productType} > {productName} in JD Ops Center",
    },
    retrievedProductFromJdOpsCenter: {
        id: "recModule.components.recSend.retrievedProductFromJdOpsCenter",
        defaultMessage: "Found matching Product {productType} > {productName} in JD Ops Center",
    },
    sentWorkPlanToJdOpsCenter: {
        id: "recModule.components.recSend.sentWorkPlanToJdOpsCenter",
        defaultMessage: "Created Work Plan in JD Ops Center containing {numberOfInputs} {prod}",
    },
    updatedWorkPlanInJdOpsCenter: {
        id: "recModule.components.recSend.updatedWorkPlanInJdOpsCenter",
        defaultMessage: "Updated Work Plan in JD Ops Center containing {numberOfInputs} {prod}",
    },
    exportValidationWarningFalconIdSpecialCharacters: {
        id: "recModule.exportControllerFile.exportValidationWarningFalconIdSpecialCharacters",
        defaultMessage: "Cannot contain letters or special characters",
    },
    exportValidationWarningFalconIdLength: {
        id: "recModule.exportControllerFile.exportValidationWarningFalconIdLength",
        defaultMessage: "Must be 6 characters in length",
    },
    couldNotCreateWorkPlanInJdOpsCenter: {
        id: "recModule.components.recSend.couldNotCreateWorkPlanInJdOpsCenter",
        defaultMessage: "Could not create Work Plan in the JD Ops Center",
    },
    viewEditDetails: {
        id: "recModule.contextMenu.viewEditDetails",
        defaultMessage: "View / Edit Details",
    },
});
