import * as actions from "./actions";
import * as immutableUtils from "~/utils/immutable";
import * as analysisInfoActions from "~/action-panel/components/layer-module/components/analysis-info/actions";

const newInitialState = Object.freeze({
    analysisLayerTypes: [],
    fieldGuidToAnalysisSummaryMap: new Map(),
    analysisDetailsErrorCodeList: [],
});

export const analysisInfoReducer = (state = newInitialState, action) => {
    switch (action.type) {
        case analysisInfoActions.CLOSE_ANALYSIS_INFO: {
            return Object.freeze({
                ...state,
                fieldGuidToAnalysisSummaryMap: new Map(),
            });
        }
        case actions.SET_ANALYSIS_ERROR_CODES: {
            const { analysisDetailsErrorCodeList } = action.payload;
            return Object.freeze({
                ...state,
                analysisDetailsErrorCodeList,
            });
        }
        case actions.SET_ANALYSIS_LAYER_TYPES: {
            const { analysisLayerTypes } = action.payload;
            return Object.freeze({
                ...state,
                analysisLayerTypes,
            });
        }
        case actions.SET_ANALYSIS_SUMMARY_MAP: {
            const { fieldGuids, analysisSummary } = action.payload;
            const fieldGuidToAnalysisSummaryMap = fieldGuids.reduce(
                (map, fieldGuid) => immutableUtils.noMutateMapSet(map, fieldGuid, analysisSummary),
                state.fieldGuidToAnalysisSummaryMap
            );
            return Object.freeze({
                ...state,
                fieldGuidToAnalysisSummaryMap,
            });
        }
        default:
            return state;
    }
};
